import "./sass/styles.scss";
import "./styles.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { Calendar, momentLocalizer, styles } from "react-big-calendar";
import React, { Component } from "react";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils";
import img1 from "../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import moment from "moment";
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

const localizer = momentLocalizer(moment);
const now = new Date();
const myEventsList = [
  {
    id: 0,
    title: "All Day Event very long title",
    allDay: true,
    start: new Date(2015, 3, 0),
    end: new Date(2015, 3, 1),
  },
  {
    id: 1,
    title: "Long Event",
    start: new Date(2015, 3, 7),
    end: new Date(2015, 3, 10),
  },

  {
    id: 2,
    title: "DTS STARTS",
    start: new Date(2016, 2, 13, 0, 0, 0),
    end: new Date(2016, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: "DTS ENDS",
    start: new Date(2016, 10, 6, 0, 0, 0),
    end: new Date(2016, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: "Some Event",
    start: new Date(2015, 3, 9, 0, 0, 0),
    end: new Date(2015, 3, 10, 0, 0, 0),
  },
  {
    id: 5,
    title: "Conference",
    start: new Date(2015, 3, 11),
    end: new Date(2015, 3, 13),
    desc: "Big conference for important people",
  },
  {
    id: 6,
    title: "Meeting",
    start: new Date(2015, 3, 12, 10, 30, 0, 0),
    end: new Date(2015, 3, 12, 12, 30, 0, 0),
    desc: "Pre-meeting meeting, to prepare for the meeting",
  },
  {
    id: 7,
    title: "Lunch",
    start: new Date(2015, 3, 12, 12, 0, 0, 0),
    end: new Date(2015, 3, 12, 13, 0, 0, 0),
    desc: "Power lunch",
  },
  {
    id: 8,
    title: "Meeting",
    start: new Date(2015, 3, 12, 14, 0, 0, 0),
    end: new Date(2015, 3, 12, 15, 0, 0, 0),
  },
];

class EventCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      success_msg: false,
      batch: "000",
      eventTitle: "",
      date: "",
      url: "",
    };
  }

  componentDidMount() {
    this.getUserData();
    this.updateDates();
  }

  updateDates = () => {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    db.collection("/Calendars/batches/cohort1")
      .doc("008")
      .set({
        id: 8,
        allDay: false,
        title: "PPI Lab Founding Member C/M Session- Week 8",
        start: new Date(2020, 8, 14, 12, 0, 0, 0),
        end: new Date(2020, 8, 14, 13, 0, 0, 0),
        url: "http://www.ppiweb.com",
      })
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };
  showEvent = (event) => {
    this.setState({
      success_msg: true,
      eventTitle: event.title,
      date: event.start.toString(),
      url: event.url,
    });
  };

  getUserData = () => {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;
    var docRef = db.collection("Progress").doc(User);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data().batch);

          this.setState({ batch: doc.data().batch }, () => {
            this.getEvents();
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getEvents = () => {
    // Get Batch From User

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var batch = this.state.batch;

    //Render Based on Batch

    db.collection("/Calendars/batches/Master")
      //  db.collection("/Calendars/batches/" + `${batch}`)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          var currentData = this.state.data;

          console.log(doc.data().start.toDate);

          var newData = {
            id: doc.data().id,
            title: doc.data().title,
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate(),
            url: doc.data().url,
          };

          currentData.push(newData);

          this.setState({ data: currentData });
        });
      });
  };

  goToUrl = (url) => {
    window.open(url, ",_blank");
  };

  render() {
    const { open } = this.state;
    const data = this.state.data;
    const eventTitle = this.state.eventTitle;

    const url = this.state.url;

    const image = (
      <box-icon id="eventIcon" size="cssSize" name="calendar-event"></box-icon>
    );

    const MyCalendar = (props) => (
      <div>
        <Calendar
          localizer={localizer}
          events={data}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onSelectEvent={(event) => {
            this.showEvent(event);
          }}
        />
      </div>
    );

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.success_msg ? (
            <SweetAlert
              title={eventTitle}
              custom
              customIcon={image}
              showCancel
              confirmBtnText="GO"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => this.goToUrl(url)}
              onCancel={() => this.setState({ success_msg: false })}
            >
              {this.state.date}
            </SweetAlert>
          ) : null}
          <Container fluid>
            <MyCalendar />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(EventCalendar);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #2ed5bd;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
