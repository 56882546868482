import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import ReactPlayer from "react-player";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/small/Team Briefing Banner_Square.png";

import "./styles.css";

class GroupRecordings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  ref = (player) => {
    this.player = player;
  };

  handleProgress(response) {
    const db = firebase.firestore();
  }

  getData() {
    const db = firebase.firestore();
    const { open } = this.state;
    let citiesRef = db.collection("GroupRecordings/cohort1/cohort1");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;

    const welcome =
      "<p>Each Team Briefing provides you with a set of materials to use for sharing Key Insights with your team members.</p><p>Designed to be completed in 5-10 minutes, Team Briefings are perfect for use during regular team meetings/briefing sessions. Each Briefing covers a Key Insight, a Tool, or a concept fundamental to next-level performance. The initial order follows your PPI Lab Success Path, allowing your team discussions to move forward with you.</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="PPILAB"
              breadcrumbItem="Group Mentoring Sessions"
            />

            <CardBody className="pt-0">
              <Row>
                {data.map((course, index) => {
                  return (
                    <EpisodeCard key={index}>
                      <Title className="mt-0">{course.title}</Title>
                      <LockContainer>
                        <box-icon
                          id="icon"
                          name="task"
                          color="#10029d"
                        ></box-icon>
                      </LockContainer>

                      <ButtonContainer>
                        <Button
                          onClick={this.onOpenModal.bind(this, index)}
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          {course.buttonTitle}
                        </Button>
                      </ButtonContainer>
                      <Modal isOpen={open[index]} ariaHideApp={false}>
                        <button
                          type="button"
                          onClick={() => this.onCloseModal()}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <CloseIcon aria-hidden="true">&times;</CloseIcon>
                        </button>
                        <TitleContainer>
                          <ModalTitle>{course.title}</ModalTitle>
                        </TitleContainer>

                        <PlayerContainer>
                          <ReactPlayer
                            ref={this.ref}
                            playbackRate={playbackRate}
                            onReady={this.handleReady}
                            onStart={console.log()}
                            onPlay={this.handlePlay}
                            onProgress={(response) => {
                              this.handleProgress(response);
                            }}
                            onDuration={(response) => {
                              this.setState({ videoDuration: response });
                            }}
                            onPause={this.handlePause}
                            onBuffer={console.log()}
                            onSeek={console.log()}
                            onEnded={console.log()}
                            controls={true}
                            url={course.videoUrl}
                            playing={playing}
                            width="100%"
                            height="100%"
                            config={{
                              attributes: {
                                controlsList: "nodownload",
                              },
                            }}
                          />
                        </PlayerContainer>
                        {course.pdf ? (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          >
                            <a
                              style={{ width: "100%", color: "white" }}
                              href={course.pdfUrl}
                              download
                              target="_blank"
                            >
                              PDF
                            </a>
                          </Button>
                        ) : (
                          <p></p>
                        )}

                        {course.ppt ? (
                          <Button
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          >
                            <a
                              style={{ width: "100%", color: "white" }}
                              href={course.pptUrl}
                              download
                              target="_blank"
                            >
                              PPT
                            </a>
                          </Button>
                        ) : (
                          <p></p>
                        )}
                      </Modal>
                    </EpisodeCard>
                  );
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(GroupRecordings);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.span`
  font-size: 50px;
`;

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
`;

const FilterButton = styled.button`
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fffff6;
  color: gray;
`;
