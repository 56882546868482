import React, { Component } from "react";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import Question1 from "./Questions/Question1.js";
import Question2A from "./Questions/Question2A.js";
import Question2B from "./Questions/Question2B.js";
import Question2C from "./Questions/Question2C.js";
import Results from "./results.js";

import styled from "styled-components";

class TransApp extends React.Component {
  constructor() {
    super();

    this.state = {
      loggedIn: false,
      Question1: true,
      Question2A: false,
      Question2B: false,
      Question2C: false,
      Results: false,
    };

    this.question1 = this.question1.bind(this);
    this.question2A = this.question2A.bind(this);
    this.question2B = this.question2B.bind(this);
    this.question2C = this.question2C.bind(this);
    this.results = this.results.bind(this);
  }

  componentDidMount() {}

  clickedHeader() {
    this.props.renderHeader();
  }

  question1() {
    this.setState({
      Question1: true,
      Question2A: false,
      Question2B: false,
      Question2C: false,
      Results: false,
    });
  }

  question2A() {
    this.setState({
      Question1: false,
      Question2A: true,
      Question2B: false,
      Question2C: false,
      Results: false,
    });
  }

  question2B() {
    this.setState({
      Question1: false,
      Question2A: false,
      Question2B: true,
      Question2C: false,
      Results: false,
    });
  }

  question2C() {
    this.setState({
      Question1: false,
      Question2A: false,
      Question2B: false,
      Question2C: true,
      Results: false,
    });
  }
  results() {
    this.setState({
      Question1: false,
      Question2A: false,
      Question2B: false,
      Question2C: false,
      Results: true,
    });
  }

  render() {
    if (this.state.Question1 === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <TransContainer>
                    <CardContainer>
                      <Question1 next={this.question2A} />
                    </CardContainer>
                  </TransContainer>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else if (this.state.Question2A === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <TransContainer>
                    <CardContainer>
                      <Question2A
                        previous={this.question1}
                        next={this.question2B}
                      />
                    </CardContainer>
                  </TransContainer>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else if (this.state.Question2B === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <TransContainer>
                    <CardContainer>
                      <Question2B
                        previous={this.question2A}
                        next={this.question2C}
                      />
                    </CardContainer>
                  </TransContainer>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else if (this.state.Question2C === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <TransContainer>
                    <CardContainer>
                      <Question2C
                        previous={this.question2B}
                        next={this.results}
                      />
                    </CardContainer>
                  </TransContainer>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    } else if (this.state.Results === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <TransContainer>
                    <CardContainer>
                      <Results previous={this.question1} />
                    </CardContainer>
                  </TransContainer>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default TransApp;

const TransContainer = styled.div`
  margin: 20px;
  padding: 20px;
`;

const CardContainer = styled.div``;
