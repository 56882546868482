import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Collapse,
  CardSubtitle,
  CardText,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import classnames from "classnames";

import styled from "styled-components";

import { connect } from "react-redux";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import VerticalTimelineComponent from "./VerticalTimelineComponent";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import "boxicons";
// Pages Components

import Banner from "./Banner";
import Progress from "./Progress";
import Intro from "./Intro/index.js";
import Principal from "./Principal/index.js";
import Coach from "./Coach/index.js";
import Synthesizer from "./Synthesizer/index.js";
import Maestro from "./Maestro/index.js";
import Mentor from "./Mentor/index.js";

import ScrollContainer from "react-indiana-drag-scroll";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./styles.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTabJustify: "1",
      introProgress: 0,
      principalProgress: 0,
      coachProgress: 0,
      synthesizerProgress: 0,
      maestroProgress: 0,
      goto: "0",
    };

    this.toggleCustomJustified = this.toggleCustomJustified.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  toggleCustomJustified(tab) {
    if (this.state.activeTabJustify !== tab) {
      this.setState({
        activeTabJustify: tab,
      });
    }
  }

  changeTab(tab) {
    this.setState(
      {
        activeTabJustify: tab,
      },
      () => {
        console.log(this.state.activeTabJustify);
      }
    );
  }

  componentDidMount() {
    if (this.props.location.state === undefined) {
      console.log("Props Undefined");
    } else {
      console.log("PROPS HERE " + this.props.location.state.activeTabJustify);

      this.setState({
        activeTabJustify: this.props.location.state.activeTabJustify,
      });
    }
  }
  componentWillReceiveProps(nextProps) {}

  componentDidUpdate() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="PPILAB" breadcrumbItem="Core Content" />

            <Row>
              <Col lg={12}>
                <Progress content={this.state.activeTabJustify}></Progress>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav tabs className="nav-tabs-custom nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "1",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("1");
                          }}
                        >
                          <box-icon name="home" type="solid"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Home</span>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "2",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("2");
                          }}
                        >
                          <box-icon type="solid" name="rocket"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Intro</span>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "3",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("3");
                          }}
                        >
                          <box-icon name="list-check"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block"> Principal</span>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "4",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("4");
                          }}
                        >
                          <box-icon name="group" type="solid"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Coach</span>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "5",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("5");
                          }}
                        >
                          <box-icon
                            name="select-multiple"
                            type="solid"
                          ></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Synthesizer</span>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "6",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("6");
                          }}
                        >
                          <box-icon name="medium" type="logo"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Maestro</span>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: this.state.activeTabJustify === "7",
                          })}
                          onClick={() => {
                            this.toggleCustomJustified("7");
                          }}
                        >
                          <box-icon name="conversation" type="solid"></box-icon>
                        </NavLink>
                        <span className="d-none d-sm-block">Mentor</span>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTabJustify}>
                      <TabPane
                        tabId="1"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <VerticalTimeline layout="2-columns">
                              <VerticalTimelineComponent
                                tab={this.changeTab}
                              ></VerticalTimelineComponent>
                            </VerticalTimeline>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane
                        tabId="2"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <Intro></Intro>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane
                        tabId="3"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <Principal />
                          </Col>
                        </Row>
                      </TabPane>

                      <TabPane
                        tabId="4"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <Coach />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane
                        tabId="5"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <Synthesizer></Synthesizer>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane
                        tabId="6"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <Maestro></Maestro>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane
                        tabId="7"
                        className="p-3"
                        style={{ backgroundColor: "#eceff2" }}
                      >
                        <Row>
                          <Col sm="12">
                            <CardText>
                              <Mentor></Mentor>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Home);

/*
 */

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const DropDownText = styled.span`
  &:hover ${DropdownContent} {
    display: block;
  }
`;

const ScrollItem = styled.div`
  background-color: #e74c3c;
  min-width: 250px;
  min-height: 250px;
  margin: 20px;
`;

/* <ScrollContainer
                              horizontal={true}
                              style={{ display: 'flex' }}
                            >
                              <ScrollItem key='1'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='2'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='3'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='4'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='5'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='6'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='7'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                              <ScrollItem key='8'>
                                <h1>HEllo</h1>
                              </ScrollItem>
                            </ScrollContainer>*/
