import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 4 [Proactive Accountability]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "Who can you hold accountable for their choices, actions, and behaviors? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Yourself",
        "Your spouse, partner, or ‘significant other’",
        "Your child",
        "People who report to you at work",
      ],
      correctAnswer: [1],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "In today’s environment, you’re either growing or you’re…dying. There is no standing still. In addition, neither incremental or iterative changes offer enough speed to ‘keep up’. Module 2 uses examples of the “Fosbury Flop” from world high-jump competition and cell phone proliferation around the globe (no longer requiring telephone lines and physical connections) as examples of transformational change. ",
    },
    {
      question:
        "If being a ‘victim’ is one who is acted upon, one who is injured or destroyed, or one who is subjected to hardship (all unpleasant), why do so many choose to ‘play the victim’ so often? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "It makes us feel good about ourselves.",
        "The ‘victim’ is morally right, has no responsibility, and is entitled to sympathy.",
        "It elevates our reputation among our peers, friends, and family members.",
        "It positively raises overall energy level. ",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question:
        "[TRUE or FALSE] A person’s level/sense of ENTITLEMENT is a product of the generation they were born into.  ",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question:
        "When a person does not consider the universe to be a ‘friendly’ place and they’re ‘hanging out’ below the Great Divide, their predominant worldview is __________, which promotes __________ behaviors.",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "disorder, confused",
        "hope, desperate",
        "scarcity, victim",
        "opportunity, accountable",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question:
        "There are three steps for climbing out of Suckers’ Swamp and getting ABOVE the Great Divide. The three steps are…",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "ADHERE → ALLOW → ACCEPT",
        "ADMIT → ASSESS → ADAPT",
        "ADMIT → ACCEPT → ACT",
        "ASSESS → ADAPT → ACT",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
  ],
};

export default class Recall4 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall4: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 5) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "4" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
