import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/small/Mini-Course Banner_square.png";

import "./styles.css";

import { device } from "../Course1/device";

class StartHere extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      episodes: [],
      fromNotifications: "Please Reload",
      episode001Progress: 0,
      episode002Progress: 0,
      episode003Progress: 0,
      episode001Locked: true,
      episode002Locked: true,
      episode003Locked: true,
    };
  }

  componentDidMount() {
    this.getEpisodes();
    this.getUserData();
    this.getStatus();
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getEpisodes() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/00-Intro/Content/Content");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log("MAIN EPISODE DATA HERE");
          console.log(doc.data());

          var currentData = this.state.episodes;

          currentData.push(doc.data());

          this.setState({ episodes: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.getIntroEpisodesProgress();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("ID HERE");
          console.log(doc.data().episode001.locked);

          this.setState({
            episode001Locked: doc.data().episode001.locked,
            episode002Locked: doc.data().episode002.locked,
            episode003Locked: doc.data().episode003.locked,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getIntroEpisodesProgress() {
    var data = this.state.data;
    console.log("DATA HERE");

    console.log(data[0].episode002.progress);
    console.log(data[0].episode003.progress);

    this.setState({
      episode001Progress: Math.round(data[0].episode001.progress),
      episode002Progress: Math.round(data[0].episode002.progress),
      episode003Progress: Math.round(data[0].episode003.progress),
    });
  }

  render() {
    const { open } = this.state;
    const episodes = this.state.episodes;
    const episode001Locked = this.state.episode001Locked;
    const episode002Locked = this.state.episode002Locked;
    const episode003Locked = this.state.episode003Locked;

    var percentage = 8;
    var item = <h1>Loading</h1>;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <ContainerThree>
              <ContainerStartHere>Begin Your Journey…</ContainerStartHere>
              <ContainerOneTitle>
                Begin your journey to becoming an EXPERT in Next-Gen Human
                Performance by watching the Orientation Videos Below.
              </ContainerOneTitle>
              <ContainerStartHere>PPI Lab Orientation</ContainerStartHere>
              <ContainerOneTitle>
                This video gives you a walkthrough of PPI Lab, including all of
                its features & benefits and what to expect along your journey.
              </ContainerOneTitle>
              <ContainerStartHere>A Bit of History</ContainerStartHere>
              <ContainerOneTitle>
                A quick overview of where the PPI Next-Gen approach to
                Performance Improvement came from and how it continues to evolve
                through CANI…
              </ContainerOneTitle>
              <ContainerStartHere>Red Pill or Blue Pill?</ContainerStartHere>
              <ContainerOneTitle>Your personal challenge…</ContainerOneTitle>
            </ContainerThree>
            <CardContainer>
              {episodes.map((episode, index) => {
                if (episode.currentEpisode === "/episode001") {
                  var percentage = this.state.episode001Progress;
                  var locked = episode001Locked;
                  console.log("locked HERE " + locked);
                } else if (episode.currentEpisode === "/episode002") {
                  var percentage = this.state.episode002Progress;
                  var locked = episode002Locked;
                } else if (episode.currentEpisode === "/episode003") {
                  var percentage = this.state.episode003Progress;
                  var locked = episode003Locked;
                }

                if (locked === true) {
                  item = (
                    <EpisodeCard key={index}>
                      <Title className="mt-0">{episode.title}</Title>
                      <LockContainer>
                        <box-icon
                          color="#2ed5bd"
                          id="lock"
                          type="solid"
                          name="lock"
                        ></box-icon>
                      </LockContainer>
                    </EpisodeCard>
                  );
                } else if (locked === false) {
                  item = (
                    <EpisodeCard key={index}>
                      <Title className="mt-0">{episode.title}</Title>
                      <ProgressContainer>
                        {percentage === 100 ? (
                          <CheckContainer>
                            <box-icon
                              size="lg"
                              color="#a3ff03"
                              name="check-circle"
                              type="solid"
                            ></box-icon>
                          </CheckContainer>
                        ) : (
                          <CircularProgressbar
                            style={{
                              width: 100,
                              position: "absolute",
                              display: "block",
                            }}
                            styles={buildStyles({
                              // Rotation of path and trail, in number of turns (0-1)
                              rotation: 0,

                              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                              strokeLinecap: "butt",

                              // Text size
                              textSize: "30px",

                              // How long animation takes to go from one percentage to another, in seconds
                              pathTransitionDuration: 0.5,

                              // Can specify path transition in more detail, or remove it entirely
                              // pathTransition: 'none',

                              // Colors
                              pathColor: `#2ed5bd`,
                              textColor: `#2ed5bd`,
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                            value={percentage}
                            text={`${percentage}%`}
                          />
                        )}
                      </ProgressContainer>
                      <Duration>
                        <TimeIcon>
                          <box-icon type="solid" name="time"></box-icon>
                        </TimeIcon>
                        <TextContainer>
                          <Text>{episode.duration}</Text>
                        </TextContainer>
                      </Duration>
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          <Link
                            to={{
                              pathname: `${episode.currentEpisode}`,
                            }}
                            style={{ color: "#FFF" }}
                          >
                            Watch
                          </Link>
                        </Button>
                      </ButtonContainer>
                    </EpisodeCard>
                  );
                }
                {
                  return item;
                }
              })}
            </CardContainer>

            <ContainerTitle>Once You’ve Completed Orientation…</ContainerTitle>
            <EpisodeContainer>
              <ContainerOne>
                <ContainerOneTitle>
                  If you’re new (or relatively new) to Human Performance, if
                  you’re an “I want it all,” or an “I don’t want to miss a
                  thing” person, OR if you’re an uber-achiever and want to grab
                  as much life and career-changing insight as possible from your
                  LAB experience,
                </ContainerOneTitle>
                <ContainerStartHere>Start Here</ContainerStartHere>
                <box-icon
                  type="solid"
                  id="icon"
                  color="#a3ff03"
                  name="down-arrow"
                ></box-icon>
                <EpisodeCard>
                  <Title className="mt-0">
                    Human Performance BASIC Training™
                  </Title>
                  <LockContainer>
                    <box-icon
                      color="#a3ff03"
                      id="icon"
                      type="solid"
                      name="analyse"
                    ></box-icon>
                  </LockContainer>

                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                      to="/dashboard"
                    >
                      <a
                        target="_blank"
                        href="https://huacademy.mykajabi.com/offers/EtNsCFcm"
                        style={{ color: "#FFF" }}
                      >
                        Access Course
                      </a>
                    </Button>
                  </ButtonContainer>
                </EpisodeCard>
              </ContainerOne>
              <ContainerTwo>
                <ContainerTwoTitle>
                  If you’ve been in the Performance Improvement world for a
                  while, fully understand WHY a focus on Human Performance is
                  the Next-Gen approach, and you want to dive right into
                  Leader-Level Stuff,
                </ContainerTwoTitle>
                <ContainerStartHere>Start Here</ContainerStartHere>
                <box-icon
                  type="solid"
                  id="icon"
                  color="#a3ff03"
                  name="down-arrow"
                ></box-icon>
                <EpisodeCard>
                  <Title className="mt-0">
                    Human Performance LEADERSHIP Master Class™
                  </Title>
                  <LockContainer>
                    <box-icon
                      color="#a3ff03"
                      id="icon"
                      type="solid"
                      name="analyse"
                    ></box-icon>
                  </LockContainer>

                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                      to="/dashboard"
                    >
                      <Link to="/course1" style={{ color: "#FFF" }}>
                        Access Course
                      </Link>
                    </Button>
                  </ButtonContainer>
                </EpisodeCard>
              </ContainerTwo>
            </EpisodeContainer>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(StartHere);

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const EpisodeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ContainerOne = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 500px;
  height: 650px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 2px #888888;
`;
const ContainerOneTitle = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 350;
`;

const ContainerStartHere = styled.h1``;
const ContainerTitle = styled.h1`
  margin-top: 30px;
  text-align: center;
`;

const ContainerTwo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 500px;
  height: 650px;
  margin: 20px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 1px #888888;
`;

const ContainerTwoTitle = styled.p`
  text-align: center;
  font-size: 19px;
  font-weight: 325;
`;

const ContainerThree = styled.div`
  margin: 10px auto;
  width: 500px;
  height: 540px;
  text-align: center;
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 40px;
  padding: 20px;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media ${device.mobileS} {
    width: 80px;
    height: 80px;
  }
  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;
const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;
