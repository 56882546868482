import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import { Link } from "react-router-dom";

import img1 from "../../../assets/images/small/img-1.jpg";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils";

import { getFirebaseBackend } from "../../../helpers/authUtils";

import ScrollContainer from "react-indiana-drag-scroll";

import styled from "styled-components";
//Import Breadcrumb

import { device } from "../device.js";

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, data: [], episodeResource: [] };
  }

  componentDidMount() {
    this.getModules();
    this.getLeader();
    this.getLabWorksheets();
    //this.getJSP();
    // this.getGuides();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getModules() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/00-Intro/Resources/Resources");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getLabWorksheets = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Lab WorkSheets
    db.collection("/Courses/001/00-Intro/Resources/Resources")
      .get()
      .then((sheets) => {
        sheets.forEach((sheet) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(sheet.id, " => ", sheet.data());

          var currentEpisodeResource = this.state.episodeResource;

          if (sheet.id === "lab00" || sheet.id === "lab01") {
            console.log(sheet.data());

            currentEpisodeResource.push(sheet.data());

            this.setState(
              { episodeResource: currentEpisodeResource },
              () => {}
            );
          }
        });
      });
  };

  getJSP = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get JSP

    db.collection("/Jumpstart")
      .get()
      .then((jumps) => {
        jumps.forEach((jump) => {
          console.log(jump.id, "=>", jump.data());

          var currentEpisodeResourceJump = this.state.episodeResource;

          if (jump.id === "001") {
            currentEpisodeResourceJump.push(jump.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceJump,
              },
              () => {}
            );
          }
        });
      });
  };

  getGuides = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Exercise Guides

    db.collection("/Powerpoints")
      .get()
      .then((guides) => {
        guides.forEach((guide) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (guide.id === "ppe46") {
            currentEpisodeResourceGuide.push(guide.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getLeader = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/LeaderManuals")
      .get()
      .then((manuals) => {
        manuals.forEach((manual) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (manual.id === "00A" || manual.id === "00B") {
            currentEpisodeResourceGuide.push(manual.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  render() {
    const { open } = this.state;
    const data = this.state.data;
    const resources = this.state.episodeResource;
    return (
      <CardContainer>
        <MainTitle>Resources</MainTitle>
        <hr></hr>
        {resources.map((resource, index) => {
          return (
            <Col key={index} mg={12} xl={12}>
              <MiniCardContainer>
                <Title className="mt-0">{resource.title}</Title>

                {resource.external ? (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        href={resource.url}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        {resource.buttonTitle}
                      </a>
                    </Button>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <Link to={resource.url} style={{ color: "white" }}>
                        {resource.buttonTitle}
                      </Link>
                    </Button>
                  </ButtonContainer>
                )}
              </MiniCardContainer>
            </Col>
          );
        })}
      </CardContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Resources);

const CardContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    padding: 5px;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
    margin-top: 10px;
  }

  @media ${device.tablet} {
    padding: 20px;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const ButtonLink = styled.a`
  display: block;
  color: white;
  width: 100%;
`;

const MiniCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: 20px;

  @media ${device.mobileS} {
    padding: 10px;
  }
`;

const MainTitle = styled.h1`
  @media ${device.mobileS} {
    font-size: 15px;
  }

  @media ${device.mobileM} {
    font-size: 35px;
  }
`;

const Title = styled.h1`
  flex: 1;
  @media ${device.mobileS} {
    font-size: 10px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const Text = styled.p`
  flex: 1;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;
