import "react-circular-progressbar/dist/styles.css";
import "../styles.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { connect } from "react-redux";
import { device } from "../device.js";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../../helpers/authUtils";
import img1 from "../../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../../helpers/authUtils.js";
import styled from "styled-components";

//Import Breadcrumb

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodes: [],
      fromNotifications: "Please Reload",
      episode0081Progress: 0,
      episode0082Progress: 0,
      episode0083Progress: 0,
      episode0084Progress: 0,
      episode0085Progress: 0,
      episode0086Progress: 0,
      episode0087Progress: 0,
      episode0081Locked: true,
      episode0082Locked: true,
      episode0083Locked: true,
      episode0084Locked: true,
      episode0085Locked: true,
      episode0086Locked: true,
      episode0087Locked: true,
      stageProgress: 0,
      examScore: 0,
    };
  }

  componentDidMount() {
    this.getEpisodes();
    this.getUserData();
    this.getStatus();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getEpisodes() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/Maestro/Content/Content");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.episodes;

          currentData.push(doc.data());

          this.setState({ episodes: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.getPrincipalEpisodesProgress();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("ID HERE");
          console.log("HEREEE");
          console.log(doc.data().C1FinalExam.correct);

          this.setState({
            episode0081Locked: doc.data().episode0081.locked,
            episode0082Locked: doc.data().episode0082.locked,
            episode0083Locked: doc.data().episode0083.locked,
            episode0084Locked: doc.data().episode0084.locked,
            episode0085Locked: doc.data().episode0085.locked,
            episode0086Locked: doc.data().episode0086.locked,
            episode0087Locked: doc.data().episode0087.locked,
            stageProgress: doc.data().stageProgress.total,
            examScore: doc.data().C1FinalExam.correct,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getPrincipalEpisodesProgress() {
    var data = this.state.data;
    console.log("DATA HERE");

    this.setState({
      episode0081Progress: Math.round(data[0].episode0081.progress),
      episode0082Progress: Math.round(data[0].episode0082.progress),
      episode0083Progress: Math.round(data[0].episode0083.progress),
      episode0084Progress: Math.round(data[0].episode0084.progress),
      episode0085Progress: Math.round(data[0].episode0085.progress),
      episode0086Progress: Math.round(data[0].episode0086.progress),
      episode0087Progress: Math.round(data[0].episode0087.progress),
    });
  }

  render() {
    const { open } = this.state;
    const episodes = this.state.episodes;

    const episode0081Locked = this.state.episode0081Locked;
    const episode0082Locked = this.state.episode0082Locked;
    const episode0083Locked = this.state.episode0083Locked;
    const episode0084Locked = this.state.episode0084Locked;
    const episode0085Locked = this.state.episode0085Locked;
    const episode0086Locked = this.state.episode0086Locked;
    const episode0087Locked = this.state.episode0087Locked;

    var stageProgress = this.state.stageProgress;
    var examScore = this.state.examScore;

    /*<Link
                  to={{
                    pathname: `/C1FinalExam`,
                  }}
                  style={{ color: "#FFF" }}
                >
                  Access
                </Link> */

    console.log("EXAM SCORE");
    console.log(examScore);

    var finalExam = <Text></Text>;

    if (stageProgress >= 80) {
      var finalExam = (
        <EpisodeCard>
          <Title className="mt-0">Final Exam</Title>
          <LockContainer>
            <box-icon
              color="#ff4a52"
              id="lock"
              type="solid"
              name="pencil"
            ></box-icon>
          </LockContainer>

          <BottomContainer>
            <ButtonContainer>
              <Button
                color="primary"
                className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                to="/dashboard"
              >
                <a
                  href="https://huacademy.mykajabi.com/offers/Xb3hznDr"
                  style={{ color: "#FFF" }}
                  target="_blank"
                >
                  Access
                </a>
              </Button>
            </ButtonContainer>
          </BottomContainer>
        </EpisodeCard>
      );
    } else {
      var finalExam = (
        <EpisodeCard>
          <Title className="mt-0">Final Exam</Title>
          <LockContainer>
            <box-icon
              color="#ff4a52"
              id="lock"
              type="solid"
              name="lock"
            ></box-icon>
          </LockContainer>
        </EpisodeCard>
      );
    }

    var certificate = <Text></Text>;

    if (examScore >= 40) {
      var certificate = (
        <EpisodeCard>
          <Title className="mt-0">Certificate</Title>
          <LockContainer>
            <box-icon
              color="#ff4a52"
              id="lock"
              type="solid"
              name="pencil"
            ></box-icon>
          </LockContainer>

          <BottomContainer>
            <ButtonContainer>
              <Button
                color="primary"
                className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                to="/dashboard"
              >
                <Link
                  to={{
                    pathname: `/certificate`,
                  }}
                  style={{ color: "#FFF" }}
                >
                  Download
                </Link>
              </Button>
            </ButtonContainer>
          </BottomContainer>
        </EpisodeCard>
      );
    } else {
      var certificate = (
        <EpisodeCard>
          <Title className="mt-0">Certificate</Title>
          <LockContainer>
            <box-icon
              color="#ff4a52"
              id="lock"
              type="solid"
              name="lock"
            ></box-icon>
          </LockContainer>
        </EpisodeCard>
      );
    }

    var percentage = 8;
    var item = <h1>Loading</h1>;
    return (
      <React.Fragment>
        <Row>
          <CardContainer>
            {episodes.map((episode, index) => {
              if (episode.currentEpisode === "/episode0081") {
                var percentage = this.state.episode0081Progress;
                var locked = this.state.episode0081Locked;
              } else if (episode.currentEpisode === "/episode0082") {
                var percentage = this.state.episode0082Progress;
                var locked = this.state.episode0082Locked;
              } else if (episode.currentEpisode === "/episode0083") {
                var percentage = this.state.episode0083Progress;
                var locked = this.state.episode0083Locked;
              } else if (episode.currentEpisode === "/episode0084") {
                var percentage = this.state.episode0084Progress;
                var locked = this.state.episode0084Locked;
              } else if (episode.currentEpisode === "/episode0085") {
                var percentage = this.state.episode0085Progress;
                var locked = this.state.episode0085Locked;
              } else if (episode.currentEpisode === "/episode0086") {
                var percentage = this.state.episode0086Progress;
                var locked = this.state.episode0086Locked;
              } else if (episode.currentEpisode === "/episode0087") {
                var percentage = this.state.episode0087Progress;
                var locked = this.state.episode0087Locked;
              }

              if (episode.fast === true) {
                var fast = (
                  <FastContainer>
                    <Fast>
                      <box-icon
                        color="#f70c2e"
                        id="fast"
                        name="star"
                        type="solid"
                      ></box-icon>
                      <FastText>20%</FastText>
                    </Fast>
                  </FastContainer>
                );
              } else {
                var fast = <div></div>;
              }

              if (locked === true) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#ff4a52"
                        id="lock"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <ProgressContainer>
                      {percentage === 100 ? (
                        <CheckContainer>
                          <box-icon
                            size="lg"
                            color="#a3ff03"
                            name="check-circle"
                            type="solid"
                          ></box-icon>
                        </CheckContainer>
                      ) : (
                        <CircularProgressbar
                          style={{
                            width: 100,
                            position: "absolute",
                            display: "block",
                          }}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "30px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#ff4a52`,
                            textColor: `#ff4a52`,
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                          value={percentage}
                          text={`${percentage}%`}
                        />
                      )}
                    </ProgressContainer>
                    <Duration>
                      <TimeIcon>
                        <box-icon type="solid" name="time"></box-icon>
                      </TimeIcon>
                      <TextContainer>
                        <Text>{episode.duration}</Text>
                      </TextContainer>
                    </Duration>

                    <BottomContainer>
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          <Link
                            to={{
                              pathname: `${episode.currentEpisode}`,
                            }}
                            style={{ color: "#FFF" }}
                          >
                            Watch
                          </Link>
                        </Button>
                      </ButtonContainer>
                      {fast}
                    </BottomContainer>
                  </EpisodeCard>
                );
              }
              {
                return item;
              }
            })}
            {finalExam}
            {certificate}
          </CardContainer>
        </Row>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episodes);

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FastContainer = styled.div`
  position: relative;
`;

const Fast = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
  }
`;

const FastText = styled.p`
  position: absolute;
  color: white;
  font-size: 15px;
  border: 1px solid green;

  @media ${device.mobileS} {
    display: none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #ff4a52;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    width: 160px;
    height: 180px;
    margin-bottom: 2px;
  }

  @media ${device.mobileM} {
    width: 190px;
    height: 200px;
  }

  @media ${device.mobileL} {
    width: 225px;
    height: 250px;
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.mobileL} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 17px;
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileL} {
    display: flex;
  }
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media ${device.mobileS} {
    width: 70px;
    height: 70px;
  }
  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
