import React from "react";
import { Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            <Col md={6}>
              {new Date().getFullYear()} © Practicing Perfection Institute Inc..
            </Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                Design & Developed by Practicing Perfection Institute Inc
              </div>
            </Col>
          </Row>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
