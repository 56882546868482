import "./styles.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils";
import img1 from "../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

//Import Breadcrumb

class MiniCoursesMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      MC001: true,
      MC002: true,
      MC003: true,
      MC004: true,
      MC005: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  getManualStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().MiniCourses);
          this.setState({
            MC001: doc.data().MiniCourses.MC001,
            MC002: doc.data().MiniCourses.MC002,
            MC003: doc.data().MiniCourses.MC003,
            MC004: doc.data().MiniCourses.MC004,
            MC005: doc.data().MiniCourses.MC005,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db.collection("MiniCourses").limit(5);
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;
    var MC001 = this.state.MC001;
    var MC002 = this.state.MC002;
    var MC003 = this.state.MC003;
    var MC004 = this.state.MC004;
    var MC005 = this.state.MC005;

    var render = <p>Locked</p>;

    return (
      <MainContainer>
        <CardBody className="pt-0">
          <CardInfo>
            <CardTitle>
              <MainTitle>Mini-Courses</MainTitle>
              <MainText>
                Helping you continually improve your abilities as a Stage 5
                LEADER
              </MainText>
            </CardTitle>
            <CardButton>
              <ButtonTitle>
                <Link
                  to="/minicourses"
                  className="btn btn-primary waves-effect waves-light"
                  style={{ fontSize: 18, backgroundColor: "#42fb93" }}
                >
                  View All Mini-Courses
                </Link>
              </ButtonTitle>
            </CardButton>
          </CardInfo>

          <Row>
            {data.map((course, index) => {
              var locked = false;
              if (course.id === "001") {
                var locked = MC001;
              } else if (course.id === "002") {
                var locked = MC002;
              } else if (course.id === "003") {
                var locked = MC003;
              } else if (course.id === "004") {
                var locked = MC004;
              } else if (course.id === "005") {
                var locked = MC005;
              }

              if (locked === true) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#42fb93"
                        id="icon"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#42fb93"
                        id="icon"
                        type="solid"
                        name="chalkboard"
                      ></box-icon>
                    </LockContainer>

                    <ButtonContainer>
                      <Button
                        color="primary"
                        className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        to="/dashboard"
                      >
                        <Link
                          to={{
                            pathname: "",
                          }}
                          style={{ color: "#FFF" }}
                        >
                          Access
                        </Link>
                      </Button>
                    </ButtonContainer>
                  </EpisodeCard>
                );
              }

              return render;
            })}
          </Row>
        </CardBody>
      </MainContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(MiniCoursesMini);

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 22px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 0px;
  padding: 20px;
`;
