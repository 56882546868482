import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import ReactPlayer from "react-player";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/small/Team Briefing Banner_Square.png";

import "./styles.css";

class TeamBriefings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      TB00: true,
      TB01: true,
      TB02: true,
      TB03: true,
      TB04: true,
      TB05: true,
      TB06: true,
      TB07: true,
      TB08: true,
      TB09: true,
      TB10: true,
      TB11: true,
      TB12: true,
      TB13: true,
      TB14: true,
      TB15: true,
      TB16: true,
      TB17: true,
      TB18: true,
      TB19: true,
      TB20: true,
      TB21: true,
      TB22: true,
      TB23: true,
      TB24: true,
      TB25: true,
      TB26: true,
      TB27: true,
      TB28: true,
      TB29: true,
      TB30: true,
      TB31: true,
      TB32: true,
      TB33: true,
      TB34: true,
      TB35: true,
      TB36: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  ref = (player) => {
    this.player = player;
  };

  handleProgress(response) {
    const db = firebase.firestore();
  }

  getManualStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().TeamBriefings);
          this.setState({
            TB00: doc.data().TeamBriefings.TB00,
            TB01: doc.data().TeamBriefings.TB01,
            TB02: doc.data().TeamBriefings.TB02,
            TB03: doc.data().TeamBriefings.TB03,
            TB04: doc.data().TeamBriefings.TB04,
            TB05: doc.data().TeamBriefings.TB05,
            TB06: doc.data().TeamBriefings.TB06,
            TB07: doc.data().TeamBriefings.TB07,
            TB08: doc.data().TeamBriefings.TB08,
            TB09: doc.data().TeamBriefings.TB09,
            TB10: doc.data().TeamBriefings.TB10,
            TB11: doc.data().TeamBriefings.TB11,
            TB12: doc.data().TeamBriefings.TB12,
            TB13: doc.data().TeamBriefings.TB13,
            TB14: doc.data().TeamBriefings.TB14,
            TB15: doc.data().TeamBriefings.TB15,
            TB16: doc.data().TeamBriefings.TB16,
            TB17: doc.data().TeamBriefings.TB17,
            TB18: doc.data().TeamBriefings.TB18,
            TB19: doc.data().TeamBriefings.TB19,
            TB20: doc.data().TeamBriefings.TB20,
            TB21: doc.data().TeamBriefings.TB21,
            TB22: doc.data().TeamBriefings.TB22,
            TB23: doc.data().TeamBriefings.TB23,
            TB24: doc.data().TeamBriefings.TB24,
            TB25: doc.data().TeamBriefings.TB25,
            TB26: doc.data().TeamBriefings.TB26,
            TB27: doc.data().TeamBriefings.TB27,
            TB28: doc.data().TeamBriefings.TB28,
            TB29: doc.data().TeamBriefings.TB29,
            TB30: doc.data().TeamBriefings.TB30,
            TB31: doc.data().TeamBriefings.TB31,
            TB32: doc.data().TeamBriefings.TB32,
            TB33: doc.data().TeamBriefings.TB33,
            TB34: doc.data().TeamBriefings.TB34,
            TB35: doc.data().TeamBriefings.TB35,
            TB36: doc.data().TeamBriefings.TB36,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    const { open } = this.state;
    let citiesRef = db.collection("TeamBriefings");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;
    var TB00 = this.state.TB00;
    var TB01 = this.state.TB01;
    var TB02 = this.state.TB02;
    var TB03 = this.state.TB03;
    var TB04 = this.state.TB04;
    var TB05 = this.state.TB05;
    var TB06 = this.state.TB06;
    var TB07 = this.state.TB07;
    var TB08 = this.state.TB08;
    var TB09 = this.state.TB09;
    var TB10 = this.state.TB10;
    var TB11 = this.state.TB11;
    var TB12 = this.state.TB12;
    var TB13 = this.state.TB13;
    var TB14 = this.state.TB14;
    var TB15 = this.state.TB15;
    var TB16 = this.state.TB16;
    var TB17 = this.state.TB17;
    var TB18 = this.state.TB18;
    var TB19 = this.state.TB19;
    var TB20 = this.state.TB20;
    var TB21 = this.state.TB21;
    var TB22 = this.state.TB22;
    var TB23 = this.state.TB23;
    var TB24 = this.state.TB24;
    var TB25 = this.state.TB25;
    var TB26 = this.state.TB26;
    var TB27 = this.state.TB27;
    var TB28 = this.state.TB28;
    var TB29 = this.state.TB29;
    var TB30 = this.state.TB30;
    var TB31 = this.state.TB31;
    var TB32 = this.state.TB32;
    var TB33 = this.state.TB33;
    var TB34 = this.state.TB34;
    var TB35 = this.state.TB35;
    var TB36 = this.state.TB36;

    var render = <p>Locked</p>;

    const welcome =
      "<p>Each Team Briefing provides you with a set of materials to use for sharing Key Insights with your team members.</p><p>Designed to be completed in 5-10 minutes, Team Briefings are perfect for use during regular team meetings/briefing sessions. Each Briefing covers a Key Insight, a Tool, or a concept fundamental to next-level performance. The initial order follows your PPI Lab Success Path, allowing your team discussions to move forward with you.</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Team Briefings" />

            <CardBody className="pt-0">
              <Row>
                <StageContainer>
                  <WelcomeContainer>
                    <WelcomeLogo src={logo} />
                  </WelcomeContainer>
                  <InfoContainer>
                    <div>
                      <h1>Team Briefings</h1>
                      {ReactHtmlParser(welcome)}
                    </div>
                  </InfoContainer>
                </StageContainer>
              </Row>
              <Row>
                {data.map((course, index) => {
                  var locked = false;
                  if (course.id === "00") {
                    var locked = TB00;
                  } else if (course.id === "01") {
                    var locked = TB01;
                  } else if (course.id === "02") {
                    var locked = TB02;
                  } else if (course.id === "03") {
                    var locked = TB03;
                  } else if (course.id === "04") {
                    var locked = TB04;
                  } else if (course.id === "05") {
                    var locked = TB05;
                  } else if (course.id === "06") {
                    var locked = TB06;
                  } else if (course.id === "07") {
                    var locked = TB07;
                  } else if (course.id === "08") {
                    var locked = TB08;
                  } else if (course.id === "09") {
                    var locked = TB09;
                  } else if (course.id === "10") {
                    var locked = TB10;
                  } else if (course.id === "11") {
                    var locked = TB11;
                  } else if (course.id === "12") {
                    var locked = TB12;
                  } else if (course.id === "13") {
                    var locked = TB13;
                  } else if (course.id === "14") {
                    var locked = TB14;
                  } else if (course.id === "15") {
                    var locked = TB15;
                  } else if (course.id === "16") {
                    var locked = TB16;
                  } else if (course.id === "17") {
                    var locked = TB17;
                  } else if (course.id === "18") {
                    var locked = TB18;
                  } else if (course.id === "19") {
                    var locked = TB19;
                  } else if (course.id === "20") {
                    var locked = TB20;
                  } else if (course.id === "21") {
                    var locked = TB21;
                  } else if (course.id === "22") {
                    var locked = TB22;
                  } else if (course.id === "23") {
                    var locked = TB23;
                  } else if (course.id === "24") {
                    var locked = TB24;
                  } else if (course.id === "25") {
                    var locked = TB25;
                  } else if (course.id === "26") {
                    var locked = TB26;
                  } else if (course.id === "27") {
                    var locked = TB27;
                  } else if (course.id === "28") {
                    var locked = TB28;
                  } else if (course.id === "29") {
                    var locked = TB29;
                  } else if (course.id === "30") {
                    var locked = TB30;
                  } else if (course.id === "31") {
                    var locked = TB31;
                  } else if (course.id === "32") {
                    var locked = TB32;
                  } else if (course.id === "33") {
                    var locked = TB33;
                  } else if (course.id === "34") {
                    var locked = TB34;
                  } else if (course.id === "35") {
                    var locked = TB35;
                  } else if (course.id === "36") {
                    var locked = TB36;
                  }

                  if (locked === true) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#10029d"
                            id="lock"
                            type="solid"
                            name="lock"
                          ></box-icon>
                        </LockContainer>
                      </EpisodeCard>
                    );
                  } else if (locked === false) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            id="icon"
                            name="task"
                            color="#10029d"
                          ></box-icon>
                        </LockContainer>

                        <ButtonContainer>
                          <Button
                            onClick={this.onOpenModal.bind(this, index)}
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          >
                            {course.buttonTitle}
                          </Button>
                        </ButtonContainer>
                        <Modal isOpen={open[index]} ariaHideApp={false}>
                          <button
                            type="button"
                            onClick={() => this.onCloseModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <CloseIcon aria-hidden="true">&times;</CloseIcon>
                          </button>
                          <TitleContainer>
                            <ModalTitle>{course.title}</ModalTitle>
                          </TitleContainer>

                          <PlayerContainer>
                            <ReactPlayer
                              ref={this.ref}
                              playbackRate={playbackRate}
                              onReady={this.handleReady}
                              onStart={console.log()}
                              onPlay={this.handlePlay}
                              onProgress={(response) => {
                                this.handleProgress(response);
                              }}
                              onDuration={(response) => {
                                this.setState({ videoDuration: response });
                              }}
                              onPause={this.handlePause}
                              onBuffer={console.log()}
                              onSeek={console.log()}
                              onEnded={console.log()}
                              controls={true}
                              url={course.videoUrl}
                              playing={playing}
                              width="100%"
                              height="100%"
                              config={{}}
                            />
                          </PlayerContainer>
                          {course.pdf ? (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              <a
                                style={{ width: "100%", color: "white" }}
                                href={course.pdfUrl}
                                download
                                target="_blank"
                              >
                                PDF
                              </a>
                            </Button>
                          ) : (
                            <p></p>
                          )}

                          {course.ppt ? (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              <a
                                style={{ width: "100%", color: "white" }}
                                href={course.pptUrl}
                                download
                                target="_blank"
                              >
                                PPT
                              </a>
                            </Button>
                          ) : (
                            <p></p>
                          )}
                        </Modal>
                      </EpisodeCard>
                    );
                  }

                  return render;
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(TeamBriefings);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.span`
  font-size: 50px;
`;

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
`;

const FilterButton = styled.button`
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fffff6;
  color: gray;
`;
