import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 6 [Achieving Zero Events]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "Which of the statements listed below are true relative to “Close Calls” [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Something bad could have happened but did not.",
        "“Close Call” means the same thing as “Near Miss”",
        "Investigating Close Calls is being truly proactive",
        "Close calls warrant little consideration since nothing bad happened",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question:
        "[TRUE or FALSE] DRIFT in perception of risk, as well as drift in work standards, can occur at both the individual and team/organizational level.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["True", "False"],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "Human fallibility means that we are capable of making mistakes and being wrong- ALL of us.",
      point: "12.5",

      correctAnswer: "1",
    },
    {
      question:
        "The accumulation of which of the following tend to make it more likely for bad things to happen? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: ["Capital", "Traps", "Roadblocks", "Landmines"],
      correctAnswer: [2, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "The reason Drift & Accumulation are collectively referred to as “Organizational Entropy” is…",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Tim Autrey is a thermodynamics ‘nut’",
        "it occurs (and will continue to get worse) unless energy is directly applied to reverse/counter it",
        "every organization is a ‘closed system’",
        "as Newton’s Second Law, it warrants consideration since he was used to develop the ‘Different Set of Rules’ of Human Performance",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },

    {
      question:
        "Which of the following activities are involved in achieving and sustaining Zero Events? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Reversing levels of complacency ",
        "Matching “how work is actually done around here” with standardized best practices",
        "Elevating use of the Power Tools",
        "Actively removing Traps, Roadblocks, and Landmines",
      ],
      correctAnswer: [1, 2, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "Since we have identified that “Safety” is NOT your organization’s “Top Priority”, what must it be?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "A Core Principle",
        "A Key Performance Indicator (KPI)",
        "A beginning topic for every meeting",
        "A topic for training 4X per year (minimum)",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "[TRUE or FALSE] Having a “JUST” Culture means having a “Blame-Free” Culture",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "WHICH ONE of the following is MOST CORRECT regarding Accountability and Responsibility? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Responsibility is internal ownership. Accountability is external accounting/consequence.",
        "There is no difference.",
        "Accountability is internal ownership. Responsibility is external accounting/consequence.",
        "They are opposites.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
  ],
};

export default class Recall6 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];
    var input6 = UserInput[5];
    var input7 = UserInput[6];
    var input8 = UserInput[7];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall6: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
          UserInput6: UserInput[5],
          UserInput7: UserInput[6],
          UserInput8: UserInput[7],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 8) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "5" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
