import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Collapse,
  CardSubtitle,
  CardText,
  CardHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown,
  CardImg,
} from "reactstrap";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import img1 from "../../assets/images/small/Test.png";

import classnames from "classnames";

import styled from "styled-components";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import "boxicons";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import "./styles.css";
import { device } from "./device.js";

export default class VerticalTimelineComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      progressData: [],
      introProgress: 0,
      principalProgress: 0,
      coachProgress: 0,
      synthesizerProgress: 0,
      maestroProgress: 0,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getUserProgress();
      this.getTimeProgress();
    }, 2000);
  }

  getUserProgress = () => {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    let progressRef = db.collection("Progress").doc(User);
    let getDoc = progressRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log("Document data:", doc.data().timeline);

          var ReadyData = Object.entries(doc.data().timeline);
          console.log(ReadyData);

          this.setState({ data: ReadyData.sort() });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getTimeProgress = () => {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    let TimeRef = db.collection("Progress").doc(User);

    let getDoc = TimeRef.get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log("Document data:", doc.data().stageProgress);
          this.setState({
            introProgress: doc.data().stageProgress.intro,
            principalProgress: doc.data().stageProgress.principal,
            coachProgress: doc.data().stageProgress.coach,
            synthesizerProgress: doc.data().stageProgress.synthesizer,
            maestroProgress: doc.data().stageProgress.maestro,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  render() {
    const data = this.state.data;
    console.log(data);

    var introProgress = this.state.introProgress;
    var principalProgress = this.state.principalProgress;
    var coachProgress = this.state.coachProgress;
    var synthesizerProgress = this.state.synthesizerProgress;
    var maestroProgress = this.state.maestroProgress;

    const percentage = 9;

    return (
      <div>
        {data.map((time, index) => {
          console.log("time");

          var Icon = <h1>Hello</h1>;

          console.log("Time Locked? " + time[1].locked);

          if (time[1].locked === true) {
            var Icon = (
              <LockedContainer>
                <box-icon name="lock"></box-icon>
              </LockedContainer>
            );
          } else {
            switch (time[1].title) {
              case "Intro":
                console.log(time[1].title);
                var Icon = (
                  <CircularProgressbar
                    style={{
                      width: 100,
                      position: "absolute",
                    }}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#2ed5bd`,
                      textColor: `#2ed5bd`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                    value={introProgress}
                    text={`${introProgress}%`}
                  />
                );
                break;
              case "Principal":
                console.log(time[1].title);
                var Icon = (
                  <CircularProgressbar
                    style={{
                      width: 100,
                      position: "absolute",
                    }}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#ff8b00`,
                      textColor: `#ff8b00`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                    value={principalProgress}
                    text={`${principalProgress}%`}
                  />
                );
                break;
              case "Coach":
                console.log(time[1].title);
                var Icon = (
                  <CircularProgressbar
                    style={{
                      width: 100,
                      position: "absolute",
                    }}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#f70c2e`,
                      textColor: `#f70c2e`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                    value={coachProgress}
                    text={`${coachProgress}%`}
                  />
                );
                break;
              case "Synthesizer":
                console.log(time[1].title);
                var Icon = (
                  <CircularProgressbar
                    style={{
                      width: 100,
                      position: "absolute",
                    }}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#10029c`,
                      textColor: `#10029c`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                    value={synthesizerProgress}
                    text={`${synthesizerProgress}%`}
                  />
                );
                break;
              case "Maestro":
                console.log(time[1].title);
                var Icon = (
                  <CircularProgressbar
                    style={{
                      width: 100,
                      position: "absolute",
                    }}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#ff4a52`,
                      textColor: `#ff4a52`,
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                    value={maestroProgress}
                    text={`${maestroProgress}%`}
                  />
                );
                break;
              case "Mentor":
                console.log(time[1].title);
                var Icon = (
                  <LockedContainer>
                    <box-icon
                      size="lg"
                      color="#a3ff03"
                      name="check-circle"
                      type="solid"
                    ></box-icon>
                  </LockedContainer>
                );
                break;
              default:
                console.log(time[1].title);
            }
          }

          return (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: `${time[1].color}`,
                padding: 3,
                borderRadius: 20,
              }}
              contentArrowStyle={{
                borderRight: "10px solid " + `${time[1].color}`,
              }}
              date={time[1].date}
              iconStyle={{
                background: "rgb(255, 255, 243)",
              }}
              icon={Icon}
            >
              <TimeContainer>
                <TimeImage src={time[1].imageurl} />
                <TimeInfo>
                  <TimeTitle className="vertical-timeline-element-title">
                    {time[1].title}
                  </TimeTitle>
                  <TimeDescription>{time[1].description}</TimeDescription>

                  <Button
                    style={{ backgroundColor: `${time[1].pathcolor}` }}
                    onClick={() => {
                      this.props.tab(time[1].tab);
                    }}
                  >
                    Continue
                  </Button>
                </TimeInfo>
              </TimeContainer>
            </VerticalTimelineElement>
          );
        })}
      </div>
    );
  }
}

/*
   <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={
            <LockedContainer>
              <box-icon name='star'></box-icon>
            </LockedContainer>
          }
        />
*/

const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.mobileS} {
    flex-direction: column;
  }

  @media ${device.mobileM} {
    flex-direction: column;
  }

  @media ${device.mobileL} {
    flex-direction: column;
  }

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.laptop} {
    flex-direction: row;
  }

  @media ${device.laptopL} {
    flex-direction: row;
  }

  @media ${device.desktop} {
    flex-direction: row;
  }

  @media ${device.desktopL} {
    flex-direction: row;
  }
`;

const TimeImage = styled.img`
  flex: 1;
  display: block;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
  }
`;

const TimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: white;
`;

const TimeTitle = styled.h1`
  text-align: center;
  font-size: 22px !important;
  font-weight: bold;
  flex: 1;

  @media ${device.mobileS} {
    font-size: 18px !important;
    margin-bottom: 5px !important;
  }

  @media ${device.mobileM} {
    font-size: 18px !important;
    margin-bottom: 5px !important;
  }
`;

const TimeDescription = styled.p`
  flex: 2;
  font-size: 12px !important;
  color: #798495 !important;
  line-height: 1.6 !important;
  font-weight: lighter;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
    display: none;
  }

  @media ${device.mobileL} {
    display: none;
  }

  @media ${device.tablet} {
    display: block;
  }
`;

const LockedContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

/*<VerticalTimelineElement
          className='vertical-timeline-element--work'
          contentStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#000'
          }}
          contentArrowStyle={{
            borderRight: '7px solid  rgb(33, 150, 243)'
          }}
          date='2011 - present'
          iconStyle={{
            background: 'rgb(33, 150, 243)',
            color: '#000'
          }}
          icon='Hello'
        >
          <h3 className='vertical-timeline-element-title'>Creative Director</h3>
          <h4 className='vertical-timeline-element-subtitle'>Miami, FL</h4>
          <p>
            Creative Direction, User Experience, Visual Design, Project
            Management, Team Leading
          </p>
        </VerticalTimelineElement> */

/*
        {data.map((time, index) => {
          var tab = "4";
          var Icon = <h1>Hello</h1>;

          if (time.locked === true) {
            var Icon = (
              <LockedContainer>
                <box-icon name="lock"></box-icon>
              </LockedContainer>
            );
          } else if (time.locked === false && time.title === "Intro") {
            if (introProgress === 100) {
              var Icon = (
                <LockedContainer>
                  <box-icon
                    size="lg"
                    color="#a3ff03"
                    name="check-circle"
                    type="solid"
                  ></box-icon>
                </LockedContainer>
              );
            } else {
              var Icon = (
                <CircularProgressbar
                  style={{
                    width: 100,
                    position: "absolute",
                  }}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "30px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#2ed5bd`,
                    textColor: `#2ed5bd`,
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                  value={introProgress}
                  text={`${introProgress}%`}
                />
              );
            }
          } else if (time.locked === false && time.title === "1. Principal") {
            if (principalProgress === 100) {
              var Icon = (
                <LockedContainer>
                  <box-icon
                    size="lg"
                    color="#a3ff03"
                    name="check-circle"
                    type="solid"
                  ></box-icon>
                </LockedContainer>
              );
            } else {
              var Icon = (
                <CircularProgressbar
                  style={{
                    width: 100,
                    position: "absolute",
                  }}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "30px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#ff8b00`,
                    textColor: `#ff8b00`,
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                  value={principalProgress}
                  text={`${principalProgress}%`}
                />
              );
            }
          } else if (time.locked === false && time.title === "2. Coach") {
            if (coachProgress === 100) {
              var Icon = (
                <LockedContainer>
                  <box-icon
                    size="lg"
                    color="#a3ff03"
                    name="check-circle"
                    type="solid"
                  ></box-icon>
                </LockedContainer>
              );
            } else {
              var Icon = (
                <CircularProgressbar
                  style={{
                    width: 100,
                    position: "absolute",
                  }}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "30px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#f70c2e`,
                    textColor: `#f70c2e`,
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                  value={coachProgress}
                  text={`${coachProgress}%`}
                />
              );
            }
          } else if (time.locked === false && time.title === "3. Synthesizer") {
            if (synthesizerProgress === 100) {
              var Icon = (
                <LockedContainer>
                  <box-icon
                    size="lg"
                    color="#a3ff03"
                    name="check-circle"
                    type="solid"
                  ></box-icon>
                </LockedContainer>
              );
            } else {
              var Icon = (
                <CircularProgressbar
                  style={{
                    width: 100,
                    position: "absolute",
                  }}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "30px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#10029c`,
                    textColor: `#10029c`,
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                  value={synthesizerProgress}
                  text={`${synthesizerProgress}%`}
                />
              );
            }
          } else if (time.locked === false && time.title === "4. Maestro") {
            if (maestroProgress === 100) {
              var Icon = (
                <LockedContainer>
                  <box-icon
                    size="lg"
                    color="#a3ff03"
                    name="check-circle"
                    type="solid"
                  ></box-icon>
                </LockedContainer>
              );
            } else {
              var Icon = (
                <CircularProgressbar
                  style={{
                    width: 100,
                    position: "absolute",
                  }}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "30px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: `#c6fef4`,
                    textColor: `#c6fef4`,
                    trailColor: "#d6d6d6",
                    backgroundColor: "#3e98c7",
                  })}
                  value={maestroProgress}
                  text={`${maestroProgress}%`}
                />
              );
            }
          }

          return (
            <VerticalTimelineElement
              key={index}
              className="vertical-timeline-element--work"
              contentStyle={{
                background: `${time.color}`,
                padding: 3,
                borderRadius: 20,
              }}
              contentArrowStyle={{
                borderRight: "10px solid " + `${time.color}`,
              }}
              date={time.date}
              iconStyle={{
                background: "rgb(255, 255, 243)",
              }}
              icon={Icon}
            >
              <TimeContainer>
                <TimeImage src={time.imageurl} />
                <TimeInfo>
                  <TimeTitle className="vertical-timeline-element-title">
                    {time.title}
                  </TimeTitle>
                  <TimeDescription>{time.description}</TimeDescription>

                  <Button
                    style={{ backgroundColor: `${time.pathcolor}` }}
                    onClick={() => {
                      this.props.tab(time.tab);
                    }}
                  >
                    Continue
                  </Button>
                </TimeInfo>
              </TimeContainer>
            </VerticalTimelineElement>
          );
        })}*/
