import React, { Component } from "react";

import { Row, Col, Button, Card, CardBody } from "reactstrap";

import { Link } from "react-router-dom";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";

import "./styles.css";

import { device } from "./device.js";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      progress: 8,
      selected: 0,
      homeCurrent: "",
      homeEpisodeTitle: "",
      homeEpisodeDescription: "",
      homeEpisodeUrl: "",
      introLocked: false,
      introProgress: 0,
      introCurrent: "",
      introEpisodeTitle: "",
      introEpisodeDescription: "",
      introEpisodeUrl: "",
      principalLocked: false,
      principalProgress: 0,
      principalCurrent: "",
      principalEpisodeTitle: "",
      principalEpisodeDescription: "",
      principalEpisodeUrl: "",
      coachLocked: false,
      coachProgress: 0,
      coachCurrent: "",
      coachEpisodeTitle: "",
      coachEpisodeDescription: "",
      coachEpisodeUrl: "",
      synthesizerLocked: false,
      synthesizerProgress: 0,
      synthesizerCurrent: "",
      synthesizerEpisodeTitle: "",
      synthesizerEpisodeDescription: "",
      synthesizerEpisodeUrl: "",
      maestroLocked: false,
      maestroProgress: 0,
      maestroCurrent: "",
      maestroEpisodeTitle: "",
      maestroEpisodeDescription: "",
      maestroEpisodeUrl: "",
      mentorLocked: true,
      stagesProgressTotal: 0,
    };
  }
  componentWillMount() {}

  componentDidMount() {
    this.getUserData();
    this.getCurrent();
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.calculateIntro();
            this.calculatePrincipal();
            this.calculateCoach();
            this.calculateSynthesizer();
            this.calculateMaestro();
            this.getMentor();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  calculateIntro() {
    var data = this.state.data;
    console.log("Calculate Intro");

    var episodeNumberIntro = 3;
    var episode001 = data[0].episode001.progress / episodeNumberIntro;
    var episode002 = data[0].episode002.progress / episodeNumberIntro;
    var episode003 = data[0].episode003.progress / episodeNumberIntro;

    console.log(episode001);
    console.log(episode002);
    console.log(episode003);

    var episodeIntroTotal = episode001 + episode002 + episode003;

    this.setState({ introProgress: Math.round(episodeIntroTotal) }, () => {});
  }

  getHomeEpisode = () => {
    const db = firebase.firestore();

    var homeCurrent = this.state.homeCurrent;

    var homeCurrentClean = homeCurrent.replace("/", "");

    if (homeCurrentClean === "") {
    } else {
      var docRef = db
        .collection("/Courses/001/00-Intro/Content/Content")
        .doc(homeCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              homeEpisodeTitle: doc.data().title,
              homeEpisodeDescription: doc.data().overview,
              homeEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  getIntroEpisode = () => {
    const db = firebase.firestore();

    var introCurrent = this.state.introCurrent;

    var introCurrentClean = introCurrent.replace("/", "");

    if (introCurrentClean === "") {
    } else {
      var docRef = db
        .collection("/Courses/001/00-Intro/Content/Content")
        .doc(introCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              introEpisodeTitle: doc.data().title,
              introEpisodeDescription: doc.data().overview,
              introEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  calculatePrincipal() {
    var data = this.state.data;
    console.log("Calculate Principal");
    var episodeNumberPrincipal = 12;

    var episode0011 = data[0].episode0011.progress / episodeNumberPrincipal;
    var episode0012 = data[0].episode0012.progress / episodeNumberPrincipal;
    var episode0013 = data[0].episode0013.progress / episodeNumberPrincipal;
    var episode0014 = data[0].episode0014.progress / episodeNumberPrincipal;
    var episode0015 = data[0].episode0015.progress / episodeNumberPrincipal;
    var episode0016 = data[0].episode0016.progress / episodeNumberPrincipal;
    var episode0017 = data[0].episode0017.progress / episodeNumberPrincipal;
    var episode0018 = data[0].episode0018.progress / episodeNumberPrincipal;
    var episode0019 = data[0].episode0019.progress / episodeNumberPrincipal;
    var episode0110 = data[0].episode0110.progress / episodeNumberPrincipal;
    var episode0111 = data[0].episode0111.progress / episodeNumberPrincipal;
    var episode0112 = data[0].episode0112.progress / episodeNumberPrincipal;

    console.log(episode0011);
    console.log(episode0012);
    console.log(episode0013);
    console.log(episode0014);
    console.log(episode0015);
    console.log(episode0016);
    console.log(episode0017);
    console.log(episode0018);
    console.log(episode0019);
    console.log(episode0110);
    console.log(episode0111);
    console.log(episode0112);

    var episodePrincipalTotal =
      episode0011 +
      episode0012 +
      episode0013 +
      episode0014 +
      episode0015 +
      episode0016 +
      episode0017 +
      episode0018 +
      episode0019 +
      episode0110 +
      episode0111 +
      episode0112;

    this.setState(
      { principalProgress: Math.round(episodePrincipalTotal) },
      () => {}
    );
  }

  getPrincipalEpisode = () => {
    const db = firebase.firestore();

    var principalCurrent = this.state.principalCurrent;

    var principalCurrentClean = principalCurrent.replace("/", "");

    if (principalCurrentClean === "") {
    } else {
      console.log(principalCurrentClean);

      var docRef = db
        .collection("/Courses/001/Principal/Content/Content")
        .doc(principalCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              principalEpisodeTitle: doc.data().title,
              principalEpisodeDescription: doc.data().overview,
              principalEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  calculateCoach() {
    var data = this.state.data;
    console.log("Calculate Coach");
    var episodeNumberPrincipal = 24;

    var episode0021 = data[0].episode0021.progress / episodeNumberPrincipal;
    var episode0022 = data[0].episode0022.progress / episodeNumberPrincipal;
    var episode0023 = data[0].episode0023.progress / episodeNumberPrincipal;
    var episode0024 = data[0].episode0024.progress / episodeNumberPrincipal;
    var episode0025 = data[0].episode0025.progress / episodeNumberPrincipal;
    var episode0026 = data[0].episode0026.progress / episodeNumberPrincipal;
    var episode0031 = data[0].episode0031.progress / episodeNumberPrincipal;
    var episode0032 = data[0].episode0032.progress / episodeNumberPrincipal;
    var episode0033 = data[0].episode0033.progress / episodeNumberPrincipal;
    var episode0034 = data[0].episode0034.progress / episodeNumberPrincipal;
    var episode0035 = data[0].episode0035.progress / episodeNumberPrincipal;
    var episode0036 = data[0].episode0036.progress / episodeNumberPrincipal;
    var episode0037 = data[0].episode0037.progress / episodeNumberPrincipal;
    var episode0038 = data[0].episode0038.progress / episodeNumberPrincipal;
    var episode0039 = data[0].episode0039.progress / episodeNumberPrincipal;
    var episode0310 = data[0].episode0310.progress / episodeNumberPrincipal;
    var episode0311 = data[0].episode0311.progress / episodeNumberPrincipal;
    var episode0041 = data[0].episode0041.progress / episodeNumberPrincipal;
    var episode0042 = data[0].episode0042.progress / episodeNumberPrincipal;
    var episode0043 = data[0].episode0043.progress / episodeNumberPrincipal;
    var episode0044 = data[0].episode0044.progress / episodeNumberPrincipal;
    var episode0045 = data[0].episode0045.progress / episodeNumberPrincipal;
    var episode0046 = data[0].episode0046.progress / episodeNumberPrincipal;
    var episode0047 = data[0].episode0047.progress / episodeNumberPrincipal;

    console.log(episode0021);
    console.log(episode0022);
    console.log(episode0023);
    console.log(episode0024);
    console.log(episode0025);
    console.log(episode0026);
    console.log(episode0031);
    console.log(episode0032);
    console.log(episode0033);
    console.log(episode0034);
    console.log(episode0035);
    console.log(episode0036);
    console.log(episode0037);
    console.log(episode0038);
    console.log(episode0039);
    console.log(episode0310);
    console.log(episode0311);
    console.log(episode0041);
    console.log(episode0042);
    console.log(episode0043);
    console.log(episode0044);
    console.log(episode0045);
    console.log(episode0046);
    console.log(episode0047);

    var episodeCoachTotal =
      episode0021 +
      episode0022 +
      episode0023 +
      episode0024 +
      episode0025 +
      episode0026 +
      episode0031 +
      episode0032 +
      episode0033 +
      episode0034 +
      episode0035 +
      episode0036 +
      episode0037 +
      episode0038 +
      episode0039 +
      episode0310 +
      episode0311 +
      episode0041 +
      episode0042 +
      episode0043 +
      episode0044 +
      episode0045 +
      episode0046 +
      episode0047;

    this.setState({ coachProgress: Math.round(episodeCoachTotal) }, () => {});
  }

  getCoachEpisode = () => {
    const db = firebase.firestore();

    var coachCurrent = this.state.coachCurrent;

    var coachCurrentClean = coachCurrent.replace("/", "");

    if (coachCurrentClean === "") {
    } else {
      console.log(coachCurrentClean);

      var docRef = db
        .collection("/Courses/001/Coach/Content/Content")
        .doc(coachCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              coachEpisodeTitle: doc.data().title,
              coachEpisodeDescription: doc.data().overview,
              coachEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  calculateSynthesizer() {
    var data = this.state.data;

    console.log("Calculate Synthesizer");
    var episodeNumberSynthesizer = 17;

    var episode0051 = data[0].episode0051.progress / episodeNumberSynthesizer;
    var episode0052 = data[0].episode0052.progress / episodeNumberSynthesizer;
    var episode0053 = data[0].episode0053.progress / episodeNumberSynthesizer;
    var episode0054 = data[0].episode0054.progress / episodeNumberSynthesizer;
    var episode0055 = data[0].episode0055.progress / episodeNumberSynthesizer;
    var episode0056 = data[0].episode0056.progress / episodeNumberSynthesizer;
    var episode0061 = data[0].episode0061.progress / episodeNumberSynthesizer;
    var episode0062 = data[0].episode0062.progress / episodeNumberSynthesizer;
    var episode0063 = data[0].episode0063.progress / episodeNumberSynthesizer;
    var episode0064 = data[0].episode0064.progress / episodeNumberSynthesizer;
    var episode0065 = data[0].episode0065.progress / episodeNumberSynthesizer;
    var episode0071 = data[0].episode0071.progress / episodeNumberSynthesizer;
    var episode0072 = data[0].episode0072.progress / episodeNumberSynthesizer;
    var episode0073 = data[0].episode0073.progress / episodeNumberSynthesizer;
    var episode0074 = data[0].episode0074.progress / episodeNumberSynthesizer;
    var episode0075 = data[0].episode0075.progress / episodeNumberSynthesizer;
    var episode0076 = data[0].episode0076.progress / episodeNumberSynthesizer;

    console.log(episode0051);
    console.log(episode0052);
    console.log(episode0053);
    console.log(episode0054);
    console.log(episode0055);
    console.log(episode0056);

    console.log(episode0061);
    console.log(episode0062);
    console.log(episode0063);
    console.log(episode0064);
    console.log(episode0065);
    console.log(episode0071);
    console.log(episode0072);
    console.log(episode0073);
    console.log(episode0074);
    console.log(episode0075);
    console.log(episode0076);

    var episodeSynthesizerTotal =
      episode0051 +
      episode0052 +
      episode0053 +
      episode0054 +
      episode0055 +
      episode0056 +
      episode0061 +
      episode0062 +
      episode0063 +
      episode0064 +
      episode0065 +
      episode0071 +
      episode0072 +
      episode0073 +
      episode0074 +
      episode0075 +
      episode0076;

    this.setState(
      { synthesizerProgress: Math.round(episodeSynthesizerTotal) },
      () => {}
    );
  }

  getSynthesizerEpisode = () => {
    const db = firebase.firestore();

    var synthesizerCurrent = this.state.synthesizerCurrent;

    var synthesizerCurrentClean = synthesizerCurrent.replace("/", "");

    if (synthesizerCurrentClean === "") {
    } else {
      console.log(synthesizerCurrentClean);

      var docRef = db
        .collection("/Courses/001/Synthesizer/Content/Content")
        .doc(synthesizerCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              synthesizerEpisodeTitle: doc.data().title,
              synthesizerEpisodeDescription: doc.data().overview,
              synthesizerEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  calculateMaestro() {
    var data = this.state.data;

    console.log("Calculate Maestro");
    var episodeNumberMaestro = 7;

    var episode0081 = data[0].episode0081.progress / episodeNumberMaestro;
    var episode0082 = data[0].episode0082.progress / episodeNumberMaestro;
    var episode0083 = data[0].episode0083.progress / episodeNumberMaestro;
    var episode0084 = data[0].episode0084.progress / episodeNumberMaestro;
    var episode0085 = data[0].episode0085.progress / episodeNumberMaestro;
    var episode0086 = data[0].episode0086.progress / episodeNumberMaestro;
    var episode0087 = data[0].episode0087.progress / episodeNumberMaestro;

    console.log(episode0081);
    console.log(episode0082);
    console.log(episode0083);
    console.log(episode0084);
    console.log(episode0085);
    console.log(episode0086);
    console.log(episode0087);

    var episodeMaestroTotal =
      episode0081 +
      episode0082 +
      episode0083 +
      episode0084 +
      episode0085 +
      episode0086 +
      episode0087;

    this.setState({ maestroProgress: Math.round(episodeMaestroTotal) }, () => {
      this.calculateTotal();
    });
  }

  getMaestroEpisode = () => {
    const db = firebase.firestore();

    var maestroCurrent = this.state.maestroCurrent;

    var maestroCurrentClean = maestroCurrent.replace("/", "");

    if (maestroCurrentClean === "") {
    } else {
      console.log(maestroCurrentClean);

      var docRef = db
        .collection("/Courses/001/Maestro/Content/Content")
        .doc(maestroCurrentClean);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            console.log("Logging HERE", doc.data());

            this.setState({
              maestroEpisodeTitle: doc.data().title,
              maestroEpisodeDescription: doc.data().overview,
              maestroEpisodeUrl: doc.data().currentEpisode,
            });
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    }
  };

  getMentor = () => {
    console.log("Running Mentor");
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    console.log(user);

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Timeline Below");

          var ReadyData = Object.entries(doc.data().timeline);

          console.log("READYDATA");

          console.log(ReadyData.sort());

          console.log(ReadyData);

          ReadyData.map((data) => {
            console.log(data[1].title);

            if (data[1].title === "Mentor") {
              console.log("Inside Mentor");

              console.log(data[1].locked);

              this.setState({ mentorLocked: data[1].locked });

              //   this.setState({ mentorLocked: true });
            } else {
              console.log("Not Inside Mentor");
              //  this.setState({ mentorLocked: false });
            }
          });

          //  this.setState({ data: ReadyData.sort() });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getCurrent() {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    let userRef = db.collection("Progress").doc(User);

    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log(doc.data().current);

          this.setState(
            {
              homeCurrent: doc.data().current.home,
              introCurrent: doc.data().current.intro,
              principalCurrent: doc.data().current.principal,
              coachCurrent: doc.data().current.coach,
              synthesizerCurrent: doc.data().current.synthesizer,
              maestroCurrent: doc.data().current.maestro,
            },
            () => {
              this.getHomeEpisode();
              this.getIntroEpisode();
              this.getPrincipalEpisode();
              this.getCoachEpisode();
              this.getSynthesizerEpisode();
              this.getMaestroEpisode();
            }
          );
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  }

  calculateTotal() {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    console.log("Calculate Total");
    var stageNumberTotal = 5;

    var introProgress = this.state.introProgress / stageNumberTotal;
    var principalProgress = this.state.principalProgress / stageNumberTotal;
    var coachProgress = this.state.coachProgress / stageNumberTotal;
    var synthesizerProgress = this.state.synthesizerProgress / stageNumberTotal;
    var maestroProgress = this.state.maestroProgress / stageNumberTotal;

    console.log(introProgress);
    console.log(principalProgress);
    console.log(coachProgress);
    console.log(synthesizerProgress);
    console.log(maestroProgress);

    var stagesProgressTotal =
      introProgress +
      principalProgress +
      coachProgress +
      synthesizerProgress +
      maestroProgress;

    this.setState(
      { stagesProgressTotal: Math.round(stagesProgressTotal) },
      () => {
        let progressRef = db.collection("Progress").doc(User);

        let updateSingle = progressRef.update({
          "stageProgress.intro": this.state.introProgress,
          "stageProgress.principal": this.state.principalProgress,
          "stageProgress.coach": this.state.coachProgress,
          "stageProgress.synthesizer": this.state.synthesizerProgress,
          "stageProgress.maestro": this.state.maestroProgress,
          "stageProgress.total": this.state.stagesProgressTotal,
        });
      }
    );
  }

  render() {
    var introProgress = this.state.introProgress;
    var introLocked = this.state.introLocked;
    var principalProgress = this.state.principalProgress;
    var principalLocked = this.state.principalLocked;
    var coachProgress = this.state.coachProgress;
    var coachLocked = this.state.coachLocked;
    var synthesizerProgress = this.state.synthesizerProgress;
    var synthesizerLocked = this.state.synthesizerLocked;
    var maestroProgress = this.state.maestroProgress;
    var maestroLocked = this.state.maestroLocked;
    var mentorLocked = this.state.mentorLocked;
    var stagesProgressTotal = this.state.stagesProgressTotal;

    var homeWelcome =
      "<p>Every journey has a starting point and a means for getting to where you’re headed. Your PPI Lab Core Content is set before you as your Success Path, guiding you through the learning stages of Next-Gen Leadership.</p>";

    var introWelcome =
      "<p>Welcome to your Orientation! In this Stage you&rsquo;ll be given guidance on how to get the most out of PPI Lab. We&rsquo;re also offering a bit of history of where the PPI approach came from, how it has evolved, and what it can do for you and your team.</p>";

    var principalWelcome =
      "<p>Upon completion of the <em>Principal</em> Stage, you&rsquo;ll have a new awareness and deeper understanding of the core of all team and organizational performance- <em>Human Performance</em>.&rdquo; In addition, you will have quantified WHAT to do to sustainably take your performance from where it is to where you want it to be.</p>";

    var coachWelcome =
      "<p>As a <em>Coach</em>, you will understand and own your role in helping create the current climate, conditions, and work culture of your team/organization. You will make a conscious choice to transform relationships and interactions, and will know HOW to quickly, effectively, and positively influence the choices, actions, and behaviors of your team members.</p>";

    var synthesizerWelcome =
      "<p>As a <em>Synthesizer</em>, you will understand HOW to overcome and reverse negative <em>drift</em> and <em>accumulation</em> while minimizing team member mistakes. You will know HOW to lead with Purpose, provide Autonomy, and interact in a manner that grows relationships while aligning team member choices, actions, and behaviors with organizational Core Principles.</p>";

    var maestroWelcome =
      "<p>Becoming a <em>Maestro</em>, you will know HOW to orchestrate the skills, expertise, and motivations of your team members into a symphony of Reliability, Efficiency, Productivity, and Safety. You will role model, grow, and support one team, with one goal, having one conversation.</p>";

    var mentorWelcome =
      "<p>Continuing your journey, you are now a <em>Steward </em>of ever-improving performance and a <em>Mentor</em> to those you lead. Just as the world continues to evolve, so does your learning, your depth of understanding, and your capabilities to inspire.</p>";

    var message = <h1>Hello</h1>;

    console.log("EPISODE URL " + this.state.introEpisodeUrl);

    if (this.state.homeEpisodeUrl === "") {
      var homeEpisodeData = <p></p>;
    } else {
      var homeEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.homeEpisodeTitle}</h1>
          {ReactHtmlParser(this.state.homeEpisodeDescription)}
          <Button className="homeContinue">
            <Link style={{ color: "white" }} to={this.state.homeEpisodeUrl}>
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.state.introEpisodeUrl === "") {
      var introEpisodeData = <p></p>;
    } else {
      var introEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.introEpisodeTitle}</h1>
          {ReactHtmlParser(this.state.introEpisodeDescription)}
          <Button className="introContinue">
            <Link style={{ color: "white" }} to={this.state.introEpisodeUrl}>
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.state.principalEpisodeUrl === "") {
      var principalEpisodeData = <p></p>;
    } else {
      var principalEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.principalEpisodeTitle}</h1>
          {ReactHtmlParser(this.state.principalEpisodeDescription)}
          <Button className="principalContinue">
            <Link
              style={{ color: "white" }}
              to={this.state.principalEpisodeUrl}
            >
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.state.coachEpisodeUrl === "") {
      var coachEpisodeData = <p></p>;
    } else {
      var coachEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.coachEpisodeTitle}</h1>
          {ReactHtmlParser(this.state.coachEpisodeDescription)}
          <Button className="coachContinue">
            <Link style={{ color: "white" }} to={this.state.coachEpisodeUrl}>
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.state.synthesizerEpisodeUrl === "") {
      var synthesizerEpisodeData = <p></p>;
    } else {
      var synthesizerEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.synthesizerEpisodeTitle}</h1>

          {ReactHtmlParser(this.state.synthesizerEpisodeDescription)}

          <Button className="synthesizerContinue">
            <Link
              style={{ color: "white" }}
              to={this.state.synthesizerEpisodeUrl}
            >
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.state.maestroEpisodeUrl === "") {
      var maestroEpisodeData = <p></p>;
    } else {
      var maestroEpisodeData = (
        <ContinueEpisode>
          <h1>{this.state.maestroEpisodeTitle}</h1>
          {ReactHtmlParser(this.state.maestroEpisodeDescription)}
          <Button className="maestroContinue">
            <Link style={{ color: "white" }} to={this.state.maestroEpisodeUrl}>
              Continue
            </Link>
          </Button>
        </ContinueEpisode>
      );
    }

    if (this.props.content === "1") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={stagesProgressTotal}
                text={`${stagesProgressTotal}%`}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>We begin at the Core!</InfoTitle>
              <InfoDescription>{ReactHtmlParser(homeWelcome)}</InfoDescription>
            </InfoContainer>

            {homeEpisodeData}
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "2") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={introProgress}
                text={`${introProgress}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgb(46,213,189)`,
                  textColor: `#2ed5bd`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>Intro</InfoTitle>
              <InfoDescription>{ReactHtmlParser(introWelcome)}</InfoDescription>
            </InfoContainer>
            <InfoEpisode>
              {introLocked ? <div></div> : introEpisodeData}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "3") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={principalProgress}
                text={`${principalProgress}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgb(255,139,0)`,
                  textColor: `#ff8b00`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>Principal</InfoTitle>
              <InfoDescription>
                {ReactHtmlParser(principalWelcome)}
              </InfoDescription>
            </InfoContainer>

            <InfoEpisode>
              {principalLocked ? <div></div> : principalEpisodeData}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "4") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={coachProgress}
                text={`${coachProgress}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgb(247,12,46)`,
                  textColor: `#f70c2e`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>Coach</InfoTitle>
              <InfoDescription>{ReactHtmlParser(coachWelcome)}</InfoDescription>
            </InfoContainer>
            <InfoEpisode>
              {" "}
              {coachLocked ? <div></div> : coachEpisodeData}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "5") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={synthesizerProgress}
                text={`${synthesizerProgress}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `rgb(16,2,156)`,
                  textColor: `#10029c`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>Synthesizer</InfoTitle>
              <InfoDescription>
                {ReactHtmlParser(synthesizerWelcome)}
              </InfoDescription>
            </InfoContainer>

            <InfoEpisode>
              {synthesizerLocked ? <div></div> : synthesizerEpisodeData}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "6") {
      var message = (
        <Row>
          <StageContainer>
            <ProgressContainer>
              <CircularProgressbar
                className="progressCircle"
                value={maestroProgress}
                text={`${maestroProgress}%`}
                styles={buildStyles({
                  // Rotation of path and trail, in number of turns (0-1)
                  rotation: 0,

                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                  strokeLinecap: "butt",

                  // Text size
                  textSize: "30px",

                  // How long animation takes to go from one percentage to another, in seconds
                  pathTransitionDuration: 0.5,

                  // Can specify path transition in more detail, or remove it entirely
                  // pathTransition: 'none',

                  // Colors
                  pathColor: `#ff4a52`,
                  textColor: `#ff4a52`,
                  trailColor: "#d6d6d6",
                  backgroundColor: "#3e98c7",
                })}
              />
            </ProgressContainer>
            <InfoContainer>
              <InfoTitle>Maestro</InfoTitle>
              <InfoDescription>
                {ReactHtmlParser(maestroWelcome)}
              </InfoDescription>
            </InfoContainer>
            <InfoEpisode>
              {maestroLocked ? <div></div> : maestroEpisodeData}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else if (this.props.content === "7") {
      var message = (
        <Row>
          <StageContainer>
            <InfoContainer>
              <InfoTitle>Mentor</InfoTitle>
              <InfoDescription>
                {ReactHtmlParser(mentorWelcome)}
              </InfoDescription>
            </InfoContainer>
            <InfoEpisode>
              {mentorLocked ? (
                <div>
                  <p>Locked</p>
                </div>
              ) : (
                <div>Unlocked</div>
              )}
            </InfoEpisode>
          </StageContainer>
        </Row>
      );
    } else {
      var message = <h1>Null</h1>;
    }

    return (
      <React.Fragment>
        <div>
          <CardBody className="pt-0">
            <Row>
              <Col mg={12} xl={12}>
                <Card style={{ overflow: "hidden", borderRadius: 20 }}>
                  <CardBody>{message}</CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Progress);

const StageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  flex: 1;
`;

const InfoTitle = styled.h1`
  @media ${device.mobileS} {
    text-align: center;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
`;

const InfoEpisode = styled.div`
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.tablet} {
    flex: 3;
    display: flex;
  }
`;

const InfoDescription = styled.div`
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const ContinueEpisode = styled.div`
  flex: 3;
  padding: 20px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Info = styled.h1``;
