import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import Login from './auth/login/reducer'
import Account from './auth/register/reducer'
import ForgetPassword from './auth/forgetpwd/reducer'

// Other

import User from './auth/user/reducer'

const rootReducer = combineReducers({
  // public
  User,
  Layout,
  Login,
  Account,
  ForgetPassword
})

export default rootReducer
