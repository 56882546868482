import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Media,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import Modal from "react-modal";

import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import { connect } from "react-redux";

import ReactPlayer from "react-player";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import styled from "styled-components";

import logo from "../../assets/images/small/Video Clip Banner_square_reversed.png";

import "./styles.css";

const customStyles = {
  content: {
    position: "absolute",
    top: 80,
    left: 40,
    right: 40,
    bottom: 80,
    padding: 40,
  },
};

class Insider extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, data: [] };
  }

  componentDidMount() {
    this.uploadData();
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    this.getData();
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  uploadData = () => {
    const db = firebase.firestore();

    const data = {
      title: "Dewalt Commercial-Situational Awareness",
      category: "Error EliminationTools, Funny",
      description:
        "Funny commercial by Dewalt Construction showing the value of Situational Awareness",
      duration: "00:37",
      thumburl: "url",
      videourl:
        "https://ppilabassets.s3.amazonaws.com/Courses/Course01/VideoLibrary/1.m4v",
    };

    const vid01 = db.collection("VideoLibrary").doc("24").set(data);
  };

  getData = () => {
    const db = firebase.firestore();

    let videosRef = db.collection("VideoLibrary");
    let allVideos = videosRef
      .get()
      .then((snap) => {
        snap.forEach((video) => {
          console.log(video.data());

          var currentData = this.state.data;

          currentData.push(video.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  ref = (player) => {
    this.player = player;
  };

  handleProgress(response) {
    const db = firebase.firestore();
  }

  render() {
    const data = this.state.data;
    const { open } = this.state;

    const welcome =
      "<p>Welcome to your PPI Lab Video Library! The Library is loaded with the same set of clips used by PPI master Facilitators when conducting presentations and implementation training sessions. The clips are downloadable for your use.</p>";

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Leader Manuals" />

            <CardBody className="pt-0">
              <Row>
                <StageContainer>
                  <WelcomeContainer>
                    <WelcomeLogo src={logo} />
                  </WelcomeContainer>
                  <InfoContainer>
                    <div>
                      <h1>Video Clip Library</h1>
                      {ReactHtmlParser(welcome)}
                    </div>
                  </InfoContainer>
                </StageContainer>
              </Row>
              <Row>
                {data.map((video, index) => {
                  return (
                    <EpisodeCard key={index}>
                      <Title className="mt-0">{video.title}</Title>
                      <ProgressContainer>
                        <CheckContainer>
                          <box-icon
                            color="#2ed5bd"
                            id="icon"
                            type="solid"
                            name="videos"
                          ></box-icon>
                        </CheckContainer>
                      </ProgressContainer>
                      <Duration>
                        <TextContainer>
                          <Text>{video.description}</Text>
                        </TextContainer>
                      </Duration>
                      <Duration>
                        <TextContainer>
                          <Text>
                            <box-icon type="solid" name="time"></box-icon>
                          </Text>
                        </TextContainer>
                        <TextContainer>
                          <Text>{video.duration}</Text>
                        </TextContainer>
                      </Duration>
                      <ButtonContainer>
                        <Button
                          onClick={this.onOpenModal.bind(this, index)}
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          Watch
                        </Button>
                      </ButtonContainer>
                      <Modal
                        style={customStyles}
                        isOpen={open[index]}
                        ariaHideApp={false}
                      >
                        <button
                          type="button"
                          onClick={() => this.onCloseModal()}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <CloseIcon aria-hidden="true">&times;</CloseIcon>
                        </button>
                        <TitleContainer>
                          <ModalTitle>{video.title}</ModalTitle>

                          <ModalDescription>
                            {video.description}
                          </ModalDescription>
                        </TitleContainer>

                        <PlayerContainer>
                          <ReactPlayer
                            ref={this.ref}
                            playbackRate={playbackRate}
                            onReady={this.handleReady}
                            onStart={console.log()}
                            onPlay={this.handlePlay}
                            onProgress={(response) => {
                              this.handleProgress(response);
                            }}
                            onDuration={(response) => {
                              this.setState({ videoDuration: response });
                            }}
                            onPause={this.handlePause}
                            onBuffer={console.log()}
                            onSeek={console.log()}
                            onEnded={console.log()}
                            controls={true}
                            url={video.videourl}
                            playing={playing}
                            width="100%"
                            height="100%"
                            config={{}}
                          />
                        </PlayerContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          <a
                            style={{ width: "100%", color: "white" }}
                            href={video.videourl}
                            download
                          >
                            Download
                          </a>
                        </Button>
                      </Modal>
                    </EpisodeCard>
                  );
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Insider);

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%
  border: 1px solid red;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 320px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #2ed5bd;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 20px;
`;

const Duration = styled.div`
  display: flex;
  flex: 2;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100% !important;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.span`
  font-size: 50px;
`;

/*return (
      <React.Fragment>
        <div className="page-content">
          <button onClick={this.uploadData()}>Upload Data</button>
          {data.map((video, index) => {
            return (
              <EpisodeCard key={index}>
                <Title className="mt-0">{video.title}</Title>
                <ProgressContainer>
                  <CheckContainer>
                    <box-icon
                      color="#2ed5bd"
                      id="icon"
                      type="solid"
                      name="videos"
                    ></box-icon>
                  </CheckContainer>
                </ProgressContainer>
                <Duration>
                  <TimeIcon>
                    <box-icon type="solid" name="time"></box-icon>
                  </TimeIcon>
                  <TextContainer>
                    <Text>{video.duration}</Text>
                  </TextContainer>
                </Duration>
                <ButtonContainer>
                  <Button
                    onClick={this.onOpenModal.bind(this, index)}
                    color="primary"
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    to="/dashboard"
                  >
                    Watch <box-icon type="solid" name="time"></box-icon>{" "}
                    {video.duration}
                  </Button>
                </ButtonContainer>
                <Modal
                  style={customStyles}
                  isOpen={open[index]}
                  ariaHideApp={false}
                >
                  <h1>{video.title}</h1>
                  <ReactPlayer
                    ref={this.ref}
                    playbackRate={playbackRate}
                    onReady={this.handleReady}
                    onStart={console.log()}
                    onPlay={this.handlePlay}
                    onProgress={(response) => {
                      this.handleProgress(response);
                    }}
                    onDuration={(response) => {
                      this.setState({ videoDuration: response });
                    }}
                    onPause={this.handlePause}
                    onBuffer={console.log()}
                    onSeek={console.log()}
                    onEnded={console.log()}
                    controls={true}
                    url={video.videourl}
                    playing={playing}
                    width="100%"
                    height="100%"
                    config={{}}
                  />
                  <button
                    type="button"
                    onClick={() => this.onCloseModal()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </Modal>
              </EpisodeCard>
            );*/
