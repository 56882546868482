import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

import firebase from "firebase/app";

import { connect } from "react-redux";

import { getFirebaseBackend } from "../../../helpers/authUtils.js";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

import styled from "styled-components";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      firstName: "User",
      avatar: "",
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate() {}

  getData() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    console.log("Currently Logged in User Below");

    console.log(user);
    console.log(this.props.email);
    const db = firebase.firestore();
    let cityRef = db.collection("Progress").doc(user);
    let getDoc = cityRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          let firstName = doc.data().firstName;
          let avatar = doc.data().avatar;

          this.setState({ firstName: firstName, avatar: avatar });

          //   console.log(firstName)
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  }
  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            tag="button"
          >
            <Image src={this.state.avatar}></Image>
            <span className="d-none d-xl-inline-block ml-2 mr-1">
              {this.state.firstName}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="#">
              <Link to="/profile" className="dropdown-item">
                <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                <span>Profile</span>
              </Link>
            </DropdownItem>

            <div className="dropdown-divider"></div>
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);

/*withRouter(ProfileMenu)*/

const Image = styled.img`
  width: 35px;
  border-radius: 50%;
`;
