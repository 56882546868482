import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Quiz from "react-quiz-component";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils.js";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import styled from "styled-components";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 1 [Setting the Stage]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "Which of the following is correct regarding CONTENT and CONTEXT?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Having more content is your key to a bigger, happier, more successful life.",
        "The larger your context, the more open you are to varied opinions, others’ perspectives and alternate options",
        "The higher your level of content, the more open you are to varied opinions, others’ perspectives and alternate options",
        "Expanding your context hones your expertise in a specific field of endeavor",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Your CONTEXT is how you perceive your world and everything in it. A small context leads to narrow-minded thinking. An essential step to creating a bigger and better future is to expand your CONTEXT.",
      point: "12.5",
    },
    {
      question:
        "Which of the following often promote ‘old-school thinking’ when it comes to Human Performance? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "Bad paradigms and outdated dogma are great enemies of next-gen performance improvement. Consider this- Henry Ford once said, “If I’d asked people what they wanted, they would have said ‘faster horses’.” Old-school thinking must be left in the past.",
      point: "12.5",
      answers: ["Mindfulness", "Paradigms", "Dogma", "Antecedents"],
      correctAnswer: [2, 3],
    },
    {
      question:
        "“REPS” is used to identify four attributes an individual, team, or organization MUST have to be successful. What do the letters R-E-P-S represent?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Reliability, Efficiency, Personality, Sanity",
        "Reliability, Efficacy, Productivity, Security",
        "Resilience, Effectiveness, Personality, Safety",
        "Reliability, Efficiency, Productivity, Safety",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "The four imperatives for success are Reliability, Efficiency, Productivity, and Safety. Because of how we’ve structured many organizations, combined with people wanting to do a ‘good job’ in their specific area of expertise, these four imperatives tend to cause conflicting agendas and priorities. The solution to this dilemma is (1) fostering adaptability, and (2) growing a culture of One Team with One Goal having One Conversation.",
      point: "12.5",
    },
    {
      question:
        "While requiring “top down” vision and role-modeling as well as “bottom up” peer leadership and enthusiasm, culture transformation truly comes from the “inside out”.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["True", "False"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Culture Transformation does indeed require communication of vision, role modeling, and support from the ‘top’, as well as leadership, role modeling, and positive energy from the ‘frontline’. However, true transformation comes from the inside out. If transformation is to happen in your organization, it must begin with YOU.",
      point: "12.5",
    },
    {
      question: "The Pathway™ to Next-Gen Performance Improvement begins with…",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["GOAL", "WHAT", "WHY", "HOW"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Before you begin ANY effort to get from where you are to where you want to be, you must clearly identify your compelling reason(s) WHY. This is the starting point of your Pathway™ for achieving synergized substantial sustainable performance improvement. Your Organizational WHY is your Core Principles.",
      point: "12.5",
    },
    {
      question:
        "Which are TRUE regarding why we should align to PRINCIPLES rather than VALUES at the Core of our organization? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "While many leaders and organizations continue to hang onto using “values” to identify what lies at their core, we believe using “Principles” is more powerful and clear. While values are relative, meaning they vary depending upon conditions and circumstances, Principles are fundamental. Running an organization based upon Principles allows people with differing values to work together effectively.",
      point: "12.5",
      answers: [
        "VALUES are relative whereas PRINCIPLES are fundamental",
        "Running an organization based upon PRINCIPLES allows people with differing VALUES to work together effectively",
        "VALUES change- PRINCIPLES do not",
        "People tend to internally own their PRINCIPLES while VALUES are externally impacted",
      ],
      correctAnswer: [1, 2, 3, 4],
    },
    {
      question:
        "The definition of Human Performance is: WHY we do WHAT we do the WAY we do it. Which of the terms has greatest impact toward sustaining improved performance?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["WHY", "WHAT", "WAY"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Human Performance is WHY we do WHAT we do the WAY we do it. The relative importance of each element in the definition can be seen in the formula, HP = W(A+B), where “W” stands for WHY, “A” represents Actions (WHAT), and “B” is Behaviors (WAY). Simple math illustrates an individual’s WHY is [by far] the most important consideration when seeking to promote next-level choices, actions and behaviors.",
      point: "12.5",
    },
    {
      question:
        "What is your GOAL along The Pathway™ to Next-Generation Performance Improvement?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Tapping the core of Human Performance",
        "Achieving a Systematic Approach",
        "Proactive Accountability®",
        "Viral Accountability®",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Achievement of sustainable outcomes requires Culture Transformation. The vehicle for Culture Transformation is Viral Accountability®, which is the GOAL of your Pathway™ to synergized sustainable performance improvement.",
      point: "12.5",
    },
  ],
};

export default class Recall1 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];
    var input6 = UserInput[5];
    var input7 = UserInput[6];
    var input8 = UserInput[7];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall1: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
          UserInput6: UserInput[5],
          UserInput7: UserInput[6],
          UserInput8: UserInput[7],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 8) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>

          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "3" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
