import React, { Component } from 'react'

import { Row, Col, Card, CardBody, CardImg, CardText } from 'reactstrap'
import { Link } from 'react-router-dom'

import avatar1 from '../../assets/images/users/avatar-1.jpg'
import img1 from '../../assets/images/small/img-1.jpg'
import profileImg from '../../assets/images/profile-img.png'

import { connect } from 'react-redux'

import firebase from 'firebase/app'

import styled from 'styled-components'

class Banner extends Component {
  constructor (props) {
    super(props)
    this.state = { data: [] }
  }

  componentDidMount () {
    const db = firebase.firestore()
    let citiesRef = db.collection('Banner')
    let allCities = citiesRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          console.log(doc.data())

          var currentData = this.state.data

          currentData.push(doc.data())

          this.setState({ data: currentData })
        })
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
  }

  render () {
    const data = this.state.data

    return (
      <React.Fragment>
        <div>
          <CardBody className='pt-0'>
            <Row>
              {data.map((banner, index) => {
                return (
                  <Col key={index} mg={6} xl={6}>
                    <BannerContainer>
                      <BannerInfo className='col-md-6'>
                        <BannerTitle>{banner.title}</BannerTitle>
                        <BannerDescription>
                          {banner.description}
                        </BannerDescription>
                      </BannerInfo>

                      <BannerImage className='col-md-6' src={banner.imageurl} />
                    </BannerContainer>
                  </Col>
                )
              })}
            </Row>
          </CardBody>
        </div>
      </React.Fragment>
    )
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps (state) {
  return { ...state.User }
}

export default connect(mapStateToProps)(Banner)

const BannerContainer = styled.div`
  margin: auto;
  height: 200px;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
`

const BannerInfo = styled.div`
  flex: 2;
  width: 100%;
`

const BannerTitle = styled.h1``

const BannerDescription = styled.p``

const BannerImage = styled.img`
  border-radius: 20px;
`
