import firebase from "firebase/app";
import { connect } from "react-redux";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          sessionStorage.setItem("authUser", JSON.stringify(user));
        } else {
          sessionStorage.removeItem("authUser");
        }
      });
    }
  }

  getDatabase = () => {
    const db = firebase.firestore();

    let usersRef = db.collection("Users");
    let test = [];

    let allUsers = usersRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((user) => {
          console.log(user.id, "=>", user.data());

          let test2 = test;

          test2.push(user.id);

          test2 = test;

          console.log(test);
          return test;
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  /**
   * Registers the user with given details
   */
  registerUser = (email, password, firstname, lastname, batch) => {
    console.log(email);
    console.log(password);
    console.log(firstname);
    console.log(lastname);
    console.log("Success Bitch " + batch);
    return new Promise((resolve, reject) => {
      var lower = email.toLowerCase();
      firebase
        .auth()
        .createUserWithEmailAndPassword(lower, password)
        .then(
          (user) => {
            console.log("USER HERE");
            console.log(user);
            resolve(firebase.auth().currentUser);

            // Sends User Info To DB

            const db = firebase.firestore();

            let setProgressDoc = db
              .collection("Progress")
              .doc(lower)
              .set({
                firstName: firstname,
                lastName: lastname,
                email: lower,
                plan: "plan",
                batch: batch,
                avatar:
                  "https://firebasestorage.googleapis.com/v0/b/ppilabv2.appspot.com/o/images%2F0a5036b0-75b2-487c-8dac-673f150f36bb.jpg?alt=media&token=585b234b-fb33-4200-9294-679a7f6ddad4",

                //Intro
                episode001: { seconds: 0, progress: 0, locked: false },
                episode002: { seconds: 0, progress: 0, locked: false },
                episode003: { seconds: 0, progress: 0, locked: false },
                //Principal
                episode0011: { seconds: 0, progress: 0, locked: false },
                episode0012: { seconds: 0, progress: 0, locked: false },
                episode0013: { seconds: 0, progress: 0, locked: false },
                episode0014: { seconds: 0, progress: 0, locked: false },
                episode0015: { seconds: 0, progress: 0, locked: false },
                episode0016: { seconds: 0, progress: 0, locked: false },
                episode0017: { seconds: 0, progress: 0, locked: false },
                episode0018: { seconds: 0, progress: 0, locked: false },
                episode0019: { seconds: 0, progress: 0, locked: false },
                episode0110: { seconds: 0, progress: 0, locked: false },
                episode0111: { seconds: 0, progress: 0, locked: false },
                episode0112: { seconds: 0, progress: 0, locked: false },
                // Coach
                episode0021: { seconds: 0, progress: 0, locked: true },
                episode0022: { seconds: 0, progress: 0, locked: true },
                episode0023: { seconds: 0, progress: 0, locked: true },
                episode0024: { seconds: 0, progress: 0, locked: true },
                episode0025: { seconds: 0, progress: 0, locked: true },
                episode0026: { seconds: 0, progress: 0, locked: true },
                episode0031: { seconds: 0, progress: 0, locked: true },
                episode0032: { seconds: 0, progress: 0, locked: true },
                episode0033: { seconds: 0, progress: 0, locked: true },
                episode0034: { seconds: 0, progress: 0, locked: true },
                episode0035: { seconds: 0, progress: 0, locked: true },
                episode0036: { seconds: 0, progress: 0, locked: true },
                episode0037: { seconds: 0, progress: 0, locked: true },
                episode0038: { seconds: 0, progress: 0, locked: true },
                episode0039: { seconds: 0, progress: 0, locked: true },
                episode0310: { seconds: 0, progress: 0, locked: true },
                episode0311: { seconds: 0, progress: 0, locked: true },
                episode0041: { seconds: 0, progress: 0, locked: true },
                episode0042: { seconds: 0, progress: 0, locked: true },
                episode0043: { seconds: 0, progress: 0, locked: true },
                episode0044: { seconds: 0, progress: 0, locked: true },
                episode0045: { seconds: 0, progress: 0, locked: true },
                episode0046: { seconds: 0, progress: 0, locked: true },
                episode0047: { seconds: 0, progress: 0, locked: true },
                // Synthesizer
                episode0051: { seconds: 0, progress: 0, locked: true },
                episode0052: { seconds: 0, progress: 0, locked: true },
                episode0053: { seconds: 0, progress: 0, locked: true },
                episode0054: { seconds: 0, progress: 0, locked: true },
                episode0055: { seconds: 0, progress: 0, locked: true },
                episode0056: { seconds: 0, progress: 0, locked: true },
                episode0061: { seconds: 0, progress: 0, locked: true },
                episode0062: { seconds: 0, progress: 0, locked: true },
                episode0063: { seconds: 0, progress: 0, locked: true },
                episode0064: { seconds: 0, progress: 0, locked: true },
                episode0065: { seconds: 0, progress: 0, locked: true },
                episode0071: { seconds: 0, progress: 0, locked: true },
                episode0072: { seconds: 0, progress: 0, locked: true },
                episode0073: { seconds: 0, progress: 0, locked: true },
                episode0074: { seconds: 0, progress: 0, locked: true },
                episode0075: { seconds: 0, progress: 0, locked: true },
                episode0076: { seconds: 0, progress: 0, locked: true },
                // Maestro
                episode0081: { seconds: 0, progress: 0, locked: true },
                episode0082: { seconds: 0, progress: 0, locked: true },
                episode0083: { seconds: 0, progress: 0, locked: true },
                episode0084: { seconds: 0, progress: 0, locked: true },
                episode0085: { seconds: 0, progress: 0, locked: true },
                episode0086: { seconds: 0, progress: 0, locked: true },
                episode0087: { seconds: 0, progress: 0, locked: true },
                // Timeline
                timeline: {
                  "001": {
                    color: "#af89ff",
                    date: "Coming on July 16",
                    description:
                      "The Lab is set up to take you on a journey of understanding in next-level performance improvement. ",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/Intro+icon+with+number.png",
                    lessons: "1",
                    locked: false,
                    pathcolor: "rgb(46,213,189)",
                    percentage: "0",
                    tab: "2",
                    title: "Intro",
                    url: "url",
                    order: "001",
                  },
                  "002": {
                    color: "#af89ff",
                    date: "Coming on July 16",
                    description:
                      "We are setting the stage to understand key aspects of the human side of organizational effort.",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/Principal+Icon+with+number.png",
                    lessons: "1",
                    locked: false,
                    pathcolor: "rgb(255,139,0)",
                    percentage: "0",
                    tab: "3",
                    title: "Principal",
                    url: "url",
                    order: "002",
                  },
                  "003": {
                    color: "#af89ff",
                    date: "Coming on July 27",
                    description:
                      "As a Stage 2 Leader, you’ll come to understand the foundational precepts of the Next-Gen approach to sustainable Performance Improvement.",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/Coach+Icon+with+number.png",
                    lessons: "6",
                    locked: true,
                    pathcolor: "rgb(247,12,46)",
                    percentage: "0",
                    tab: "4",
                    title: "Coach",
                    url: "url",
                    order: "003",
                  },
                  "004": {
                    color: "#af89ff",
                    date: "Coming on August 17",
                    description:
                      "In this Stage of becoming a Next-Gen Leader, you learn how to Synthesize- how to put what you’ve learned thus far together to move toward your goal of Viral Accountability®.",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/Synthesizer+icon+with+number.png",
                    lessons: "6",
                    locked: true,
                    pathcolor: "rgb(16,2,156)",
                    percentage: "0",
                    tab: "5",
                    title: "Synthesizer",
                    url: "url",
                    order: "004",
                  },
                  "005": {
                    color: "#af89ff",
                    date: "Coming on September 7",
                    description:
                      "While you should now be a transformed and much more powerful leader, and have likely already implemented direct work with your team using the exercises provided thus far, this Stage brings things together. ",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/New+Maestro+icon+with+number.png",
                    lessons: "6",
                    locked: true,
                    pathcolor: "rgb(255,74,82)",
                    percentage: "0",
                    tab: "6",
                    title: "Maestro",
                    url: "url",
                    order: "005",
                  },
                  "006": {
                    color: "#af89ff",
                    date: "Coming on September 14",
                    description:
                      "Stage 5 is where you take everything you learned and implemented in the PPI Lab Core Content and continually expand and improve it.",
                    imageurl:
                      "https://ppilabassets.s3.amazonaws.com/Images/Mentor+icon+with+number.png",
                    lessons: "6",
                    locked: true,
                    pathcolor: "rgb(88,196,222)",
                    percentage: "0",
                    tab: "7",
                    title: "Mentor",
                    url: "url",
                    order: "006",
                  },
                },
                stageProgress: {
                  intro: 0,
                  principal: 0,
                  coach: 0,
                  synthesizer: 0,
                  maestro: 0,
                  total: 0,
                },
                // Current Episodes
                current: {
                  home: "/",
                  intro: "/",
                  principal: "/",
                  coach: "/",
                  synthesizer: "/",
                  maestro: "/",
                },
                tc1: {
                  Question1: "",
                  Question2A: "",
                  Question2B: "",
                  Question2C: "",
                },
                tc2: {
                  Question1: "",
                  Question2A: "",
                  Question2B: "",
                  Question2C: "",
                },
                C1FinalExam: {},
                recall1: {},
                recall2: {},
                recall3: {},
                recall4: {},
                recall5: {},
                recall6: {},
                recall7: {},
                recall8: {},
                LeaderManuals: {
                  LM00A: false,
                  LM00B: false,
                  LM01: true,
                  LM02: true,
                  LM03: true,
                  LM04: true,
                  LM05: true,
                  LM06: true,
                  LM07: true,
                  LM08: true,
                },
                JumpStartPack: {
                  JS001: true,
                  JS002: true,
                  JS003: true,
                  JS004: true,
                  JS005: true,
                  JS006: true,
                  JS007: true,
                  JS008: true,
                  JS009: true,
                  JS010: true,
                  JS011: true,
                },
                LabWorksheets: {
                  LAB00: false,
                  LAB01: false,
                  LAB02: false,
                  LAB03: false,
                  LAB04: false,
                  LAB05: false,
                  LAB06: true,
                  LAB07: true,
                  LAB08: true,
                  LAB09: true,
                  LAB10: true,
                  LAB11: true,
                  LAB12: true,
                  LAB13: true,
                  LAB14: true,
                  LAB15: true,
                  LAB16: true,
                  LAB17: true,
                  LAB18: true,
                  LAB19: true,
                  LAB20: true,
                },
                TeamExercises: {
                  PPE03: false,
                  PPE04: false,
                  PPE13: true,
                  PPE16: true,
                  PPE28: true,
                  PPE45: true,
                  PPE46: true,
                  PPE47: true,
                  PPE471: true,
                  PPE48: true,
                  PPE49: true,
                  PPE50: true,
                  PPE51: true,
                  PPE52: true,
                },
                MiniCourses: {
                  MC001: true,
                  MC002: true,
                  MC003: true,
                  MC004: true,
                  MC005: true,
                },
                TeamBriefings: {
                  TB00: false,
                  TB01: false,
                  TB02: true,
                  TB03: true,
                  TB04: true,
                  TB05: true,
                  TB06: true,
                  TB07: true,
                  TB08: true,
                  TB09: true,
                  TB10: true,
                  TB11: true,
                  TB12: true,
                  TB13: true,
                  TB14: true,
                  TB15: true,
                  TB16: true,
                  TB17: true,
                  TB18: true,
                  TB19: true,
                  TB20: true,
                  TB21: true,
                  TB22: true,
                  TB23: true,
                  TB24: true,
                  TB25: true,
                  TB26: true,
                  TB27: true,
                  TB28: true,
                  TB29: true,
                  TB30: true,
                  TB31: true,
                  TB32: true,
                  TB33: true,
                  TB34: true,
                  TB35: true,
                  TB36: true,
                },
              });
            console.log("Calling Register");
            this.loginUser(email, password);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    console.log("Calling Login");
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            resolve(firebase.auth().currentUser);
            console.log("Calling Login Success");
          },
          (error) => {
            reject(this._handleError(error));
            console.log("Calling Login Error");
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    sessionStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!sessionStorage.getItem("authUser")) return null;
    return JSON.parse(sessionStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
export const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
export const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null);
