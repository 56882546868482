import React from "react";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import { PDFExport } from "@progress/kendo-react-pdf";

import logo from "../../assets/images/labicon.png";

class Results extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      answer: "",
      Question1: "",
      Question1Answer: "",
      Question1AnswerPrevious: "",
      Question2A: "",
      Question2AAnswer: "",
      Question2AAnswerPrevious: "",
      Question2B: "",
      Question2BAnswer: "",
      Question2BAnswerPrevious: "",
      Question2C: "",
      Question2CAnswer: "",
      Question2CAnswerPrevious: "",
    };

    this.HandleQuestion = this.HandleQuestion.bind(this);
    this.HandlePrevious = this.HandlePrevious.bind(this);
    this.EditQuestion = this.EditQuestion.bind(this);
  }

  componentDidMount() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;
    const db = firebase.firestore();
    // Getting Questions - Start

    var questionRef = db.collection("TCQuestions").doc(`Questions2`);
    var getDoc = questionRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          this.setState({
            Question1: doc.data().Question1,
            Question2A: doc.data().Question2A,
            Question2B: doc.data().Question2B,
            Question2C: doc.data().Question2C,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    // Getting Questions - End

    // Getting Answers - Start

    var answersRef = db.collection("Progress").doc(user);
    var getDoc = answersRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          this.setState({
            Question1Answer: doc.data().tc2.Question1,
            Question1AnswerPrevious: doc.data().tc1.Question1,
            Question2AAnswer: doc.data().tc2.Question2A,
            Question2AAnswerPrevious: doc.data().tc1.Question2A,
            Question2BAnswer: doc.data().tc2.Question2B,
            Question2BAnswerPrevious: doc.data().tc1.Question2B,
            Question2CAnswer: doc.data().tc2.Question2C,
            Question2CAnswerPrevious: doc.data().tc1.Question2C,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    // Getting Answers - End
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  HandleQuestion = (e) => {
    e.preventDefault();
    const db = firebase.firestore();

    var email = this.state.email;
    console.log(email);
    var answer = this.state.answer;
    var password = this.state.password;

    var userRef = db.collection("Users").doc(`${email}`);

    // Set the "capital" field of the city 'DC'
    return userRef
      .update({
        Question1: answer,
      })
      .then(() => {
        this.props.next();
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  HandlePrevious(e) {
    e.preventDefault();
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;
    var answer = this.state.answer;
    var password = this.state.password;

    var userRef = db.collection("Progress").doc(user);

    var userRef = db
      .collection("Progress")
      .doc(user)
      .update({
        "tc2.Question2C": " " + answer,
      })
      .then(() => {
        this.props.previous();
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  }

  EditQuestion() {
    this.props.previous();
  }

  exportPDF = () => {
    this.resume.save();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className="CardContainer">
                  <PDFExport
                    margin={{ top: 20, left: 20, right: 20, bottom: 20 }}
                    scale={0.6}
                    id="pdfexport"
                    paperSize={"Letter"}
                    fileName="YourTransformationConversation.pdf"
                    title="Transformation Conversation"
                    subject=""
                    keywords=""
                    ref={(r) => (this.resume = r)}
                  >
                    <div>
                      <div className="resultlogocont">
                        <Logo src={logo}></Logo>
                        <AppTitle>Transformation Conversation</AppTitle>
                      </div>

                      <QuestionContainer>
                        <Question1Container>
                          <QuestionNumber>Question1</QuestionNumber>
                          <QuestionTitle>{this.state.Question1}</QuestionTitle>

                          <Answer>
                            <p>Previous Answer</p>
                            <hr />
                            {this.state.Question1AnswerPrevious}
                          </Answer>

                          <Answer>
                            <p>Current Answer</p>
                            <hr />
                            {this.state.Question1Answer}
                          </Answer>
                        </Question1Container>
                        <Question2AContainer>
                          <QuestionNumber>Question2A</QuestionNumber>
                          <QuestionTitle>{this.state.Question2A}</QuestionTitle>
                          <Answer>
                            <p>Previous Answer</p>
                            <hr />
                            {this.state.Question2AAnswerPrevious}
                          </Answer>
                          <Answer>
                            <p>Current Answer</p>
                            <hr />
                            {this.state.Question2AAnswer}
                          </Answer>
                        </Question2AContainer>
                        <Question2BContainer>
                          <QuestionNumber>Question2B</QuestionNumber>
                          <QuestionTitle>{this.state.Question2B}</QuestionTitle>
                          <Answer>
                            <p>Previous Answer</p>
                            <hr />
                            {this.state.Question2BAnswerPrevious}
                          </Answer>
                          <Answer>
                            <p>Current Answer</p>
                            <hr />
                            {this.state.Question2BAnswer}
                          </Answer>
                        </Question2BContainer>
                        <Question2CContainer>
                          <QuestionNumber>Question2C</QuestionNumber>
                          <QuestionTitle>{this.state.Question2C}</QuestionTitle>
                          <Answer>
                            <p>Previous Answer</p>
                            <hr />
                            {this.state.Question2CAnswerPrevious}
                          </Answer>
                          <Answer>
                            <p>Current Answer</p>
                            <hr />
                            {this.state.Question2CAnswer}
                          </Answer>
                        </Question2CContainer>
                      </QuestionContainer>
                    </div>
                  </PDFExport>
                  <Button
                    onClick={this.EditQuestion}
                    color="primary"
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                  >
                    Edit Answers
                  </Button>
                  <Button
                    onClick={this.exportPDF}
                    color="primary"
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                  >
                    Download PDF
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Results;

const QuestionContainer = styled.div`
  padding: 5px;
`;

const Question1Container = styled.div`
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
`;

const QuestionNumber = styled.h1`
  font-size: 25px;
`;

const QuestionTitle = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-family: arial;
`;

const Answer = styled.p`
  font-size: 18px;
  font-weight: light;
`;

const Question2AContainer = styled.div`
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
`;

const Question2BContainer = styled.div`
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
`;

const Question2CContainer = styled.div`
  padding: 5px;
  border-radius: 5px;
  margin: 5px;
`;

const AppTitle = styled.h1`
  text-align: center;
`;

const Logo = styled.img`
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;
