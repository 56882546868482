import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/small/Leader Manuals Banner_Square.png";

import "./styles.css";

class LeaderManuals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      LM00A: true,
      LM00B: true,
      LM01: true,
      LM02: true,
      LM03: true,
      LM04: true,
      LM05: true,
      LM06: true,
      LM07: true,
      LM08: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  getManualStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().LeaderManuals);
          this.setState({
            LM00A: doc.data().LeaderManuals.LM00A,
            LM00B: doc.data().LeaderManuals.LM00B,
            LM01: doc.data().LeaderManuals.LM01,
            LM02: doc.data().LeaderManuals.LM02,
            LM03: doc.data().LeaderManuals.LM03,
            LM04: doc.data().LeaderManuals.LM04,
            LM05: doc.data().LeaderManuals.LM05,
            LM06: doc.data().LeaderManuals.LM06,
            LM07: doc.data().LeaderManuals.LM07,
            LM08: doc.data().LeaderManuals.LM08,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  addDoc = () => {
    const db = firebase.firestore();

    // Add a new document in collection "cities"
    db.collection("TeamBriefings")
      .doc("tb36")
      .set({
        buttonTitle: "Access",
        description: "Description",
        id: "36",
        locked: true,
        url: "url",
        title: "[KI] What gets APPRECIATED gets REPEATED",
      })
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db.collection("LeaderManuals");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;
    var LM00A = this.state.LM00A;
    var LM00B = this.state.LM00B;
    var LM01 = this.state.LM01;
    var LM02 = this.state.LM02;
    var LM03 = this.state.LM03;
    var LM04 = this.state.LM04;
    var LM05 = this.state.LM05;
    var LM06 = this.state.LM06;
    var LM07 = this.state.LM07;
    var LM08 = this.state.LM08;

    var render = <p>Locked</p>;

    const welcome =
      "<p>These are the sections for your Human Performance Master Class LEADERSHIP Manual. Each section becomes available for download as the corresponding Master Class Episodes are unlocked along your Success Path.</p><p>Please DOWNLOAD and PRINT OUT each section to use in your note-taking. Once you&rsquo;ve completed the Course, you&rsquo;ll have an awesome set of Next-Gen LEADERSHIP notes &amp; reference materials!</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Leader Manuals" />

            <CardBody className="pt-0">
              <Row>
                <StageContainer>
                  <WelcomeContainer>
                    <WelcomeLogo src={logo} />
                  </WelcomeContainer>
                  <InfoContainer>
                    <div>
                      <h1>Leader Manuals</h1>
                      {ReactHtmlParser(welcome)}
                    </div>
                  </InfoContainer>
                </StageContainer>
              </Row>
              <Row>
                {data.map((course, index) => {
                  var locked = false;
                  if (course.id === "00A") {
                    var locked = LM00A;
                  } else if (course.id === "00B") {
                    var locked = LM00B;
                  } else if (course.id === "01") {
                    var locked = LM01;
                  } else if (course.id === "02") {
                    var locked = LM02;
                  } else if (course.id === "03") {
                    var locked = LM03;
                  } else if (course.id === "04") {
                    var locked = LM04;
                  } else if (course.id === "05") {
                    var locked = LM05;
                  } else if (course.id === "06") {
                    var locked = LM06;
                  } else if (course.id === "07") {
                    var locked = LM07;
                  } else if (course.id === "08") {
                    var locked = LM08;
                  }

                  if (locked === true) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#2ed5be"
                            id="lock"
                            type="solid"
                            name="lock"
                          ></box-icon>
                        </LockContainer>
                      </EpisodeCard>
                    );
                  } else if (locked === false) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#2ed5be"
                            id="icon"
                            type="solid"
                            name="spreadsheet"
                          ></box-icon>
                        </LockContainer>

                        <ButtonContainer>
                          <Button
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            to="/dashboard"
                          >
                            <Link
                              to={{
                                pathname: "",
                              }}
                              style={{ color: "#FFF" }}
                            >
                              DOWNLOAD
                            </Link>
                          </Button>
                        </ButtonContainer>
                      </EpisodeCard>
                    );
                  }

                  return render;
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(LeaderManuals);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #17bebb;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;
