import "./styles.css";

import { Button, CardBody, Container } from "reactstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Results from "./results.js";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import styled from "styled-components";

class PPN extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labMember: false,
      results: false,
      resultsArray: [],
      open: false,
      value: "2",
      value2: "2",
      value3: "2",
      value4: "2",
      value5: "2",
      value6: "2",
      value7: "2",
      value8: "2",
      value9: "2",
      value10: "2",
      value11: "2",
      value12: "2",
      value13: "2",
      value14: "2",
      value15: "2",
      value16: "2",
      value17: "2",
      value18: "2",
      value19: "2",
      value20: "2",
      value21: "2",
      value22: "2",
      value23: "2",
      value24: "2",
      value25: "2",
      value26: "2",
      value27: "2",
      value28: "2",
      value29: "2",
      value30: "2",
      value31: "2",
      value32: "2",
      value33: "2",
      value34: "2",
      value35: "2",
      value36: "2",
      value37: "2",
      value38: "2",
      value39: "2",
      value40: "2",
      value41: "2",
      value42: "2",
      value43: "2",
      value44: "2",
      value45: "2",
      value46: "2",
      value47: "2",
      value48: "2",
      value49: "2",
      value50: "2",
      value51: "2",
      value52: "2",
      value53: "2",
      value54: "2",
      value55: "2",
      value56: "2",
      value57: "2",
      value58: "2",
      value59: "2",
      value60: "2",
      value61: "2",
      value62: "2",
      value63: "2",
      value64: "2",
      value65: "2",
      value66: "2",
      value67: "2",
      value68: "2",
      value69: "2",
      value70: "2",
      value71: "2",
      value72: "2",
      value73: "2",
      value74: "2",
      value75: "2",
      value76: "2",
      value77: "2",
      value78: "2",
      value79: "2",
      value80: "2",
      value81: "2",
      value82: "2",
      value83: "2",
      value84: "2",
      emailValue: "",
    };
  }

  componentDidMount() {
    // Check if User is Logged in
    const fireBaseBackend = getFirebaseBackend();

    if (fireBaseBackend.getAuthenticatedUser() == null) {
      console.log("null");

      // Handle Non Lab Member
    } else {
      console.log("Not Null");
      var user = fireBaseBackend.getAuthenticatedUser().email;

      // Handle Lab Member
      this.setState({ labMember: true });
      console.log(user);
    }

    console.log("d");
    console.log("HEELO");
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };
  handleChange2 = (event) => {
    this.setState({ value2: event.target.value });
  };
  handleChange3 = (event) => {
    this.setState({ value3: event.target.value });
  };
  handleChange4 = (event) => {
    this.setState({ value4: event.target.value });
  };
  handleChange5 = (event) => {
    this.setState({ value5: event.target.value });
  };
  handleChange6 = (event) => {
    this.setState({ value6: event.target.value });
  };
  handleChange7 = (event) => {
    this.setState({ value7: event.target.value });
  };
  handleChange8 = (event) => {
    this.setState({ value8: event.target.value });
  };
  handleChange9 = (event) => {
    this.setState({ value9: event.target.value });
  };
  handleChange10 = (event) => {
    this.setState({ value10: event.target.value });
  };
  handleChange11 = (event) => {
    this.setState({ value11: event.target.value });
  };
  handleChange12 = (event) => {
    this.setState({ value12: event.target.value });
  };
  handleChange13 = (event) => {
    this.setState({ value13: event.target.value });
  };
  handleChange14 = (event) => {
    this.setState({ value14: event.target.value });
  };
  handleChange15 = (event) => {
    this.setState({ value15: event.target.value });
  };
  handleChange16 = (event) => {
    this.setState({ value16: event.target.value });
  };
  handleChange17 = (event) => {
    this.setState({ value17: event.target.value });
  };
  handleChange18 = (event) => {
    this.setState({ value18: event.target.value });
  };
  handleChange19 = (event) => {
    this.setState({ value19: event.target.value });
  };
  handleChange20 = (event) => {
    this.setState({ value20: event.target.value });
  };
  handleChange21 = (event) => {
    this.setState({ value21: event.target.value });
  };
  handleChange22 = (event) => {
    this.setState({ value22: event.target.value });
  };
  handleChange23 = (event) => {
    this.setState({ value23: event.target.value });
  };
  handleChange24 = (event) => {
    this.setState({ value24: event.target.value });
  };
  handleChange25 = (event) => {
    this.setState({ value25: event.target.value });
  };
  handleChange26 = (event) => {
    this.setState({ value26: event.target.value });
  };
  handleChange27 = (event) => {
    this.setState({ value27: event.target.value });
  };
  handleChange28 = (event) => {
    this.setState({ value28: event.target.value });
  };
  handleChange29 = (event) => {
    this.setState({ value29: event.target.value });
  };
  handleChange30 = (event) => {
    this.setState({ value30: event.target.value });
  };
  handleChange31 = (event) => {
    this.setState({ value31: event.target.value });
  };
  handleChange32 = (event) => {
    this.setState({ value32: event.target.value });
  };
  handleChange33 = (event) => {
    this.setState({ value33: event.target.value });
  };
  handleChange34 = (event) => {
    this.setState({ value34: event.target.value });
  };
  handleChange35 = (event) => {
    this.setState({ value35: event.target.value });
  };
  handleChange36 = (event) => {
    this.setState({ value36: event.target.value });
  };
  handleChange37 = (event) => {
    this.setState({ value37: event.target.value });
  };
  handleChange38 = (event) => {
    this.setState({ value38: event.target.value });
  };
  handleChange39 = (event) => {
    this.setState({ value39: event.target.value });
  };
  handleChange40 = (event) => {
    this.setState({ value40: event.target.value });
  };
  handleChange41 = (event) => {
    this.setState({ value41: event.target.value });
  };
  handleChange42 = (event) => {
    this.setState({ value42: event.target.value });
  };
  handleChange43 = (event) => {
    this.setState({ value43: event.target.value });
  };
  handleChange44 = (event) => {
    this.setState({ value44: event.target.value });
  };
  handleChange45 = (event) => {
    this.setState({ value45: event.target.value });
  };
  handleChange46 = (event) => {
    this.setState({ value46: event.target.value });
  };
  handleChange47 = (event) => {
    this.setState({ value47: event.target.value });
  };
  handleChange48 = (event) => {
    this.setState({ value48: event.target.value });
  };
  handleChange49 = (event) => {
    this.setState({ value49: event.target.value });
  };
  handleChange50 = (event) => {
    this.setState({ value50: event.target.value });
  };
  handleChange51 = (event) => {
    this.setState({ value51: event.target.value });
  };
  handleChange52 = (event) => {
    this.setState({ value52: event.target.value });
  };
  handleChange53 = (event) => {
    this.setState({ value53: event.target.value });
  };
  handleChange54 = (event) => {
    this.setState({ value54: event.target.value });
  };
  handleChange55 = (event) => {
    this.setState({ value55: event.target.value });
  };
  handleChange56 = (event) => {
    this.setState({ value56: event.target.value });
  };
  handleChange57 = (event) => {
    this.setState({ value57: event.target.value });
  };
  handleChange58 = (event) => {
    this.setState({ value58: event.target.value });
  };
  handleChange59 = (event) => {
    this.setState({ value59: event.target.value });
  };
  handleChange60 = (event) => {
    this.setState({ value60: event.target.value });
  };
  handleChange61 = (event) => {
    this.setState({ value61: event.target.value });
  };
  handleChange62 = (event) => {
    this.setState({ value62: event.target.value });
  };
  handleChange63 = (event) => {
    this.setState({ value63: event.target.value });
  };
  handleChange64 = (event) => {
    this.setState({ value64: event.target.value });
  };
  handleChange65 = (event) => {
    this.setState({ value65: event.target.value });
  };
  handleChange66 = (event) => {
    this.setState({ value66: event.target.value });
  };
  handleChange67 = (event) => {
    this.setState({ value67: event.target.value });
  };
  handleChange68 = (event) => {
    this.setState({ value68: event.target.value });
  };
  handleChange69 = (event) => {
    this.setState({ value69: event.target.value });
  };
  handleChange70 = (event) => {
    this.setState({ value70: event.target.value });
  };
  handleChange71 = (event) => {
    this.setState({ value71: event.target.value });
  };
  handleChange72 = (event) => {
    this.setState({ value72: event.target.value });
  };
  handleChange73 = (event) => {
    this.setState({ value73: event.target.value });
  };
  handleChange74 = (event) => {
    this.setState({ value74: event.target.value });
  };
  handleChange75 = (event) => {
    this.setState({ value75: event.target.value });
  };
  handleChange76 = (event) => {
    this.setState({ value76: event.target.value });
  };
  handleChange77 = (event) => {
    this.setState({ value77: event.target.value });
  };
  handleChange78 = (event) => {
    this.setState({ value78: event.target.value });
  };
  handleChange79 = (event) => {
    this.setState({ value79: event.target.value });
  };
  handleChange80 = (event) => {
    this.setState({ value80: event.target.value });
  };
  handleChange81 = (event) => {
    this.setState({ value81: event.target.value });
  };
  handleChange82 = (event) => {
    this.setState({ value82: event.target.value });
  };
  handleChange83 = (event) => {
    this.setState({ value83: event.target.value });
  };
  handleChange84 = (event) => {
    this.setState({ value84: event.target.value });
  };

  handleSubmit = (event) => {
    var resultsArray = this.state.resultsArray;

    resultsArray.push(
      this.state.value,
      this.state.value2,
      this.state.value3,
      this.state.value4,
      this.state.value5,
      this.state.value6,
      this.state.value7,
      this.state.value8,
      this.state.value9,
      this.state.value10,
      this.state.value11,
      this.state.value12,
      this.state.value13,
      this.state.value14,
      this.state.value15,
      this.state.value16,
      this.state.value17,
      this.state.value18,
      this.state.value19,
      this.state.value20,
      this.state.value21,
      this.state.value22,
      this.state.value23,
      this.state.value24,
      this.state.value25,
      this.state.value26,
      this.state.value27,
      this.state.value28,
      this.state.value29,
      this.state.value30,
      this.state.value31,
      this.state.value32,
      this.state.value33,
      this.state.value34,
      this.state.value35,
      this.state.value36,
      this.state.value37,
      this.state.value38,
      this.state.value39,
      this.state.value40,
      this.state.value41,
      this.state.value42,
      this.state.value43,
      this.state.value44,
      this.state.value45,
      this.state.value46,
      this.state.value47,
      this.state.value48,
      this.state.value49,
      this.state.value50,
      this.state.value51,
      this.state.value52,
      this.state.value53,
      this.state.value54,
      this.state.value55,
      this.state.value56,
      this.state.value57,
      this.state.value58,
      this.state.value59,
      this.state.value60,
      this.state.value61,
      this.state.value62,
      this.state.value63,
      this.state.value64,
      this.state.value65,
      this.state.value66,
      this.state.value67,
      this.state.value68,
      this.state.value69,
      this.state.value70,
      this.state.value71,
      this.state.value72,
      this.state.value73,
      this.state.value74,
      this.state.value75,
      this.state.value76,
      this.state.value77,
      this.state.value78,
      this.state.value79,
      this.state.value80,
      this.state.value81,
      this.state.value82,
      this.state.value83,
      this.state.value84
    );

    this.setState({ results: true, resultsArray: resultsArray });

    event.preventDefault();
  };

  render() {
    const data = this.state.data;
    const results = this.state.results;
    const resultsArray = this.state.resultsArray;

    if (results === true) {
      return (
        <React.Fragment>
          <div className="page-content">
            <Container fluid>
              <CardBody className="pt-0">
                <Results data={resultsArray} />
              </CardBody>
            </Container>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="page-content" style={{ background: "gray" }}>
            <Container fluid>
              <CardBody className="pt-0">
                <MainContainer>
                  <MainTitle>Primary Performance Needs</MainTitle>
                  <form onSubmit={this.handleSubmit}>
                    {/* Question 1 */}
                    <QuestionTitle>
                      I feel that if there's no risk- there's no reward
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value === "2"}
                            onChange={this.handleChange}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value === "1"}
                            onChange={this.handleChange}
                          ></RadioInput>
                          <RadioText>
                            <RadioText>
                              <RadioText>SOMEWHAT ME</RadioText>
                            </RadioText>
                          </RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value === "0"}
                            onChange={this.handleChange}
                          ></RadioInput>
                          <RadioText>
                            <RadioText>NOT ME</RadioText>
                          </RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 2 */}
                    <QuestionTitle>
                      People tend to be impressed by me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value2 === "2"}
                            onChange={this.handleChange2}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value2 === "1"}
                            onChange={this.handleChange2}
                          ></RadioInput>
                          <RadioText>
                            <RadioText>SOMEWHAT ME</RadioText>
                          </RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value2 === "0"}
                            onChange={this.handleChange2}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 3 */}
                    <QuestionTitle>I need to feel grounded</QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value3 === "2"}
                            onChange={this.handleChange3}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value3 === "1"}
                            onChange={this.handleChange3}
                          ></RadioInput>
                          <RadioText>
                            <RadioText>SOMEWHAT ME</RadioText>
                          </RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value3 === "0"}
                            onChange={this.handleChange3}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 4 */}
                    <QuestionTitle>
                      Feeling I am 'part of the team' is important to me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value4 === "2"}
                            onChange={this.handleChange4}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value4 === "1"}
                            onChange={this.handleChange4}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value4 === "0"}
                            onChange={this.handleChange4}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 5 */}
                    <QuestionTitle>I am on a mission</QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value5 === "2"}
                            onChange={this.handleChange5}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value5 === "1"}
                            onChange={this.handleChange5}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value5 === "0"}
                            onChange={this.handleChange5}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 6 */}
                    <QuestionTitle>
                      I like my life to be stable and uniform
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value6 === "2"}
                            onChange={this.handleChange6}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value6 === "1"}
                            onChange={this.handleChange6}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value6 === "0"}
                            onChange={this.handleChange6}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 7 */}
                    <QuestionTitle>
                      I pay a lot of attention to what other people are saying
                      about me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value7 === "2"}
                            onChange={this.handleChange7}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value7 === "1"}
                            onChange={this.handleChange7}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value7 === "0"}
                            onChange={this.handleChange7}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 8 */}
                    <QuestionTitle>You only fail if you quit</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value8 === "2"}
                            onChange={this.handleChange8}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value8 === "1"}
                            onChange={this.handleChange8}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value8 === "0"}
                            onChange={this.handleChange8}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 9 */}
                    <QuestionTitle>I like change</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value9 === "2"}
                            onChange={this.handleChange9}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value9 === "1"}
                            onChange={this.handleChange9}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value9 === "0"}
                            onChange={this.handleChange9}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 10 */}
                    <QuestionTitle>
                      My sense of purpose is my contribution to others
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value10 === "2"}
                            onChange={this.handleChange10}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value10 === "1"}
                            onChange={this.handleChange10}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value10 === "0"}
                            onChange={this.handleChange10}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 11 */}
                    <QuestionTitle>
                      I enjoy taking care of others and am pretty good at it
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value11 === "2"}
                            onChange={this.handleChange11}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value11 === "1"}
                            onChange={this.handleChange11}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value11 === "0"}
                            onChange={this.handleChange11}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 12 */}
                    <QuestionTitle>I'm security-conscious</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value12 === "2"}
                            onChange={this.handleChange12}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value12 === "1"}
                            onChange={this.handleChange12}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value12 === "0"}
                            onChange={this.handleChange12}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 13 */}
                    <QuestionTitle>
                      I like learning new things and starting new projects
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value13 === "2"}
                            onChange={this.handleChange13}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value13 === "1"}
                            onChange={this.handleChange13}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value13 === "0"}
                            onChange={this.handleChange13}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 14 */}
                    <QuestionTitle>
                      Helping make our world a better place is how my life makes
                      sense
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value14 === "2"}
                            onChange={this.handleChange14}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value14 === "1"}
                            onChange={this.handleChange14}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value14 === "0"}
                            onChange={this.handleChange14}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 15 */}
                    <QuestionTitle>
                      I find it easy to connect with people
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value15 === "2"}
                            onChange={this.handleChange15}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value15 === "1"}
                            onChange={this.handleChange15}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value15 === "0"}
                            onChange={this.handleChange15}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 16 */}
                    <QuestionTitle>
                      I continually seek to improve myself
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value16 === "2"}
                            onChange={this.handleChange16}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value16 === "1"}
                            onChange={this.handleChange16}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value16 === "0"}
                            onChange={this.handleChange16}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 17 */}
                    <QuestionTitle>
                      I do not find excitement in danger
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value17 === "2"}
                            onChange={this.handleChange17}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value17 === "1"}
                            onChange={this.handleChange17}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value17 === "0"}
                            onChange={this.handleChange17}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 18 */}
                    <QuestionTitle>
                      Being fulfilled is more important than being admired
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value18 === "2"}
                            onChange={this.handleChange18}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value18 === "1"}
                            onChange={this.handleChange18}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value18 === "0"}
                            onChange={this.handleChange18}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 19 */}
                    <QuestionTitle>I'm competitive</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value19 === "2"}
                            onChange={this.handleChange19}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value19 === "1"}
                            onChange={this.handleChange19}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value19 === "0"}
                            onChange={this.handleChange19}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 20 */}
                    <QuestionTitle>
                      I often compare myself to others
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value20 === "2"}
                            onChange={this.handleChange20}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value20 === "1"}
                            onChange={this.handleChange20}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value20 === "0"}
                            onChange={this.handleChange20}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 21 */}
                    <QuestionTitle>
                      I am here to make a positive contribution
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value21 === "2"}
                            onChange={this.handleChange21}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value21 === "1"}
                            onChange={this.handleChange21}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value21 === "0"}
                            onChange={this.handleChange21}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 22 */}
                    <QuestionTitle>I get bored easily</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value22 === "2"}
                            onChange={this.handleChange22}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value22 === "1"}
                            onChange={this.handleChange22}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value22 === "0"}
                            onChange={this.handleChange22}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 23 */}
                    <QuestionTitle>
                      I like stretching my mental and physical limits
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value23 === "2"}
                            onChange={this.handleChange23}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value23 === "1"}
                            onChange={this.handleChange23}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value23 === "0"}
                            onChange={this.handleChange23}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 24 */}
                    <QuestionTitle>
                      There is always something new to learn
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value24 === "2"}
                            onChange={this.handleChange24}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value24 === "1"}
                            onChange={this.handleChange24}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value24 === "0"}
                            onChange={this.handleChange24}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 25 */}
                    <QuestionTitle>
                      I find myself more often being the 'giver' in my close
                      relationships
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value25 === "2"}
                            onChange={this.handleChange25}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value25 === "1"}
                            onChange={this.handleChange25}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value25 === "0"}
                            onChange={this.handleChange25}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 26 */}
                    <QuestionTitle>
                      When I believe in something, I give it lots of energy
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value26 === "2"}
                            onChange={this.handleChange26}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value26 === "1"}
                            onChange={this.handleChange26}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value26 === "0"}
                            onChange={this.handleChange26}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 27 */}
                    <QuestionTitle>I prefer predictability</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value27 === "2"}
                            onChange={this.handleChange27}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value27 === "1"}
                            onChange={this.handleChange27}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value27 === "0"}
                            onChange={this.handleChange27}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 28 */}
                    <QuestionTitle>
                      I like others to be aware of my contribtions and what I
                      have achieved
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value28 === "2"}
                            onChange={this.handleChange28}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value28 === "1"}
                            onChange={this.handleChange28}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value28 === "0"}
                            onChange={this.handleChange28}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 29 */}
                    <QuestionTitle>
                      I am very careful to not overspend
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value29 === "2"}
                            onChange={this.handleChange29}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value29 === "1"}
                            onChange={this.handleChange29}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value29 === "0"}
                            onChange={this.handleChange29}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 30 */}
                    <QuestionTitle>I enjoy learning new things</QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value30 === "2"}
                            onChange={this.handleChange30}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value30 === "1"}
                            onChange={this.handleChange30}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value30 === "0"}
                            onChange={this.handleChange30}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 31 */}
                    <QuestionTitle>
                      I tend to be more loving than most people
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value31 === "2"}
                            onChange={this.handleChange31}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value31 === "1"}
                            onChange={this.handleChange31}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value31 === "0"}
                            onChange={this.handleChange31}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 32 */}
                    <QuestionTitle>I take pride in who I am</QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value32 === "2"}
                            onChange={this.handleChange32}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value32 === "1"}
                            onChange={this.handleChange32}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value32 === "0"}
                            onChange={this.handleChange32}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 33 */}
                    <QuestionTitle>
                      My success is based upon making a positive difference
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value33 === "2"}
                            onChange={this.handleChange33}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value33 === "1"}
                            onChange={this.handleChange33}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value33 === "0"}
                            onChange={this.handleChange33}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 34 */}
                    <QuestionTitle>
                      I sometimes put too much energy into trying to help others
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value34 === "2"}
                            onChange={this.handleChange34}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value34 === "1"}
                            onChange={this.handleChange34}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value34 === "0"}
                            onChange={this.handleChange34}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 35 */}
                    <QuestionTitle>
                      When ordering from a menu, I look for something I've never
                      had before
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value35 === "2"}
                            onChange={this.handleChange35}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value35 === "1"}
                            onChange={this.handleChange35}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value35 === "0"}
                            onChange={this.handleChange35}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 36 */}
                    <QuestionTitle>
                      It's important that I feel needed
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value36 === "2"}
                            onChange={this.handleChange36}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value36 === "1"}
                            onChange={this.handleChange36}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value36 === "0"}
                            onChange={this.handleChange36}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 37 */}
                    <QuestionTitle>
                      I like how learning something new can expand my context
                      and give me new perspective
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value37 === "2"}
                            onChange={this.handleChange37}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value37 === "1"}
                            onChange={this.handleChange37}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value37 === "0"}
                            onChange={this.handleChange37}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 38 */}
                    <QuestionTitle>
                      Habits and routines are important to me
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value38 === "2"}
                            onChange={this.handleChange38}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value38 === "1"}
                            onChange={this.handleChange38}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value38 === "0"}
                            onChange={this.handleChange38}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 39 */}
                    <QuestionTitle>
                      I do not seek nor enjoy adventure
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value39 === "2"}
                            onChange={this.handleChange39}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value39 === "1"}
                            onChange={this.handleChange39}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value39 === "0"}
                            onChange={this.handleChange39}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 40 */}
                    <QuestionTitle>
                      Once I learn something I like to teach it to others
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value40 === "2"}
                            onChange={this.handleChange40}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value40 === "1"}
                            onChange={this.handleChange40}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value40 === "0"}
                            onChange={this.handleChange40}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 41 */}
                    <QuestionTitle>
                      I will spend on impulse, especially for a new experience
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value41 === "2"}
                            onChange={this.handleChange41}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value41 === "1"}
                            onChange={this.handleChange41}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value41 === "0"}
                            onChange={this.handleChange41}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 42 */}
                    <QuestionTitle>
                      Each 'less than desired' outcome gives me an opportunity
                      to learn
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value42 === "2"}
                            onChange={this.handleChange42}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value42 === "1"}
                            onChange={this.handleChange42}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value42 === "0"}
                            onChange={this.handleChange42}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 43 */}
                    <QuestionTitle>
                      I could not imagine anyone thinking I'm selfish or
                      non-caring
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value43 === "2"}
                            onChange={this.handleChange43}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value43 === "1"}
                            onChange={this.handleChange43}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value43 === "0"}
                            onChange={this.handleChange43}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 44 */}
                    <QuestionTitle>
                      If I cannot predict the consequences, I stay away
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value44 === "2"}
                            onChange={this.handleChange44}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value44 === "1"}
                            onChange={this.handleChange44}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value44 === "0"}
                            onChange={this.handleChange44}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 45 */}
                    <QuestionTitle>
                      The most important work is often beyond what you get paid
                      for
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value45 === "2"}
                            onChange={this.handleChange45}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value45 === "1"}
                            onChange={this.handleChange45}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value45 === "0"}
                            onChange={this.handleChange45}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 46 */}
                    <QuestionTitle>
                      I seek closeness and connection in my relationships
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value46 === "2"}
                            onChange={this.handleChange46}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value46 === "1"}
                            onChange={this.handleChange46}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value46 === "0"}
                            onChange={this.handleChange46}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 47 */}
                    <QuestionTitle>
                      Prestige and perks are important to me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value47 === "2"}
                            onChange={this.handleChange47}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value47 === "1"}
                            onChange={this.handleChange47}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value47 === "0"}
                            onChange={this.handleChange47}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 48 */}
                    <QuestionTitle>
                      I tend to be loving and romantic
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value48 === "2"}
                            onChange={this.handleChange48}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value48 === "1"}
                            onChange={this.handleChange48}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value48 === "0"}
                            onChange={this.handleChange48}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 49 */}
                    <QuestionTitle>
                      I seek opportunities where I can learn & grow
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value49 === "2"}
                            onChange={this.handleChange49}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value49 === "1"}
                            onChange={this.handleChange49}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value49 === "0"}
                            onChange={this.handleChange49}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 50 */}
                    <QuestionTitle>
                      I enjoy uncertainty and anticipation
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value50 === "2"}
                            onChange={this.handleChange50}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value50 === "1"}
                            onChange={this.handleChange50}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value50 === "0"}
                            onChange={this.handleChange50}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 51 */}
                    <QuestionTitle>
                      I tend to find myself in leadership roles
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value51 === "2"}
                            onChange={this.handleChange51}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value51 === "1"}
                            onChange={this.handleChange51}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value51 === "0"}
                            onChange={this.handleChange51}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 52 */}
                    <QuestionTitle>
                      I like to be first in the queue / at the top of my group
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value52 === "2"}
                            onChange={this.handleChange52}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value52 === "1"}
                            onChange={this.handleChange52}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value52 === "0"}
                            onChange={this.handleChange52}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 53 */}
                    <QuestionTitle>
                      I go out of my way to make others feel better
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value53 === "2"}
                            onChange={this.handleChange53}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value53 === "1"}
                            onChange={this.handleChange53}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value53 === "0"}
                            onChange={this.handleChange53}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 54 */}
                    <QuestionTitle>
                      I am learning something new most of the time
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value54 === "2"}
                            onChange={this.handleChange54}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value54 === "1"}
                            onChange={this.handleChange54}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value54 === "0"}
                            onChange={this.handleChange54}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 55 */}
                    <QuestionTitle>
                      I avoid risks whenever possible
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value55 === "2"}
                            onChange={this.handleChange55}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value55 === "1"}
                            onChange={this.handleChange55}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value55 === "0"}
                            onChange={this.handleChange55}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 56 */}
                    <QuestionTitle>
                      I'm uncomfortable when conditions involve risk
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value56 === "2"}
                            onChange={this.handleChange56}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value56 === "1"}
                            onChange={this.handleChange56}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value56 === "0"}
                            onChange={this.handleChange56}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 57 */}
                    <QuestionTitle>
                      I am continually developing my knowledge, skills, and
                      abilities
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value57 === "2"}
                            onChange={this.handleChange57}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value57 === "1"}
                            onChange={this.handleChange57}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value57 === "0"}
                            onChange={this.handleChange57}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 58 */}
                    <QuestionTitle>
                      I am continually developing my knowledge, skills, and
                      abilities
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value58 === "2"}
                            onChange={this.handleChange58}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value58 === "1"}
                            onChange={this.handleChange58}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value58 === "0"}
                            onChange={this.handleChange58}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 59 */}
                    <QuestionTitle>
                      Personal relationships are very important to me
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value59 === "2"}
                            onChange={this.handleChange59}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value59 === "1"}
                            onChange={this.handleChange59}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value59 === "0"}
                            onChange={this.handleChange59}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 60 */}
                    <QuestionTitle>
                      Having a positive influence on others is fulfilling to me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value60 === "2"}
                            onChange={this.handleChange60}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value60 === "1"}
                            onChange={this.handleChange60}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value60 === "0"}
                            onChange={this.handleChange60}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 61 */}
                    <QuestionTitle>
                      Other people are sometimes intimidated by me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value61 === "2"}
                            onChange={this.handleChange61}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value61 === "1"}
                            onChange={this.handleChange61}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value61 === "0"}
                            onChange={this.handleChange61}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 62 */}
                    <QuestionTitle>
                      I am generally a quick learner
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value62 === "2"}
                            onChange={this.handleChange62}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value62 === "1"}
                            onChange={this.handleChange62}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value62 === "0"}
                            onChange={this.handleChange62}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 63 */}
                    <QuestionTitle>
                      Whenever possible, I consider things carefully before
                      taking action
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value63 === "2"}
                            onChange={this.handleChange63}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value63 === "1"}
                            onChange={this.handleChange63}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value63 === "0"}
                            onChange={this.handleChange63}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 64 */}
                    <QuestionTitle>
                      The best way to have positive impact is to be a positive
                      role model
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value64 === "2"}
                            onChange={this.handleChange64}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value64 === "1"}
                            onChange={this.handleChange64}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value64 === "0"}
                            onChange={this.handleChange64}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 65 */}
                    <QuestionTitle>
                      I like to experience new and different platforms on the
                      internet
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value65 === "2"}
                            onChange={this.handleChange65}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value65 === "1"}
                            onChange={this.handleChange65}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value65 === "0"}
                            onChange={this.handleChange65}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 66 */}
                    <QuestionTitle>
                      I enjoy experiencing the thrill of the unknown
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value66 === "2"}
                            onChange={this.handleChange66}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value66 === "1"}
                            onChange={this.handleChange66}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value66 === "0"}
                            onChange={this.handleChange66}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 67 */}
                    <QuestionTitle>
                      Contribution to team and 'community' is important
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value67 === "2"}
                            onChange={this.handleChange67}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value67 === "1"}
                            onChange={this.handleChange67}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value67 === "0"}
                            onChange={this.handleChange67}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 68 */}
                    <QuestionTitle>
                      It is essential that I am respected by others
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value68 === "2"}
                            onChange={this.handleChange68}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value68 === "1"}
                            onChange={this.handleChange68}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value68 === "0"}
                            onChange={this.handleChange68}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 69 */}
                    <QuestionTitle>
                      I am interested in many things and like to learn about
                      them
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value69 === "2"}
                            onChange={this.handleChange69}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value69 === "1"}
                            onChange={this.handleChange69}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value69 === "0"}
                            onChange={this.handleChange69}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 70 */}
                    <QuestionTitle>
                      I offer my time, energy, and/or money to gain closeness
                      with others
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value70 === "2"}
                            onChange={this.handleChange70}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value70 === "1"}
                            onChange={this.handleChange70}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value70 === "0"}
                            onChange={this.handleChange70}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 71 */}
                    <QuestionTitle>
                      Feeling steady and stable is important to me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value71 === "2"}
                            onChange={this.handleChange71}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value71 === "1"}
                            onChange={this.handleChange71}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value71 === "0"}
                            onChange={this.handleChange71}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 72 */}
                    <QuestionTitle>
                      I like to improve in whatever it is I am doing
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value72 === "2"}
                            onChange={this.handleChange72}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value72 === "1"}
                            onChange={this.handleChange72}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value72 === "0"}
                            onChange={this.handleChange72}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 73 */}
                    <QuestionTitle>
                      It's important that I am seen as more successful than my
                      peers
                    </QuestionTitle>
                    <RadioContainer>
                      
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value73 === "2"}
                            onChange={this.handleChange73}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value73 === "1"}
                            onChange={this.handleChange73}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value73 === "0"}
                            onChange={this.handleChange73}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 74 */}
                    <QuestionTitle>
                      A sense of togetherness is important to me
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value74 === "2"}
                            onChange={this.handleChange74}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value74 === "1"}
                            onChange={this.handleChange74}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value74 === "0"}
                            onChange={this.handleChange74}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 75 */}
                    <QuestionTitle>I love to 'pay it forward'</QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value75 === "2"}
                            onChange={this.handleChange75}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value75 === "1"}
                            onChange={this.handleChange75}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value75 === "0"}
                            onChange={this.handleChange75}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 76 */}
                    <QuestionTitle>
                      I enjoy variation in options and activities
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value76 === "2"}
                            onChange={this.handleChange76}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value76 === "1"}
                            onChange={this.handleChange76}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value76 === "0"}
                            onChange={this.handleChange76}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 77 */}
                    <QuestionTitle>
                      You're either learning and growing or you're dying- there
                      is no 'standing still'
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value77 === "2"}
                            onChange={this.handleChange77}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value77 === "1"}
                            onChange={this.handleChange77}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value77 === "0"}
                            onChange={this.handleChange77}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 78 */}
                    <QuestionTitle>
                      It's important that I'm never seen as a loser
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value78 === "2"}
                            onChange={this.handleChange78}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value78 === "1"}
                            onChange={this.handleChange78}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value78 === "0"}
                            onChange={this.handleChange78}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 79 */}
                    <QuestionTitle>
                      When making decisions, I tend toward whatever I think will
                      be most stimulating
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value79 === "2"}
                            onChange={this.handleChange79}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value79 === "1"}
                            onChange={this.handleChange79}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value79 === "0"}
                            onChange={this.handleChange79}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 80 */}
                    <QuestionTitle>
                      I tend to make quick decisions so I don't "miss out"
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value80 === "2"}
                            onChange={this.handleChange80}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value80 === "1"}
                            onChange={this.handleChange80}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value80 === "0"}
                            onChange={this.handleChange80}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 81 */}
                    <QuestionTitle>
                      Feeling stagnant or stifled drives me crazy
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value81 === "2"}
                            onChange={this.handleChange81}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value81 === "1"}
                            onChange={this.handleChange81}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value81 === "0"}
                            onChange={this.handleChange81}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 82 */}
                    <QuestionTitle>
                      I seek to feel as safe as possible whenever possible
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value82 === "2"}
                            onChange={this.handleChange82}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value82 === "1"}
                            onChange={this.handleChange82}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value82 === "0"}
                            onChange={this.handleChange82}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 83 */}
                    <QuestionTitle>
                      Passion is very important in my primary relationships
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value83 === "2"}
                            onChange={this.handleChange83}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value83 === "1"}
                            onChange={this.handleChange83}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value83 === "0"}
                            onChange={this.handleChange83}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>

                    {/* Question 84 */}
                    <QuestionTitle>
                      Uniformity and constancy are boring
                    </QuestionTitle>
                    <RadioContainer>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="2"
                            checked={this.state.value84 === "2"}
                            onChange={this.handleChange84}
                          ></RadioInput>
                          <RadioText>ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="1"
                            checked={this.state.value84 === "1"}
                            onChange={this.handleChange84}
                          ></RadioInput>
                          <RadioText>SOMEWHAT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                      <Radio>
                        <RadioLabel>
                          <RadioInput
                            type="radio"
                            value="0"
                            checked={this.state.value84 === "0"}
                            onChange={this.handleChange84}
                          ></RadioInput>
                          <RadioText>NOT ME</RadioText>
                        </RadioLabel>
                      </Radio>
                    </RadioContainer>
                    {/*Non Lab Member Email Field*/}
                    <SubmitContainer>
                      <RadioSubmit type="submit" value="Submit">
                        CONTINUE
                      </RadioSubmit>
                    </SubmitContainer>
                  </form>
                </MainContainer>
              </CardBody>
            </Container>
          </div>
        </React.Fragment>
      );
    }
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(PPN);

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
  width: 300px;
`;

const RadioContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;
`;

const Radio = styled.div`
  border: 1px solid #838e95;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px;
  animation: cubic-bezier(2, 2, 2, 0.5);
  &:hover {
  }
`;

const RadioLabel = styled.label`
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const RadioInput = styled.input`
  flex: 1;
`;

const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px;
`;

const RadioSubmit = styled.button`
  width: 80%;
  height: 50px;
  border-radius: 5px;
`;

const RadioText = styled.h1`
  flex: 2;
  font-size: 17px;
  margin: 0;
`;

const MainTitle = styled.h1`
  margin: 20px;
`;

const QuestionTitle = styled.h1`
  font-size: 20px;
  text-align: center;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
`;
