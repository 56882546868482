import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { connect } from "react-redux";
import { device } from "../device.js";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../../helpers/authUtils";
import img1 from "../../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../../helpers/authUtils";
import styled from "styled-components";

//Import Breadcrumb

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodeResource: [],
      episodeAudio: [],
    };
  }

  componentDidMount() {
    this.getModuleAudio();
    this.getModules();
    this.getLabWorksheets();
    //this.getJSP();
    this.getGuides();
    this.getLeader();
    this.getMiscResources();
    this.getRecall();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getRecall = () => {
    var currentData = this.state.episodeResource;

    console.log("GET RECALL");
    console.log(currentData);

    var recall2 = {
      title: "Recall Questions - Module 2",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall2",
    };

    var recall3 = {
      title: "Recall Questions - Module 3",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall3",
    };

    var recall4 = {
      title: "Recall Questions - Module 4",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall4",
    };

    currentData.push(recall2, recall3, recall4);

    this.setState(
      {
        episodeResource: currentData,
      },
      () => {
        // Get Leader WorkSheets
      }
    );
  };

  getModuleAudio = () => {
    const db = firebase.firestore();
    let moduleRef = db.collection("ModuleAudio");

    let allModules = moduleRef
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          console.log(doc.data());

          var currentEpisodeAudio = this.state.episodeAudio;

          if (
            doc.data().id === "002" ||
            doc.data().id === "003" ||
            doc.data().id === "004"
          ) {
            currentEpisodeAudio.push(doc.data());

            this.setState({ episodeAudio: currentEpisodeAudio });
          }
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  getModules() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/Coach/Resources/Resources");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getLabWorksheets = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Lab WorkSheets
    db.collection("/Courses/001/00-Intro/Resources/Resources")
      .get()
      .then((sheets) => {
        sheets.forEach((sheet) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(sheet.id, " => ", sheet.data());

          var currentEpisodeResource = this.state.episodeResource;

          if (
            sheet.id === "lab06" ||
            sheet.id === "lab07" ||
            sheet.id === "lab10"
          ) {
            console.log(sheet.data());

            currentEpisodeResource.push(sheet.data());

            this.setState(
              { episodeResource: currentEpisodeResource },
              () => {}
            );
          }
        });
      });
  };

  getJSP = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get JSP

    db.collection("/Jumpstart")
      .get()
      .then((jumps) => {
        jumps.forEach((jump) => {
          console.log(jump.id, "=>", jump.data());

          var currentEpisodeResourceJump = this.state.episodeResource;

          if (jump.id === "001") {
            currentEpisodeResourceJump.push(jump.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceJump,
              },
              () => {}
            );
          }
        });
      });
  };

  getGuides = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Exercise Guides

    db.collection("/Powerpoints")
      .get()
      .then((guides) => {
        guides.forEach((guide) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (
            guide.id === "ppe28" ||
            guide.id === "ppe47" ||
            guide.id === "ppe48" ||
            guide.id === "ppe49"
          ) {
            currentEpisodeResourceGuide.push(guide.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getLeader = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/LeaderManuals")
      .get()
      .then((manuals) => {
        manuals.forEach((manual) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (manual.id === "02" || manual.id === "03" || manual.id === "04") {
            currentEpisodeResourceGuide.push(manual.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getMiscResources = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/MiscResources")
      .get()
      .then((miscs) => {
        miscs.forEach((misc) => {
          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (misc.id === "04" || misc.id === "05") {
            currentEpisodeResourceGuide.push(misc.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  render() {
    const { open } = this.state;
    const data = this.state.data;
    const resources = this.state.episodeResource;
    const audios = this.state.episodeAudio;

    return (
      <CardContainer>
        <MainTitle>Resources</MainTitle>
        <hr></hr>
        <div>
          {audios.map((audio, index) => {
            console.log("Audio HEre");
            console.log(audio);
            return (
              <Col key={index} mg={12} xl={12}>
                <MiniCardContainer>
                  <Title className="mt-0">{audio.title}</Title>
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        href={audio.url}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        {audio.buttonTitle}
                      </a>
                    </Button>
                  </ButtonContainer>
                </MiniCardContainer>
              </Col>
            );
          })}
        </div>
        {resources.map((resource, index) => {
          return (
            <Col key={index} mg={12} xl={12}>
              <MiniCardContainer>
                <Title className="mt-0">{resource.title}</Title>

                {resource.external ? (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        href={resource.url}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        {resource.buttonTitle}
                      </a>
                    </Button>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <Link to={resource.url} style={{ color: "white" }}>
                        {resource.buttonTitle}
                      </Link>
                    </Button>
                  </ButtonContainer>
                )}
              </MiniCardContainer>
            </Col>
          );
        })}
      </CardContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Resources);

const CardContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    padding: 5px;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
    margin-top: 10px;
  }

  @media ${device.tablet} {
    padding: 20px;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const ButtonLink = styled.a`
  display: block;
  color: white;
  width: 100%;
`;

const MiniCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: 20px;

  @media ${device.mobileS} {
    padding: 10px;
  }
`;

const MainTitle = styled.h1`
  @media ${device.mobileS} {
    font-size: 15px;
  }

  @media ${device.mobileM} {
    font-size: 35px;
  }
`;

const Title = styled.h1`
  flex: 1;
  @media ${device.mobileS} {
    font-size: 10px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const Text = styled.p`
  flex: 1;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;
