import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import firebase from "firebase/app";

import { connect } from "react-redux";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

// Pages Components

// New Imports
import CoreContentMini from "../CoreContentMini";
import JumpstartMini from "../JumpstartMini/index";
import LeaderWorksheetMini from "../LeaderWorksheetsMini/index.js";
import TeamBriefingsMini from "../TeamBriefingsMini/index.js";
import TeamExercisesMini from "../TeamExercisesMini/index.js";
import MiniCoursesMini from "../MiniCoursesMini/index.js";
import VideoClipLibraryMini from "../VideoClipLibraryMini/index.js";
import AdditionalResourcesMini from "../AdditionalResourcesMini/index.js";
import UpcomingEventsMini from "../UpcomingEventsMini/index.js";

import styled from "styled-components";

import logo from "../../assets/images/labicon.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [
        { title: "Orders", iconClass: "bx-copy-alt", description: "1,235" },
        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: "$35, 723",
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: "$16.2",
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
    };
    this.togglemodal.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  componentDidMount() {}

  render() {
    const welcome =
      "<p>Your PPI Lab membership is here to serve you with step-by-step learning and guidance, as well as an arsenal of Jump Start Packs, Mini-Courses, Team Briefings, Team Exercises, Apps, and Resources.</p><p>We&rsquo;re honored that you&rsquo;ve chosen to join us, and look forward to helping you Start Fast and Finish Strong in your efforts to achieve synergized sustainable next-level performance for you and your team.</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="PPILAB" breadcrumbItem="Dashboard" />

            <Row>
              <StageContainer>
                <WelcomeContainer>
                  <WelcomeLogo src={logo} />
                </WelcomeContainer>
                <InfoContainer>
                  <div>
                    <h1>Welcome</h1>
                    {ReactHtmlParser(welcome)}
                  </div>
                </InfoContainer>

                <StartContainer>
                  <Link to="/starthere">
                    <StartButton>Start Here</StartButton>
                  </Link>
                </StartContainer>
              </StageContainer>
            </Row>

            <Row>
              <Col sm="12">
                <CoreContentMini />
              </Col>
              <Col sm="12">
                <JumpstartMini />
              </Col>
              <Col sm="12">
                <LeaderWorksheetMini />
              </Col>
              <Col sm="12">
                <TeamBriefingsMini />
              </Col>
              <Col sm="12">
                <TeamExercisesMini />
              </Col>
              <Col sm="12">
                <MiniCoursesMini />
              </Col>
              <Col sm="12">
                <VideoClipLibraryMini />
              </Col>

              <Col sm="12">
                <AdditionalResourcesMini />
              </Col>
              <Col sm="12">
                <UpcomingEventsMini />
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Wireless Headphone (Black)
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

export default connect(mapStateToProps, mapDispatchToProps, null)(Dashboard);
