import { Alert, Card, CardBody, Col, Row } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";
import React, { Component } from "react";
// action
import {
  apiError,
  loginUser,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import logoImg from "../../assets/images/labicon.png";
// import images
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

class HpimsRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleValidSubmitLogin = this.handleValidSubmitLogin.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    console.log(values);
    this.props.registerUser(values, this.props.history);
    this.handleValidSubmitLogin(event, values);
  }

  // handleValidSubmitLogin
  handleValidSubmitLogin(event, values) {
    this.props.loginUser(values, this.props.history);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome!</h5>
                          <p>Sign Up For Your PPILAB Account</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <Logo src={logoImg} alt="" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.user && this.props.user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}
                        {this.props.registrationError &&
                        this.props.registrationError ? (
                          <Alert color="danger">
                            {this.props.registrationError}
                          </Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            name="firstname"
                            label="First Name"
                            type="text"
                            required
                            placeholder="Enter First Name"
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="lastname"
                            label="Last Name"
                            type="text"
                            required
                            placeholder="Enter Last Name"
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>
                        <div className="form-group">
                          <AvField
                            name="batch"
                            label=""
                            type="text"
                            placeholder=""
                            value="cohorts2101"
                            style={{ display: "none" }}
                          />
                        </div>

                        <div className="mt-4">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            By registering you agree to the Practicing
                            Perfection Institute Inc.{" "}
                            <a
                              href="https://ppiweb.com/membership-home/terms-of-use-2/"
                              className="text-primary"
                              target="_blank"
                            >
                              Terms of Use
                            </a>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    © {new Date().getFullYear()} Practicing Perfection Institute
                    Inc.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  const { error } = state.Login;
  return { user, registrationError, loading, error };
};

export default withRouter(
  connect(mapStatetoProps, {
    loginUser,
    registerUser,
    apiError,
    registerUserFailed,
  })(HpimsRegister)
);

const Logo = styled.img`
  width: 50px;
`;
/* 
 <p>
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
*/
