import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";

import firebase from "firebase/app";

import Episodes from "./episodes";
import Overview from "./overview";
import Resources from "./resources";

import styled from "styled-components";
//Import Breadcrumb

class Coach extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, data: [], locked: false };
  }

  componentWillMount() {
    this.checkLocked();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  checkLocked = () => {
    console.log("Checking Locked");

    const db = firebase.firestore();

    db.collection("/Courses/001/Timeline")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.data().locked);

          if (doc.data().tab === "4" && doc.data().locked === true) {
            this.setState({ locked: doc.data().locked });
          } else {
          }
        });
      });
  };

  render() {
    const locked = this.state.locked;

    return locked ? (
      <React.Fragment>
        <Container fluid>
          <Locked>
            <box-icon size="lg" type="solid" name="lock"></box-icon>
          </Locked>
        </Container>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Container fluid>
          <SectionOne>
            <Row>
              <Col lg={12}>
                <Episodes></Episodes>
              </Col>
            </Row>
          </SectionOne>
          <SectionTwo>
            <Row>
              <Col sm={12} mg={12} lg={6}>
                <Overview></Overview>
              </Col>
              <Col sm={12} mg={12} lg={6}>
                <Resources></Resources>
              </Col>
            </Row>
          </SectionTwo>
        </Container>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Coach);

const SectionOne = styled.div`
  padding: 20px;
`;
const SectionTwo = styled.div`
  padding: 20px;
  margin-top: 20px;
`;

const Locked = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
