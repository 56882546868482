import React, { Component } from 'react'

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal
} from 'reactstrap'

import { Link } from 'react-router-dom'

import avatar1 from '../../assets/images/users/avatar-1.jpg'
import img1 from '../../assets/images/small/img-1.jpg'
import profileImg from '../../assets/images/profile-img.png'

import { connect } from 'react-redux'

import firebase from 'firebase/app'

import styled from 'styled-components'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

class FoundationalTopics extends Component {
  constructor (props) {
    super(props)
    this.state = { open: false, data: [] }
  }

  componentDidMount () {
    this.getData()
  }

  removeBodyCss () {
    document.body.classList.add('no_padding')
  }

  onOpenModal = id => {
    this.setState({
      open: {
        [id]: true
      }
    })
  }

  onCloseModal = id => {
    this.setState({
      open: {
        [id]: false
      }
    })
  }

  getData () {
    const db = firebase.firestore()
    let citiesRef = db.collection('FoundationalTopics')
    let allCities = citiesRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          console.log(doc.data())

          var currentData = this.state.data

          currentData.push(doc.data())

          this.setState({ data: currentData })
        })
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
  }

  render () {
    const { open } = this.state
    const data = this.state.data

    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            <Breadcrumbs title='PPILAB' breadcrumbItem='Foundational Topics' />
            <CardBody className='pt-0'>
              <Row>
                {data.map((course, index) => {
                  return (
                    <Col key={index} mg={6} xl={4}>
                      <Card style={{ overflow: 'hidden', borderRadius: 20 }}>
                        <CardImg
                          top
                          className='img-fluid'
                          src={img1}
                          alt='Skote'
                        />
                        <CardBody>
                          <CardTitle className='mt-0'>{course.title}</CardTitle>
                          <CardText>{course.description}</CardText>
                          <button
                            type='button'
                            onClick={this.onOpenModal.bind(this, index)}
                            className='btn btn-primary waves-effect waves-light'
                            data-toggle='modal'
                            data-target='#myModal'
                          >
                            Watch
                          </button>
                          <Modal
                            className='Modal'
                            isOpen={open[index]}
                            onRequestClose={this.onCloseModal}
                            contentLabel='Example Modal'
                          >
                            <h1>{course.title}</h1>
                            <button
                              type='button'
                              onClick={() => this.onCloseModal()}
                              className='close'
                              data-dismiss='modal'
                              aria-label='Close'
                            >
                              <span aria-hidden='true'>&times;</span>
                            </button>
                          </Modal>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps (state) {
  return { ...state.User }
}

export default connect(mapStateToProps)(FoundationalTopics)

const CardInfo = styled.div`
  display: flex;
`
const CardTitle = styled.div`
  flex: 4;
`
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonTitle = styled.div`
  display: flex;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`
