import "./cert.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Quiz from "react-quiz-component";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils.js";
import hpa from "../../assets/images/hpa.png";
import hub from "../../assets/images/masterclass.png";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import moment from "moment";
import signature from "../../assets/images/signature.png";
import styled from "styled-components";

export default class CertificatePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      certificateDate: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  exportPDF = () => {
    this.resume.save();
  };

  getData = () => {
    console.log("Running Cert");
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    db.collection("Progress")
      .where("email", "==", user)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          // Date to Timestamp
          const t = doc.data().certificateDate;

          // Timestamp to Date
          const d = t.toDate();

          console.log(d);

          console.log();

          const date = moment(d).format("MMMM Do YYYY");

          this.setState({
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            certificateDate: date,
          });
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div id="pdfcont">
                  <PDFExport
                    id="pdfexport"
                    paperSize={"auto"}
                    fileName="HUBCredential.pdf"
                    title=""
                    subject=""
                    keywords=""
                    ref={(r) => (this.resume = r)}
                  >
                    <div id="incont">
                      <div id="incont2">
                        <p id="title">Practicing Perfection Institute</p>
                        <img id="courseimg" src={hub} />
                        <p id="name">
                          {this.state.firstName + " " + this.state.lastName}
                        </p>
                        <p id="p1">
                          Has completed the Human Performance MasterClass
                        </p>
                        <p id="p2">
                          and now having knowledge of Next-Generation Human
                          Performance,
                        </p>
                        <p id="p3"> is hereby designated as a </p>
                        <p id="coursetitle">
                          Next-Gen Human Performance Leader
                        </p>
                        <div id="bottomrow">
                          <img id="bottomleftimg" src={hpa} />
                          <p id="date">
                            <strong>Issued on: </strong>
                            {this.state.certificateDate.toString()}
                          </p>
                          <div id="sigcont">
                            <img id="bottomrightimg" src={signature} />
                            <p>Tim Autrey - Founder/CEO</p>
                            <p>Practicing Perfection Institute, Inc</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PDFExport>
                </div>
                <div id="buttoncont">
                  <button id="download" onClick={this.exportPDF}>
                    Download
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
