import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { connect } from "react-redux";

import ReactPlayer from "react-player";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "./styles.css";

const customStyles = {
  content: {
    position: "absolute",
    top: 80,
    left: 40,
    right: 40,
    bottom: 80,
    padding: 40,
  },
};

class AdditionalResourcesMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [
        {
          title: "Video Clip Library",
          buttonTitle: "Access",
          url: "/videocliplibrary",
          external: false,
        },
        {
          title: "APPs",
          buttonTitle: "Access",
          url: "/apps",
          external: false,
        },
        {
          title: "Special Reports",
          buttonTitle: "Access",
          url: "/specialreports",
          external: false,
        },
        {
          title: "REPS RADIO",
          buttonTitle: "Access",
          url: "https://ppiweb.com/repsradio/",
          external: true,
        },
        {
          title: "Monday Mindset",
          buttonTitle: "Access",
          url: "https://ppiweb.com/resources/blog-latest-insights/",
          external: true,
        },
        {
          title: "Infographics & Downloads",
          buttonTitle: "Access",
          url: "/downloads",
          external: false,
        },

        {
          title: "Mentoring Session Recordings",
          buttonTitle: "Access",
          url: "/grouprecordings",
          external: false,
        },
        {
          title: "Error Elimination Tools Leader's Guide",
          buttonTitle: "Access",
          url:
            "https://ppilabassets.s3.amazonaws.com/Downloads/EE-Tools-Leaders-Guide_Rev10.01.pdf",
          external: true,
        },
      ],
    };
  }

  componentDidMount() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;
  }

  render() {
    const data = this.state.data;
    const { open } = this.state;

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;

    return (
      <MainContainer>
        <CardBody className="pt-0">
          <CardInfo>
            <CardTitle>
              <MainTitle>Additional Resources</MainTitle>
              <MainText>
                Resources to help you learn, grow, and prosper as a Next-Gen
                Leader
              </MainText>
            </CardTitle>
            <CardButton></CardButton>
          </CardInfo>
          <ItemContainer>
            {data.map((video, index) => {
              return (
                <ItemCard key={index}>
                  <Title className="mt-0">{video.title}</Title>

                  {video.external ? (
                    <a href={video.url} target="_blank">
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          {video.buttonTitle}
                        </Button>
                      </ButtonContainer>
                    </a>
                  ) : (
                    <ButtonContainer>
                      <Link to={video.url}>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          {video.buttonTitle}
                        </Button>
                      </Link>
                    </ButtonContainer>
                  )}
                </ItemCard>
              );
            })}
          </ItemContainer>
        </CardBody>
      </MainContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(AdditionalResourcesMini);

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 320px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #2ed5be;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 16px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 0;
  padding: 20px;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;
const CloseIcon = styled.span`
  font-size: 50px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
  width: 300px;
`;
