import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import { UPDATE_EMAIL } from './actionTypes'
import { updateEmail } from './actions'

export function * watchUserEmail () {
  yield takeEvery(UPDATE_EMAIL, updateEmail)
}

function * userSaga () {
  yield all([fork(watchUserEmail)])
}

export default userSaga
