import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  View,
} from "@react-pdf/renderer";
import React, { Component } from "react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";
import firebase from "firebase/app";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

//Import Breadcrumb

class Downloads extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, data: [] };
  }

  componentDidMount() {
    this.getData();
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db.collection("Downloads");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;

    // Create styles
    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
    });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Downloads" />
            <CardBody className="pt-0">
              <Row>
                {data.map((course, index) => {
                  return (
                    <EpisodeCard key={index}>
                      <Title className="mt-0">{course.title}</Title>
                      <LockContainer>
                        <box-icon
                          id="icon"
                          name="task"
                          color="#10029d"
                        ></box-icon>
                      </LockContainer>

                      <ButtonContainer>
                        <Button
                          onClick={this.onOpenModal.bind(this, index)}
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          Open
                        </Button>
                      </ButtonContainer>

                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          <a
                            style={{ width: "100%", color: "white" }}
                            href={course.pdfUrl}
                            download
                            target="_blank"
                          >
                            Download
                          </a>
                        </Button>
                      </ButtonContainer>

                      <Modal isOpen={open[index]} ariaHideApp={false}>
                        <button
                          type="button"
                          onClick={() => this.onCloseModal()}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <CloseIcon aria-hidden="true">&times;</CloseIcon>
                        </button>

                        <PlayerContainer>
                          <iframe
                            src={course.pdfUrl}
                            width="100%"
                            height="500px"
                          ></iframe>
                        </PlayerContainer>
                      </Modal>
                    </EpisodeCard>
                  );
                })}
              </Row>
            </CardBody>{" "}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Downloads);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 16px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  margin-top: 5px;
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const CloseIcon = styled.span`
  font-size: 50px;
`;

const CategoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
`;

const FilterButton = styled.button`
  margin: 10px;
  border-radius: 5px;
  padding: 10px;
  background-color: #fffff6;
  color: gray;
`;
