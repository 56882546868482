import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import Quiz from "react-quiz-component";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils.js";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import styled from "styled-components";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "HPIMCI Final Exam",
  quizSynopsis: "Quiz Synopsis",

  // Question 1
  questions: [
    {
      question:
        "When you can simultaneously achieve reductions in error rates with reductions in worker turnover, reductions in team member complaints/grievances, and increase in profits, what does this mean?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Team members are more engaged with and more fulfilled by their work. ",
        "The focus on KPIs has succeeded.",
        "The focus on the ‘bottom line’ has succeeded.",
        "Team members have learned to ‘toe the line’ and do what they’re told.",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 2

    {
      question:
        "Complete the statement: “Never doubt that a group of thoughtful, committed citizens can change the world; ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "however, it takes big bucks and lots of power to effect real change. ",
        "indeed, the only requirement is strong management and good KPIs.",
        "however, change can only be sustained through command and control.",
        "indeed, it’s the only thing that ever has.",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 3

    {
      question:
        "[TRUE or FALSE] Expanding your CONTEXT means learning everything you can so your mind can be more “full”.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 4

    {
      question:
        "The OLD-SCHOOL approach to performance improvement includes…[CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "COMMAND and CONTROL",
        "Growing a culture of DESIRE",
        "Team members doing the ‘right thing’ when no one is watching.",
        "DOGMA",
      ],
      correctAnswer: [1, 4],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 5
    {
      question:
        "Element (5) in Tim Autrey’s Course, Conquering the Challenge of Change, was “Champion the Value of Others.” This shows up again as the 4th element of HOW to get the MOST out of ANYTHING you do. WHICH ONE of the following best describes why this concept is so important? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "This is the way to use people so that they’ll support your goals and agenda. ",
        "This is how to get others to champion you and your goals/desires. ",
        "It provides a means to develop your cadre of ‘suck-ups’ and ‘brown-nosers’.",
        "It allows you to do very little while others do everything for you.",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 6

    {
      question:
        "[TRUE or FALSE] “Simplicity on the far side of complexity” refers to an insight, tactic, or tool that is easy to understand and/or execute while providing true and honest value relative difficult concepts, conditions, or circumstances.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 7

    {
      question:
        "Which of the following statements is/are true regarding “REPS”? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "“REPS” represents Retention, Effectiveness, Possibility, and Sanity.",
        "The REPS quadrants identify performance imperatives having potentially conflicting priorities and agendas.",
        "The way many organizations are configured and managed can promote conflicting priorities and agendas.",
        "Potentially conflicting priorities and agendas within many organizations exist mainly because of lack of vision and mission. ",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 8

    {
      question:
        "The Pathway™ identifies WHAT organizations should focus upon in efforts to achieve synergized sustainable performance improvement, which is… ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Measurable Outcomes",
        "Use of Tools",
        "Human Performance",
        "Culpability",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 9
    {
      question:
        "WHICH ONE of the following statements is TRUE regarding A Players, B Players, and C Players?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "The terms “A Player,” “B Player,” and “C Player” refer to how well team members perform on written exams.",
        "The best way to deal with a “C Player” is to leave them alone.",
        "“C Players” are typically the most popular and well-respected team members.",
        "Many “C Players” are not true ‘C Players,” rather, they are intelligent and highly frustrated members of the team.",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 10

    {
      question:
        "[TRUE or FALSE] To be a good leader, you must be a good multi-tasker.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 11

    {
      question:
        "Which of the following statements are TRUE regarding Precept #2 (84 to 94 percent of all mistakes on the job are setup by process, programmatic, or organizational issues? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "The Error Elimination Tools™ are primarily for the other 6 to 16 percent.",
        "The (3) primary types of setups include Landmines, Traps, and Roadblocks.",
        "Time Pressure is an example of a Roadblock.",
        "The other 6 to 16 percent mostly result from human fallibility.",
      ],
      correctAnswer: [1, 2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 12
    {
      question:
        "Complete this statement: “All progress begins by___________________________________________.”",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "having a detailed plan.",
        "defining great KPIs.",
        "telling the truth.",
        "getting rid of your “C players”.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 13

    {
      question:
        "Precept #4 (People who do the work have the answers) directly relates to WHICH ONE of the following Key Insights?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "The choices I make dictate the life I lead",
        "No one of us is as smart as all of us",
        "What gets appreciated gets repeated",
        "What I allow I teach",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 14

    {
      question:
        "When a team member gets hurt on the job, WHICH ONE of the following best indicates the percentage of time human error is involved?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["95", "100", "50", "75"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 15

    {
      question:
        "Choose the answer below which best completes the following statements.All motivation is ______________________. The two primal motivations are: ____________________ and ____________________.",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "external, fight, flight",
        "internal, survival, avoidance of complexity",
        "internal, avoidance of pain, acquisition of pleasure",
        "external, avoidance of people, acquisition of reward",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 16

    {
      question:
        "Which of the following statements are accurate relative to Key Insight #10 (The short-term and long-term consequences of the choices I make are typically different and often opposite)? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Decisions made for short-term pleasure can cause long-term pain.",
        "Choosing to save (rather than spend) money can be a good example.",
        "Choosing to smoke cigarettes early in life can be a good example.",
        "Anything that’s good for the short-term is good for the long-term.",
      ],
      correctAnswer: [1, 2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 17

    {
      question:
        "A work environment where average team member choices, actions, and behaviors typically exceed expectations indicates WHICH ONE of the following?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Exceptional command and control",
        "A culture of desire",
        "A culture of compliance",
        "Managing people like ‘things’",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 18

    {
      question:
        "WHICH ONE of the following methods of influence should NEVER be used?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Inspiration", "Force", "Command and Control", "Manipulation’"],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 19
    {
      question:
        "WHICH ONE of the following is required for you to get past someone’s mental “Gatekeeper” (their ‘Lizard Brain’) when you are attempting to influence their choices, actions, and/or behaviors?	",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Must be non-threatening",
        "Must involve a tangible reward",
        "There must be ‘something in it for them’",
        "Consistent verbal and non-verbal communication",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 20

    {
      question:
        "In our reactions/responses to our external world, WHICH ONE of the following separates humans from all other living creatures on the planet?	",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Personality", "Feelings", "Mindset & Perception", "The Gap"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },

    // Question 21
    {
      question:
        "My perception of reality is my reality. Your perception of reality is your reality. Because of this, if my perception of reality is different than your perception of reality, I am obviously ‘right’ and you are obviously ‘wrong’.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE ", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 22
    {
      question:
        "Which of the following promote(s) sustainable changes in choices, actions, and behaviors? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "A pay raise",
        "A bonus",
        "Genuine appreciation ",
        "Opportunity to develop and grow",
      ],
      correctAnswer: [3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 23
    {
      question:
        "[TRUE or FALSE] The growing sense of entitlement we are witnessing is a product of culture rather than of generation.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE ", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 24
    {
      question:
        "Which of the following statement(s) is/are correct relative to the Great Divide? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "A core feeling of FEAR leads to “attack” or “isolate” behaviors BELOW the Great Divide",
        "A core feeling of ENTHUSIASM leads to “improve” or “support” behaviors ABOVE the Great Divide",
        "“The pie is only so big” mentality is dominant ABOVE the Great Divide.",
        "A predominant mindset of LACK entices people to focus on OPPORTUNITY",
      ],
      correctAnswer: [1, 2],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 25
    {
      question:
        "When someone says, “It’s not my job,” WHICH ONE of the classic victim behaviors is on display?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["DENY ", "IGNORE", "DEFER", "BLAME"],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 26
    {
      question:
        "Who is primarily responsible for whether you tend to hang out BELOW or ABOVE the Great Divide?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["My spouse ", "Me", "My boss", "The government"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 27
    {
      question:
        "Key Insight #22 states: “If you are not part of the problem, you are not part of the solution.” As this relates to every area of your life, WHICH ONE of the steps for rising from BELOW to ABOVE the Great Divide is involved?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["ACCEPT", "ADMIT", "TRY", "ACT"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 28
    {
      question:
        "[TRUE or FALSE] Most people readily point out what they think needs to be changed in ‘the world’, but are reluctant to take a look in the mirror",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 29
    {
      question:
        "During WHICH ONE of the following Performance Modes does human fallibility show up most frequently? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "SKILL-Based",
        "RULE-Based",
        "PROCEDURE-Based",
        "PROBLEM-Based",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 30
    {
      question:
        "Considering “Defense in Depth,” WHICH ONE of the following offers the final layer of defense?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Team Defense",
        "Administrative Defense",
        "Behavioral Defense",
        "Physical Defense",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 31
    {
      question:
        "Which of the following Error Elimination Tools™ are considered to be “Power Tools?” [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Awareness",
        "Questioning Attitude",
        "Pre-Job Brief",
        "Post-Job Review",
      ],
      correctAnswer: [1, 2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 32
    {
      question:
        "WHICH ONE of the following correctly identifies the position having greatest impact on HOW work gets completed in the organization (the ‘linchpin’)?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Front Line Supervisor", "Department Head", "Director", "CEO"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 33
    {
      question:
        "Key Insight #18 states: Team members can only sustain levels of performance supported by the organization. The metaphor of a tree is used to describe the underlying elements of this Key Insight with the following conclusion: “The roots determine the fruits.” In this metaphor, what do the “roots” represent?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Organization vision and mission",
        "Work culture",
        "Organizational structure",
        "Policies, procedures, and work instructions",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 34
    {
      question:
        "Within the context of Next-Gen Performance Improvement, WHICH ONE of the following identifies the difference between a Close-Call, an Incident, and an Event? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Significance of consequences",
        "Whether safety-related or not",
        "Whether “in-plant” or “in-office”",
        "Seniority/classification of personnel involved",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 35
    {
      question:
        "[TRUE or FALSE] Traps, Roadblocks, and Landmines accumulate over time within an organization unless something is directly done to counter it. This is part of what’s known as Organizational Entropy.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 36
    {
      question:
        "In addition to Accumulation, there is another component to Organizational Entropy known as DRIFT. One type of DRIFT is the difference between “Work as Imagined” (WAI) and “Work as Done” (WAD). From the choices below, identify WHICH ONE best completes this statement: The other type of DRIFT is DRIFT in ____________________________. It tends to occur both ________________________________________. Associated behaviors can be observed as _______________________. ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "pay / annually and with promotions / entitlement ",
        "relationships / between Reliability & Efficiency and Safety & Productivity / team coherence",
        "adaptability / in Operations and Administration / relationship dysfunction",
        "perception of risk / personally and organizationally / complacency",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 37
    {
      question:
        " [TRUE or FALSE] Fully investigating every Close-Call (Near-Miss) is being as proactive as you can be. ",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 38
    {
      question:
        "WHICH ONE of the following statements is correct regarding Accountability, Responsibility, and Culpability?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Accountability is assigned by management, Responsibility is sense of internal ownership, Culpability is the level of punishment",
        "Accountability is internal ownership, Responsibility is externally assigned, Culpability is the degree to which a person should be held responsible",
        "Accountability is the degree to which a person should be held responsible, Responsibility is externally assigned, Culpability is the level of punishment",
        "Accountability is internal ownership, Responsibility and Culpability mean essentially the same thing",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 39

    {
      question:
        "Which of the items below are part of a correct understanding of Just Culture? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Blame-free",
        "Promotes learning",
        "Promotes improvement",
        "Satisfies the need for Accountability",
      ],
      correctAnswer: [2, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 40
    {
      question:
        "WHICH ONE of the following correctly identifies the P-A-M elements within a work environment that support team member motivation?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Purpose → Autonomy → Mastery",
        "Potential → Autonomy → Mission",
        "Purpose → Authority → Mastery",
        "Potential → Authority → Mission",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 41
    {
      question:
        "The first of four aspects of the “Next-Gen Mindset” is Focus. WHICH ONE of the following correctly identifies what you should primarily Focus upon when observing team members on the job?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "attitudes and behaviors",
        "roadblocks and compliance",
        "timeliness and compliance",
        "mistakes and landmines",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 42
    {
      question:
        "WHICH ONE of the choices below correctly completes this statement? “Wanna lead me? You need my ______________________________.It all depends upon _____________________________________.”",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Acquiescence / our org chart positions",
        "Permission / my level of self-esteem ",
        "Permission / our relationship",
        "Submission / your level of authority",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 43
    {
      question:
        "Which of the following statements are correct relative to the CORE FOUR™ mentoring ‘recipe’ for team member engagement? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "The Core Four™ is a sequence of “SHARE” / “ASK” interactions.",
        "The first SHARE is to identify the TOP THREE positives you observed. ",
        "In the “pivot” you ask, “WHAT could have gone better?”",
        "The final ASK includes 4 power words- “How can I help…”",
      ],
      correctAnswer: [1, 2, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 44
    {
      question:
        "[TRUE or FALSE] Within a culture of DESIRE, team member performance levels will generally exceed expectations.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE ", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 45
    {
      question:
        "Relative to Follow-Through associated with Principle-Based Engagement, WHICH ONE of the following is most correct?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Ensure the team member gets permission from HR before proceeding. ",
        "Take it upon yourself to resolve the issue for the team member.",
        "Assist the team member (as appropriate) in resolving the issue.",
        "Refer the team member to the associated Policy Manual.",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 46
    {
      question:
        "[TRUE or FALSE] Running an organization based upon principles allows people with differing values to work together effectively.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 47

    {
      question:
        " [TRUE or FALSE] The best Codes of Honor are created at the Executive Team level.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 48
    {
      question:
        "WHICH ONE of the following correctly represents the only item required to be on a Code of Honor and the basis for its requirement?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "“CANI” (Constant and Never-Ending Improvement)- sets the stage for proactively reversing organizational entropy",
        "“Be willing to call and be called”- provides management with agreed permission for holding a team member accountable to their Code.",
        "“CANI” (Constant and Never-Ending Improvement)- sets the stage for better observation of Vital Behaviors",
        "“Be willing to call and be called”- brings accountability and peer leadership out of the classroom into the work environment.",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 49

    {
      question:
        "Which of the following represent properly-written elements for inclusion in a team’s Code of Honor? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Embrace CANI",
        "Don’t Gossip",
        "Look for others’ mistakes",
        "Learn, share, mentor",
      ],
      correctAnswer: [1, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
    // Question 50
    {
      question:
        "Which of the choices below are accurate relative to “Trim Tabs” helping make our world a better and safer place?  [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "To be a “Trim Tab” I must jump far outside my existing sphere of influence.",
        "Transforming team culture is not hard and need not take long when we proper “Trim Tabs” are present",
        "The only way to change anything is to get massive numbers of people onboard. ",
        "Small forces properly applied can ultimately ‘steer the ship’.",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      point: "1",
    },
  ],
};

export default class C1FinalExam extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];
    var input6 = UserInput[5];
    var input7 = UserInput[6];
    var input8 = UserInput[7];
    var input9 = UserInput[8];
    var input10 = UserInput[9];
    var input11 = UserInput[10];
    var input12 = UserInput[11];
    var input13 = UserInput[12];
    var input14 = UserInput[13];
    var input15 = UserInput[14];
    var input16 = UserInput[15];
    var input17 = UserInput[16];
    var input18 = UserInput[17];
    var input19 = UserInput[18];
    var input20 = UserInput[19];
    var input21 = UserInput[20];
    var input22 = UserInput[21];
    var input23 = UserInput[22];
    var input24 = UserInput[23];
    var input25 = UserInput[24];
    var input26 = UserInput[25];
    var input27 = UserInput[26];
    var input28 = UserInput[27];
    var input29 = UserInput[28];
    var input30 = UserInput[29];
    var input31 = UserInput[30];
    var input32 = UserInput[31];
    var input33 = UserInput[32];
    var input34 = UserInput[33];
    var input35 = UserInput[34];
    var input36 = UserInput[35];
    var input37 = UserInput[36];
    var input38 = UserInput[37];
    var input39 = UserInput[38];
    var input40 = UserInput[39];
    var input41 = UserInput[40];
    var input42 = UserInput[41];
    var input43 = UserInput[42];
    var input44 = UserInput[43];
    var input45 = UserInput[44];
    var input46 = UserInput[45];
    var input47 = UserInput[46];
    var input48 = UserInput[47];
    var input49 = UserInput[48];
    var input50 = UserInput[49];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        C1FinalExam: {
          certfificateDate: Date.now(),
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
          UserInput6: UserInput[5],
          UserInput7: UserInput[6],
          UserInput8: UserInput[7],
          UserInput9: UserInput[8],
          UserInput10: UserInput[9],
          UserInput11: UserInput[10],
          UserInput12: UserInput[11],
          UserInput13: UserInput[12],
          UserInput14: UserInput[13],
          UserInput15: UserInput[14],
          UserInput16: UserInput[15],
          UserInput17: UserInput[16],
          UserInput18: UserInput[17],
          UserInput19: UserInput[18],
          UserInput20: UserInput[19],
          UserInput21: UserInput[20],
          UserInput22: UserInput[21],
          UserInput23: UserInput[22],
          UserInput24: UserInput[23],
          UserInput25: UserInput[24],
          UserInput26: UserInput[25],
          UserInput27: UserInput[26],
          UserInput28: UserInput[27],
          UserInput29: UserInput[28],
          UserInput30: UserInput[29],
          UserInput31: UserInput[30],
          UserInput32: UserInput[31],
          UserInput33: UserInput[32],
          UserInput34: UserInput[33],
          UserInput35: UserInput[34],
          UserInput36: UserInput[35],
          UserInput37: UserInput[36],
          UserInput38: UserInput[37],
          UserInput39: UserInput[38],
          UserInput40: UserInput[39],
          UserInput41: UserInput[40],
          UserInput42: UserInput[41],
          UserInput43: UserInput[42],
          UserInput44: UserInput[43],
          UserInput45: UserInput[44],
          UserInput46: UserInput[45],
          UserInput47: UserInput[46],
          UserInput48: UserInput[47],
          UserInput49: UserInput[48],
          UserInput50: UserInput[49],
        },
      })
      .then(() => {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true }, () => {});

      if (obj.numberOfCorrectAnswers >= 40) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  getUserInfo = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);

    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("Getting Results Data");
          console.log(doc.data().C1FinalExam);

          this.setState({ correct: doc.data().C1FinalExam.correct });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;
    var correct = this.state.correct;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>You passed the final exam!</MessageBody>

            <Link
              to={{
                pathname: "./certificate",
              }}
            >
              <Button
                style={{ marginTop: 10, marginTop: 20 }}
                color="primary"
                className="btn btn-primary btn-lg btn-block waves-effect waves-light"
              >
                Access Certificate
              </Button>
            </Link>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>

          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "4" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
