import React, { Component } from "react";
import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../../helpers/authUtils.js";

import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import logo from "../../../assets/images/labicon.png";

class Question1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      Question1: "",
      answer: "",
    };

    this.HandleQuestion = this.HandleQuestion.bind(this);
  }

  componentDidMount() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Getting Questions - Start

    var questionRef = db.collection("TCQuestions").doc(`Questions`);
    var getDoc = questionRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          this.setState({ Question1: doc.data().Question1 });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    // Getting Questions - End

    // Getting Answers - Start

    var answerRef = db.collection("Progress").doc(user);
    var getDoc = answerRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          this.setState({ answer: doc.data().tc1.Question1 });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    // Getting Answers - End
  }

  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  HandleQuestion = (e) => {
    e.preventDefault();
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    var answer = this.state.answer;

    var userRef = db
      .collection("Progress")
      .doc(user)
      .update({
        "tc1.Question1": answer,
      })
      .then(() => {
        this.props.next();
        console.log("Document successfully updated!");
      })
      .catch(function (error) {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  render() {
    return (
      <QuestionContainer>
        <Logo src={logo}></Logo>
        <AppTitle>Transformation Conversation</AppTitle>
        <QuestionNumber>Question 1</QuestionNumber>
        <ProgressBar value="25" max="100" />
        <QuestionTitle>{this.state.Question1}</QuestionTitle>
        <FormContainer>
          <Form>
            <FormArea
              type="text"
              name="answer"
              placeholder="Your Answer Here"
              onChange={this.updateInput}
              value={this.state.answer}
            />
          </Form>
        </FormContainer>

        <Button
          onClick={this.HandleQuestion}
          color="primary"
          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
        >
          NEXT
        </Button>
      </QuestionContainer>
    );
  }
}

export default Question1;

const AppTitle = styled.h1`
  margin: 20px;
  text-align: center;
`;

const QuestionContainer = styled.div``;

const QuestionNumber = styled.h1`
  text-align: center;
`;

const FormContainer = styled.div`
  padding: 20px;
`;

const Form = styled.form`
  width: 100%;
`;

const ProgressBar = styled.progress`
  width: 100%;
`;

const FormArea = styled.textarea`
  width: 100%;
  height: 150px;
  border-radius: 10px;
  padding: 10px;
`;

const QuestionTitle = styled.h1`
  font-size: 18px;
  padding: 10px;
`;

const Logo = styled.img`
  width: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
`;
