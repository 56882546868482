import { Button, Col, Container, Modal, Row } from "reactstrap";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { device } from "../device.js";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../../helpers/authUtils";
import { initFirebaseBackend } from "../../../helpers/authUtils";
import styled from "styled-components";

//Import Breadcrumb

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodeResource: [],
      episodeBriefings: [],
      episodeDownloads: [],
      episodeAudio: [],
      playbackRate: 1.0,
    };
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  componentDidMount() {
    this.getModuleAudio();
    this.getModules();
    this.getLabWorksheets();
    // this.getJSP();
    this.getGuides();
    this.getLeader();
    this.getMiscResources();
    this.getTeamBriefings();
    this.getDownloads();

    this.getRecall();
  }

  getRecall = () => {
    var currentData = this.state.episodeResource;

    console.log("GET RECALL");
    console.log(currentData);

    var recall5 = {
      title: "Recall Questions - Module 5",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall5",
    };

    var recall6 = {
      title: "Recall Questions - Module 6",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall6",
    };

    var recall7 = {
      title: "Recall Questions - Module 7",
      buttonTitle: "GO",
      description: "Description",
      external: false,

      url: "/recall7",
    };

    currentData.push(recall5, recall6, recall7);

    this.setState(
      {
        episodeResource: currentData,
      },
      () => {
        // Get Leader WorkSheets
      }
    );
  };

  getModuleAudio = () => {
    const db = firebase.firestore();
    let moduleRef = db.collection("ModuleAudio");

    let allModules = moduleRef
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          console.log(doc.data());

          var currentEpisodeAudio = this.state.episodeAudio;

          if (
            doc.data().id === "005" ||
            doc.data().id === "006" ||
            doc.data().id === "007"

            /* || doc.data().id === "006" ||
            doc.data().id === "007"*/
          ) {
            currentEpisodeAudio.push(doc.data());

            this.setState({ episodeAudio: currentEpisodeAudio });
          }
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  getTeamBriefings = () => {
    const fireBaseBackend = getFirebaseBackend();

    const db = firebase.firestore();

    db.collection("/TeamBriefings")
      .get()
      .then((briefings) => {
        briefings.forEach((briefing) => {
          console.log(briefing.id, "=>", briefing.data());

          var currentEpisodeResourceBriefings = this.state.episodeBriefings;

          if (
            briefing.id === "tb05" ||
            briefing.id === "tb06" ||
            briefing.id === "tb07" ||
            briefing.id === "tb08" ||
            briefing.id === "tb09" ||
            briefing.id === "tb10" ||
            briefing.id === "tb12" ||
            briefing.id === "tb13"
          ) {
            currentEpisodeResourceBriefings.push(briefing.data());

            this.setState(
              {
                episodeBriefings: currentEpisodeResourceBriefings,
              },
              () => {}
            );
          }
        });
      });
  };

  getModules() {
    const db = firebase.firestore();
    let citiesRef = db.collection(
      "/Courses/001/Synthesizer/Resources/Resources"
    );
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getLabWorksheets = () => {
    const db = firebase.firestore();

    // Get Lab WorkSheets
    db.collection("/Courses/001/00-Intro/Resources/Resources")
      .get()
      .then((sheets) => {
        sheets.forEach((sheet) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(sheet.id, " => ", sheet.data());

          var currentEpisodeResource = this.state.episodeResource;

          if (
            //  sheet.id === "lab11" ||
            //   sheet.id === "lab12" ||
            //   sheet.id === "lab13" ||
            //    sheet.id === "lab14" ||
            //   sheet.id === "lab15" ||
            sheet.id === "lab16"
          ) {
            console.log(sheet.data());

            currentEpisodeResource.push(sheet.data());

            this.setState(
              { episodeResource: currentEpisodeResource },
              () => {}
            );
          }
        });
      });
  };

  getJSP = () => {
    const db = firebase.firestore();

    // Get JSP

    db.collection("/Jumpstart")
      .get()
      .then((jumps) => {
        jumps.forEach((jump) => {
          console.log(jump.id, "=>", jump.data());

          var currentEpisodeResourceJump = this.state.episodeResource;

          if (jump.id === "002" || jump.id === "003") {
            currentEpisodeResourceJump.push(jump.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceJump,
              },
              () => {}
            );
          }
        });
      });
  };

  getGuides = () => {
    const db = firebase.firestore();

    // Exercise Guides

    db.collection("/Powerpoints")
      .get()
      .then((guides) => {
        guides.forEach((guide) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (
            guide.id === "ppe47" /*||
            guide.id === "ppe50" ||
            guide.id === "ppe51"*/
          ) {
            currentEpisodeResourceGuide.push(guide.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getLeader = () => {
    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/LeaderManuals")
      .get()
      .then((manuals) => {
        manuals.forEach((manual) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (manual.id === "05" || manual.id === "06" || manual.id === "07") {
            currentEpisodeResourceGuide.push(manual.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getMiscResources = () => {
    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/MiscResources")
      .get()
      .then((miscs) => {
        miscs.forEach((misc) => {
          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (
            misc.id === "06" ||
            misc.id === "07" ||
            misc.id === "08" ||
            misc.id === "09" ||
            misc.id === "010" ||
            misc.id === "011" ||
            misc.id === "012" ||
            misc.id === "013"
          ) {
            currentEpisodeResourceGuide.push(misc.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getDownloads() {
    console.log("Downloads HERE");
    const db = firebase.firestore();

    // Get Additional Resources

    db.collection("/Downloads")
      .get()
      .then((downs) => {
        downs.forEach((down) => {
          console.log("Downloads BELOW");
          console.log(down.id);
          var currentEpisodeResourceDownloads = this.state.episodeDownloads;

          if (down.id === "003" || down.id === "004") {
            currentEpisodeResourceDownloads.push(down.data());

            this.setState(
              {
                episodeDownloads: currentEpisodeResourceDownloads,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  }

  render() {
    const { open } = this.state;
    const resources = this.state.episodeResource;
    const resourcesBriefings = this.state.episodeBriefings;
    const resourcesDownloads = this.state.episodeDownloads;
    const audios = this.state.episodeAudio;
    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;

    return (
      <CardContainer>
        <MainTitle>Resources</MainTitle>
        <hr></hr>
        <div>
          {audios.map((audio, index) => {
            console.log("Audio HEre");
            console.log(audio);
            return (
              <Col key={index} mg={12} xl={12}>
                <MiniCardContainer>
                  <Title className="mt-0">{audio.title}</Title>
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        href={audio.url}
                        target="_blank"
                        style={{ color: "white" }}
                      >
                        {audio.buttonTitle}
                      </a>
                    </Button>
                  </ButtonContainer>
                </MiniCardContainer>
              </Col>
            );
          })}
        </div>
        {resources.map((resource, index) => {
          return (
            <Col key={index} mg={12} xl={12}>
              <MiniCardContainer>
                <Title className="mt-0">{resource.title}</Title>

                {resource.external ? (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        href={resource.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "white" }}
                      >
                        {resource.buttonTitle}
                      </a>
                    </Button>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <Link to={resource.url} style={{ color: "white" }}>
                        {resource.buttonTitle}
                      </Link>
                    </Button>
                  </ButtonContainer>
                )}
              </MiniCardContainer>
            </Col>
          );
        })}

        {resourcesDownloads.map((download, index) => {
          return (
            <Col key={index} mg={12} xl={12}>
              <MiniCardContainer>
                <Title className="mt-0">{download.title}</Title>

                <ButtonContainer
                  color="primary"
                  className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                >
                  <a
                    href={download.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    {download.buttonTitle}
                  </a>
                </ButtonContainer>
              </MiniCardContainer>
            </Col>
          );
        })}

        {resourcesBriefings.map((resource, index) => {
          return (
            <Col key={index} mg={12} xl={12}>
              <MiniCardContainer>
                <Title className="mt-0">{resource.title}</Title>

                <ButtonContainer>
                  <Button
                    onClick={this.onOpenModal.bind(this, index)}
                    color="primary"
                    className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                  >
                    {resource.buttonTitle}
                  </Button>
                </ButtonContainer>
                <Modal isOpen={open[index]} ariaHideApp={false}>
                  <button
                    type="button"
                    onClick={() => this.onCloseModal()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <CloseIcon aria-hidden="true">&times;</CloseIcon>
                  </button>
                  <TitleContainer>
                    <ModalTitle>{resource.title}</ModalTitle>
                  </TitleContainer>

                  <PlayerContainer>
                    <ReactPlayer
                      ref={this.refTB}
                      playbackRate={playbackRate}
                      onReady={this.handleReady}
                      onStart={console.log()}
                      onPlay={this.handlePlay}
                      onProgress={console.log()}
                      onDuration={console.log()}
                      onPause={console.log()}
                      onBuffer={console.log()}
                      onSeek={console.log()}
                      onEnded={console.log()}
                      controls={true}
                      url={resource.videoUrl}
                      playing={playing}
                      width="100%"
                      height="100%"
                      config={{}}
                    />
                  </PlayerContainer>
                  {resource.pdf ? (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        style={{
                          width: "100%",
                          color: "white",
                        }}
                        href={resource.pdfUrl}
                        download
                        target="_blank"
                      >
                        PDF
                      </a>
                    </Button>
                  ) : (
                    <p></p>
                  )}

                  {resource.ppt ? (
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                    >
                      <a
                        style={{
                          width: "100%",
                          color: "white",
                        }}
                        href={resource.pptUrl}
                        download
                        target="_blank"
                      >
                        PPT
                      </a>
                    </Button>
                  ) : (
                    <p></p>
                  )}
                </Modal>
              </MiniCardContainer>
            </Col>
          );
        })}
      </CardContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Resources);

const CardContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    padding: 5px;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
    margin-top: 10px;
  }

  @media ${device.tablet} {
    padding: 20px;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const ButtonLink = styled.a`
  display: block;
  color: white;
  width: 100%;
`;

const MiniCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: 20px;

  @media ${device.mobileS} {
    padding: 10px;
  }
`;

const MainTitle = styled.h1`
  @media ${device.mobileS} {
    font-size: 15px;
  }

  @media ${device.mobileM} {
    font-size: 35px;
  }
`;

const Title = styled.h1`
  flex: 1;
  @media ${device.mobileS} {
    font-size: 10px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const Text = styled.p`
  flex: 1;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const CloseIcon = styled.span`
  font-size: 50px;
`;

const PlayerContainer = styled.div`
  padding: 40px;
  background-color: #fffff6;
  border-radius: 10px;
`;
