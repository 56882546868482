import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import { Link } from "react-router-dom";

import img1 from "../../../assets/images/small/img-1.jpg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../../helpers/authUtils";

import ScrollContainer from "react-indiana-drag-scroll";

import styled from "styled-components";
//Import Breadcrumb

import { device } from "../device.js";

import "../styles.css";

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodes: [],
      fromNotifications: "Please Reload",
      episode0021Progress: 0,
      episode0022Progress: 0,
      episode0023Progress: 0,
      episode0024Progress: 0,
      episode0025Progress: 0,
      episode0026Progress: 0,
      episode0031Progress: 0,
      episode0032Progress: 0,
      episode0033Progress: 0,
      episode0034Progress: 0,
      episode0035Progress: 0,
      episode0036Progress: 0,
      episode0037Progress: 0,
      episode0038Progress: 0,
      episode0039Progress: 0,
      episode0310Progress: 0,
      episode0311Progress: 0,
      episode0041Progress: 0,
      episode0042Progress: 0,
      episode0043Progress: 0,
      episode0044Progress: 0,
      episode0045Progress: 0,
      episode0046Progress: 0,
      episode0047Progress: 0,

      episode0021Locked: true,
      episode0022Locked: true,
      episode0023Locked: true,
      episode0024Locked: true,
      episode0025Locked: true,
      episode0026Locked: true,
      episode0031Locked: true,
      episode0032Locked: true,
      episode0033Locked: true,
      episode0034Locked: true,
      episode0035Locked: true,
      episode0036Locked: true,
      episode0037Locked: true,
      episode0038Locked: true,
      episode0039Locked: true,
      episode0310Locked: true,
      episode0311Locked: true,
      episode0041Locked: true,
      episode0042Locked: true,
      episode0043Locked: true,
      episode0044Locked: true,
      episode0045Locked: true,
      episode0046Locked: true,
      episode0047Locked: true,
    };
  }

  componentDidMount() {
    this.getEpisodes();
    this.getUserData();
    this.getStatus();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getEpisodes() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/Coach/Content/Content");
    let allCities = citiesRef
      .orderBy("order")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.episodes;

          currentData.push(doc.data());

          this.setState({ episodes: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.getPrincipalEpisodesProgress();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("ID HERE");
          console.log(doc.data().episode001.locked);

          this.setState({
            episode0021Locked: doc.data().episode0021.locked,

            episode0022Locked: doc.data().episode0022.locked,
            episode0023Locked: doc.data().episode0023.locked,
            episode0024Locked: doc.data().episode0024.locked,
            episode0025Locked: doc.data().episode0025.locked,
            episode0026Locked: doc.data().episode0026.locked,
            episode0031Locked: doc.data().episode0031.locked,
            episode0032Locked: doc.data().episode0032.locked,
            episode0033Locked: doc.data().episode0033.locked,
            episode0034Locked: doc.data().episode0034.locked,
            episode0035Locked: doc.data().episode0035.locked,
            episode0036Locked: doc.data().episode0036.locked,
            episode0037Locked: doc.data().episode0037.locked,
            episode0038Locked: doc.data().episode0038.locked,
            episode0039Locked: doc.data().episode0039.locked,
            episode0310Locked: doc.data().episode0310.locked,
            episode0311Locked: doc.data().episode0311.locked,
            episode0041Locked: doc.data().episode0041.locked,
            episode0042Locked: doc.data().episode0042.locked,
            episode0043Locked: doc.data().episode0043.locked,
            episode0044Locked: doc.data().episode0044.locked,
            episode0045Locked: doc.data().episode0045.locked,
            episode0046Locked: doc.data().episode0046.locked,
            episode0047Locked: doc.data().episode0047.locked,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getPrincipalEpisodesProgress() {
    var data = this.state.data;
    console.log("DATA HERE");

    this.setState({
      episode0021Progress: Math.round(data[0].episode0021.progress),
      episode0022Progress: Math.round(data[0].episode0022.progress),
      episode0023Progress: Math.round(data[0].episode0023.progress),
      episode0024Progress: Math.round(data[0].episode0024.progress),
      episode0025Progress: Math.round(data[0].episode0025.progress),
      episode0026Progress: Math.round(data[0].episode0026.progress),
      episode0031Progress: Math.round(data[0].episode0031.progress),
      episode0032Progress: Math.round(data[0].episode0032.progress),
      episode0033Progress: Math.round(data[0].episode0033.progress),
      episode0034Progress: Math.round(data[0].episode0034.progress),
      episode0035Progress: Math.round(data[0].episode0035.progress),
      episode0036Progress: Math.round(data[0].episode0036.progress),
      episode0037Progress: Math.round(data[0].episode0037.progress),
      episode0038Progress: Math.round(data[0].episode0038.progress),
      episode0039Progress: Math.round(data[0].episode0039.progress),
      episode0310Progress: Math.round(data[0].episode0310.progress),
      episode0311Progress: Math.round(data[0].episode0311.progress),
      episode0041Progress: Math.round(data[0].episode0041.progress),
      episode0042Progress: Math.round(data[0].episode0042.progress),
      episode0043Progress: Math.round(data[0].episode0043.progress),
      episode0044Progress: Math.round(data[0].episode0044.progress),
      episode0045Progress: Math.round(data[0].episode0045.progress),
      episode0046Progress: Math.round(data[0].episode0046.progress),
      episode0047Progress: Math.round(data[0].episode0047.progress),
    });
  }

  render() {
    const { open } = this.state;
    const episodes = this.state.episodes;
    const episode0021Locked = this.state.episode0021Locked;
    const episode0022Locked = this.state.episode0022Locked;
    const episode0023Locked = this.state.episode0023Locked;
    const episode0024Locked = this.state.episode0024Locked;
    const episode0025Locked = this.state.episode0025Locked;
    const episode0026Locked = this.state.episode0026Locked;
    const episode0031Locked = this.state.episode0031Locked;
    const episode0032Locked = this.state.episode0032Locked;
    const episode0033Locked = this.state.episode0033Locked;
    const episode0034Locked = this.state.episode0034Locked;
    const episode0035Locked = this.state.episode0035Locked;
    const episode0036Locked = this.state.episode0036Locked;
    const episode0037Locked = this.state.episode0037Locked;
    const episode0038Locked = this.state.episode0038Locked;
    const episode0039Locked = this.state.episode0039Locked;
    const episode0310Locked = this.state.episode0310Locked;
    const episode0311Locked = this.state.episode0311Locked;
    const episode0041Locked = this.state.episode0041Locked;
    const episode0042Locked = this.state.episode0042Locked;
    const episode0043Locked = this.state.episode0043Locked;
    const episode0044Locked = this.state.episode0044Locked;
    const episode0045Locked = this.state.episode0045Locked;
    const episode0046Locked = this.state.episode0046Locked;
    const episode0047Locked = this.state.episode0047Locked;

    var percentage = 8;
    var item = <h1>Loading</h1>;
    return (
      <React.Fragment>
        <Row>
          <CardContainer>
            {episodes.map((episode, index) => {
              if (episode.currentEpisode === "/episode0021") {
                var percentage = this.state.episode0021Progress;
                var locked = episode0021Locked;
              } else if (episode.currentEpisode === "/episode0022") {
                var percentage = this.state.episode0022Progress;
                var locked = episode0022Locked;
              } else if (episode.currentEpisode === "/episode0023") {
                var percentage = this.state.episode0023Progress;
                var locked = episode0023Locked;
              } else if (episode.currentEpisode === "/episode0024") {
                var percentage = this.state.episode0024Progress;
                var locked = episode0024Locked;
              } else if (episode.currentEpisode === "/episode0025") {
                var percentage = this.state.episode0025Progress;
                var locked = episode0025Locked;
              } else if (episode.currentEpisode === "/episode0026") {
                var percentage = this.state.episode0026Progress;
                var locked = episode0026Locked;
              } else if (episode.currentEpisode === "/episode0031") {
                var percentage = this.state.episode0031Progress;
                var locked = episode0031Locked;
              } else if (episode.currentEpisode === "/episode0032") {
                var percentage = this.state.episode0032Progress;
                var locked = episode0032Locked;
              } else if (episode.currentEpisode === "/episode0033") {
                var percentage = this.state.episode0033Progress;
                var locked = episode0033Locked;
              } else if (episode.currentEpisode === "/episode0034") {
                var percentage = this.state.episode0034Progress;
                var locked = episode0034Locked;
              } else if (episode.currentEpisode === "/episode0035") {
                var percentage = this.state.episode0035Progress;
                var locked = episode0035Locked;
              } else if (episode.currentEpisode === "/episode0036") {
                var percentage = this.state.episode0036Progress;
                var locked = episode0036Locked;
              } else if (episode.currentEpisode === "/episode0037") {
                var percentage = this.state.episode0037Progress;
                var locked = episode0037Locked;
              } else if (episode.currentEpisode === "/episode0038") {
                var percentage = this.state.episode0038Progress;
                var locked = episode0038Locked;
              } else if (episode.currentEpisode === "/episode0039") {
                var percentage = this.state.episode0039Progress;
                var locked = episode0039Locked;
              } else if (episode.currentEpisode === "/episode0310") {
                var percentage = this.state.episode0310Progress;
                var locked = episode0310Locked;
              } else if (episode.currentEpisode === "/episode0311") {
                var percentage = this.state.episode0311Progress;
                var locked = episode0311Locked;
              } else if (episode.currentEpisode === "/episode0041") {
                var percentage = this.state.episode0041Progress;
                var locked = episode0041Locked;
              } else if (episode.currentEpisode === "/episode0042") {
                var percentage = this.state.episode0042Progress;
                var locked = episode0042Locked;
              } else if (episode.currentEpisode === "/episode0043") {
                var percentage = this.state.episode0043Progress;
                var locked = episode0043Locked;
              } else if (episode.currentEpisode === "/episode0044") {
                var percentage = this.state.episode0044Progress;
                var locked = episode0044Locked;
              } else if (episode.currentEpisode === "/episode0045") {
                var percentage = this.state.episode0045Progress;
                var locked = episode0045Locked;
              } else if (episode.currentEpisode === "/episode0046") {
                var percentage = this.state.episode0046Progress;
                var locked = episode0046Locked;
              } else if (episode.currentEpisode === "/episode0047") {
                var percentage = this.state.episode0047Progress;
                var locked = episode0047Locked;
              }

              if (episode.fast === true) {
                var fast = (
                  <FastContainer>
                    <Fast>
                      <box-icon
                        color="#f70c2e"
                        id="fast"
                        name="star"
                        type="solid"
                      ></box-icon>
                      <FastText>20%</FastText>
                    </Fast>
                  </FastContainer>
                );
              } else {
                var fast = <div></div>;
              }

              if (locked === true) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#f70c2e"
                        id="lock"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <ProgressContainer>
                      {percentage === 100 ? (
                        <CheckContainer>
                          <box-icon
                            size="lg"
                            color="#a3ff03"
                            name="check-circle"
                            type="solid"
                          ></box-icon>
                        </CheckContainer>
                      ) : (
                        <CircularProgressbar
                          style={{
                            width: 100,
                            position: "absolute",
                            display: "block",
                          }}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "30px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#f70c2e`,
                            textColor: `#f70c2e`,
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                          value={percentage}
                          text={`${percentage}%`}
                        />
                      )}
                    </ProgressContainer>
                    <Duration>
                      <TimeIcon>
                        <box-icon type="solid" name="time"></box-icon>
                      </TimeIcon>
                      <TextContainer>
                        <Text>{episode.duration}</Text>
                      </TextContainer>
                    </Duration>
                    <BottomContainer>
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          <Link
                            to={{
                              pathname: `${episode.currentEpisode}`,
                            }}
                            style={{ color: "#FFF" }}
                          >
                            Watch
                          </Link>
                        </Button>
                      </ButtonContainer>
                      {fast}
                    </BottomContainer>
                  </EpisodeCard>
                );
              }
              {
                return item;
              }
            })}
          </CardContainer>
        </Row>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episodes);

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FastContainer = styled.div`
  position: relative;
`;

const Fast = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
  }
`;

const FastText = styled.p`
  position: absolute;
  color: white;
  font-size: 15px;
  border: 1px solid green;

  @media ${device.mobileS} {
    display: none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #f70c2e;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    width: 160px;
    height: 180px;
    margin-bottom: 2px;
  }

  @media ${device.mobileM} {
    width: 190px;
    height: 200px;
  }

  @media ${device.mobileL} {
    width: 225px;
    height: 250px;
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.mobileL} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 17px;
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileL} {
    display: flex;
  }
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media ${device.mobileS} {
    width: 70px;
    height: 70px;
  }
  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
