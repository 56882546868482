import AdditionalResources from "../pages/AdditionalResources/index.js";
import AesmRegister from "../pages/Authentication/aesm-register";
import Apps from "../pages/Apps/index";
import BasicTables from "../pages/Tables/BasicTables";
import Blogs from "../pages/Blogs/index";
import C1FinalExam from "../pages/C1FinalExam/index.js";
import Calendar from "../pages/Calendar/index";
import CertificatePage from "../pages/CertificatePage/index";
import Chat from "../pages/Chat/Chat";
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";
import Course1 from "../pages/Course1/index";
import Courses from "../pages/Courses/index";
import Dashboard from "../pages/Dashboard/index";
import DatatableTables from "../pages/Tables/DatatableTables";
import Downloads from "../pages/Downloads/index.js";
import EditableTables from "../pages/Tables/EditableTables";
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import Episode from "../pages/Episode/index.js";
import Episode001 from "../pages/Course1/Intro/Episodes/episode001.js";
import Episode0011 from "../pages/Course1/Principal/Episodes/episode0011.js";
import Episode0012 from "../pages/Course1/Principal/Episodes/episode0012.js";
import Episode0013 from "../pages/Course1/Principal/Episodes/episode0013.js";
import Episode0014 from "../pages/Course1/Principal/Episodes/episode0014.js";
import Episode0015 from "../pages/Course1/Principal/Episodes/episode0015.js";
import Episode0016 from "../pages/Course1/Principal/Episodes/episode0016.js";
import Episode0017 from "../pages/Course1/Principal/Episodes/episode0017.js";
import Episode0018 from "../pages/Course1/Principal/Episodes/episode0018.js";
import Episode0019 from "../pages/Course1/Principal/Episodes/episode0019.js";
import Episode002 from "../pages/Course1/Intro/Episodes/episode002.js";
import Episode0021 from "../pages/Course1/Coach/Episodes/episode0021.js";
import Episode0022 from "../pages/Course1/Coach/Episodes/episode0022.js";
import Episode0023 from "../pages/Course1/Coach/Episodes/episode0023.js";
import Episode0024 from "../pages/Course1/Coach/Episodes/episode0024.js";
import Episode0025 from "../pages/Course1/Coach/Episodes/episode0025.js";
import Episode0026 from "../pages/Course1/Coach/Episodes/episode0026.js";
import Episode003 from "../pages/Course1/Intro/Episodes/episode003.js";
import Episode0031 from "../pages/Course1/Coach/Episodes/episode0031.js";
import Episode0032 from "../pages/Course1/Coach/Episodes/episode0032.js";
import Episode0033 from "../pages/Course1/Coach/Episodes/episode0033.js";
import Episode0034 from "../pages/Course1/Coach/Episodes/episode0034.js";
import Episode0035 from "../pages/Course1/Coach/Episodes/episode0035.js";
import Episode0036 from "../pages/Course1/Coach/Episodes/episode0036.js";
import Episode0037 from "../pages/Course1/Coach/Episodes/episode0037.js";
import Episode0038 from "../pages/Course1/Coach/Episodes/episode0038.js";
import Episode0039 from "../pages/Course1/Coach/Episodes/episode0039.js";
import Episode0041 from "../pages/Course1/Coach/Episodes/episode0041.js";
import Episode0042 from "../pages/Course1/Coach/Episodes/episode0042.js";
import Episode0043 from "../pages/Course1/Coach/Episodes/episode0043.js";
import Episode0044 from "../pages/Course1/Coach/Episodes/episode0044.js";
import Episode0045 from "../pages/Course1/Coach/Episodes/episode0045.js";
import Episode0046 from "../pages/Course1/Coach/Episodes/episode0046.js";
import Episode0047 from "../pages/Course1/Coach/Episodes/episode0047.js";
import Episode0051 from "../pages/Course1/Synthesizer/Episodes/episode0051.js";
import Episode0052 from "../pages/Course1/Synthesizer/Episodes/episode0052.js";
import Episode0053 from "../pages/Course1/Synthesizer/Episodes/episode0053.js";
import Episode0054 from "../pages/Course1/Synthesizer/Episodes/episode0054.js";
import Episode0055 from "../pages/Course1/Synthesizer/Episodes/episode0055.js";
import Episode0056 from "../pages/Course1/Synthesizer/Episodes/episode0056.js";
import Episode0061 from "../pages/Course1/Synthesizer/Episodes/episode0061.js";
import Episode0062 from "../pages/Course1/Synthesizer/Episodes/episode0062.js";
import Episode0063 from "../pages/Course1/Synthesizer/Episodes/episode0063.js";
import Episode0064 from "../pages/Course1/Synthesizer/Episodes/episode0064.js";
import Episode0065 from "../pages/Course1/Synthesizer/Episodes/episode0065.js";
import Episode0071 from "../pages/Course1/Synthesizer/Episodes/episode0071.js";
import Episode0072 from "../pages/Course1/Synthesizer/Episodes/episode0072.js";
import Episode0073 from "../pages/Course1/Synthesizer/Episodes/episode0073.js";
import Episode0074 from "../pages/Course1/Synthesizer/Episodes/episode0074.js";
import Episode0075 from "../pages/Course1/Synthesizer/Episodes/episode0075.js";
import Episode0076 from "../pages/Course1/Synthesizer/Episodes/episode0076.js";
import Episode0081 from "../pages/Course1/Maestro/Episodes/episode0081.js";
import Episode0082 from "../pages/Course1/Maestro/Episodes/episode0082.js";
import Episode0083 from "../pages/Course1/Maestro/Episodes/episode0083.js";
import Episode0084 from "../pages/Course1/Maestro/Episodes/episode0084.js";
import Episode0085 from "../pages/Course1/Maestro/Episodes/episode0085.js";
import Episode0086 from "../pages/Course1/Maestro/Episodes/episode0086.js";
import Episode0087 from "../pages/Course1/Maestro/Episodes/episode0087.js";
import Episode0110 from "../pages/Course1/Principal/Episodes/episode0110.js";
import Episode0111 from "../pages/Course1/Principal/Episodes/episode0111.js";
import Episode0112 from "../pages/Course1/Principal/Episodes/episode0112.js";
import Episode0310 from "../pages/Course1/Coach/Episodes/episode0310.js";
import Episode0311 from "../pages/Course1/Coach/Episodes/episode0311.js";
import EventCalendar from "../pages/EventCalendar";
import Exercises from "../pages/Exercises/index";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import FoundationalTopics from "../pages/FoundationalTopics/index";
import GroupRecordings from "../pages/GroupMentoringRecordings/index";
import Helpdesk from "../pages/Helpdesk/index.js";
import HpimsRegister from "../pages/Authentication/hpims-register";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import Implementation from "../pages/Implementation/index";
import Insider from "../pages/Insider/index";
import InvoiceDetail from "../pages/Invoices/invoices-detail";
import InvoicesList from "../pages/Invoices/invoices-list";
import JSP01 from "../pages/Jumpstart/Packs/jsp01.js";
import JSP02 from "../pages/Jumpstart/Packs/jsp02.js";
import JSP03 from "../pages/Jumpstart/Packs/jsp03.js";
import JSP04 from "../pages/Jumpstart/Packs/jsp04.js";
import JSP05 from "../pages/Jumpstart/Packs/jsp05.js";
import JSP06 from "../pages/Jumpstart/Packs/jsp06.js";
import JSP07 from "../pages/Jumpstart/Packs/jsp07.js";
import JSP08 from "../pages/Jumpstart/Packs/jsp08.js";
import JSP09 from "../pages/Jumpstart/Packs/jsp09.js";
import JSP10 from "../pages/Jumpstart/Packs/jsp10.js";
import JSP11 from "../pages/Jumpstart/Packs/jsp11.js";
import Jumpstart from "../pages/Jumpstart/index";
import LeaderManuals from "../pages/LeaderManuals/index";
import LeaderWorksheets from "../pages/LeaderWorksheets/index";
import Library from "../pages/Library/index";
import Login from "../pages/Authentication/Login";
import Login1 from "../pages/AuthenticationInner/Login";
import Logout from "../pages/Authentication/Logout";
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MiniCourses from "../pages/MiniCourses/index.js";
import PPN from "../pages/PPN/index";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesPricing from "../pages/Utility/pages-pricing";
import PagesStarter from "../pages/Utility/pages-starter";
import PagesTimeline from "../pages/Utility/pages-timeline";
import Podcasts from "../pages/Podcasts/index";
import Profile from "../pages/Profile/index";
import ProjectsCreate from "../pages/Projects/projects-create";
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/projects-overview";
import Pulse from "../pages/Pulse/index";
import React from "react";
import Recall1 from "../pages/Recall1/index.js";
import Recall2 from "../pages/Recall2/index.js";
import Recall3 from "../pages/Recall3/index.js";
import Recall4 from "../pages/Recall4/index.js";
import Recall5 from "../pages/Recall5/index.js";
import Recall6 from "../pages/Recall6/index.js";
import Recall7 from "../pages/Recall7/index.js";
import Recall8 from "../pages/Recall8/index.js";
import { Redirect } from "react-router-dom";
import Register from "../pages/Authentication/Register";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/Authentication/Register2";
import Register2A from "../pages/Authentication/Register2A";
import RegisterGeneral from "../pages/Authentication/RegisterGeneral";
import Reinforcement from "../pages/Reinforcement/index";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import SpecialReports from "../pages/SpecialReports/index";
import StartHere from "../pages/StartHere/index";
import TeamBriefings from "../pages/TeamBriefings/index";
import TeamExercises from "../pages/TeamExercises/index";
import TopPicks from "../pages/TopPicks/index";
import TransApp from "../pages/TransformationConversation/index";
import TransApp2 from "../pages/TransformationConversation2/index";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UpcomingEvents from "../pages/UpcomingEvents/index";
import VideoClipLibrary from "../pages/VideoClipLibrary/index.js";
import Workshops from "../pages/Workshops/index";

// Pages Component

// Pages Calendar

//Projects

//Email

//Invoices

// Authentication related pages

// Inner Authentication

// Dashboard

// JumpsStart Pages

// Episodes

// Intro Episodes

//Principal Episodes

//Coach Episodes

// Synthesizer Episodes

// Maestro Episodes

// Other

// Recall Imports

// Certificate Page

// Maps

//Icons

//Tables

// Forms

//Ui

//Pages

//Contacts

const authProtectedRoutes = [
  // Production Menu Items

  { path: "/transapp", component: TransApp },
  { path: "/transapp2", component: TransApp2 },

  { path: "/eventcalendar", component: EventCalendar },
  { path: "/helpdesk", component: Helpdesk },
  { path: "/starthere", component: StartHere },
  { path: "/downloads", component: Downloads },

  // JumpStart Pages500
  { path: "/jsp01", component: JSP01 },
  { path: "/jsp02", component: JSP02 },
  { path: "/jsp03", component: JSP03 },
  { path: "/jsp04", component: JSP04 },
  { path: "/jsp05", component: JSP05 },
  { path: "/jsp06", component: JSP06 },
  { path: "/jsp07", component: JSP07 },
  { path: "/jsp08", component: JSP08 },
  { path: "/jsp09", component: JSP09 },
  { path: "/jsp10", component: JSP10 },
  { path: "/jsp11", component: JSP11 },

  //Quiz Components
  { path: "/c1finalexam", component: C1FinalExam },
  { path: "/recall1", component: Recall1 },
  { path: "/recall2", component: Recall2 },
  { path: "/recall3", component: Recall3 },
  { path: "/recall4", component: Recall4 },
  { path: "/recall5", component: Recall5 },
  { path: "/recall6", component: Recall6 },
  { path: "/recall7", component: Recall7 },
  { path: "/recall8", component: Recall8 },
  // Episode Routes

  // Intro Routes
  { path: "/episode001", component: Episode001 },
  { path: "/episode002", component: Episode002 },
  { path: "/episode003", component: Episode003 },

  // Principal Routes
  { path: "/episode0011", component: Episode0011 },
  { path: "/episode0012", component: Episode0012 },
  { path: "/episode0013", component: Episode0013 },
  { path: "/episode0014", component: Episode0014 },
  { path: "/episode0015", component: Episode0015 },
  { path: "/episode0016", component: Episode0016 },
  { path: "/episode0017", component: Episode0017 },
  { path: "/episode0018", component: Episode0018 },
  { path: "/episode0019", component: Episode0019 },
  { path: "/episode0110", component: Episode0110 },
  { path: "/episode0111", component: Episode0111 },
  { path: "/episode0112", component: Episode0112 },

  // Coach Routes
  { path: "/episode0021", component: Episode0021 },
  { path: "/episode0022", component: Episode0022 },
  { path: "/episode0023", component: Episode0023 },
  { path: "/episode0024", component: Episode0024 },
  { path: "/episode0025", component: Episode0025 },
  { path: "/episode0026", component: Episode0026 },
  { path: "/episode0031", component: Episode0031 },
  { path: "/episode0032", component: Episode0032 },
  { path: "/episode0033", component: Episode0033 },
  { path: "/episode0034", component: Episode0034 },
  { path: "/episode0035", component: Episode0035 },
  { path: "/episode0036", component: Episode0036 },
  { path: "/episode0037", component: Episode0037 },
  { path: "/episode0038", component: Episode0038 },
  { path: "/episode0039", component: Episode0039 },
  { path: "/episode0310", component: Episode0310 },
  { path: "/episode0311", component: Episode0311 },
  { path: "/episode0041", component: Episode0041 },
  { path: "/episode0042", component: Episode0042 },
  { path: "/episode0043", component: Episode0043 },
  { path: "/episode0044", component: Episode0044 },
  { path: "/episode0045", component: Episode0045 },
  { path: "/episode0046", component: Episode0046 },
  { path: "/episode0047", component: Episode0047 },

  // Synthesizer Routes

  { path: "/episode0051", component: Episode0051 },
  { path: "/episode0052", component: Episode0052 },
  { path: "/episode0053", component: Episode0053 },
  { path: "/episode0054", component: Episode0054 },
  { path: "/episode0055", component: Episode0055 },
  { path: "/episode0056", component: Episode0056 },
  { path: "/episode0061", component: Episode0061 },
  { path: "/episode0062", component: Episode0062 },
  { path: "/episode0063", component: Episode0063 },
  { path: "/episode0064", component: Episode0064 },
  { path: "/episode0065", component: Episode0065 },
  { path: "/episode0071", component: Episode0071 },
  { path: "/episode0072", component: Episode0072 },
  { path: "/episode0073", component: Episode0073 },
  { path: "/episode0074", component: Episode0074 },
  { path: "/episode0075", component: Episode0075 },
  { path: "/episode0076", component: Episode0076 },

  //Maestro Routes
  { path: "/episode0081", component: Episode0081 },
  { path: "/episode0082", component: Episode0082 },
  { path: "/episode0083", component: Episode0083 },
  { path: "/episode0084", component: Episode0084 },
  { path: "/episode0085", component: Episode0085 },
  { path: "/episode0086", component: Episode0086 },
  { path: "/episode0087", component: Episode0087 },

  // Dashboard Routes
  { path: "/dashboard", component: Dashboard },
  { path: "/course1", component: Course1 },
  { path: "/episode", component: Episode },
  { path: "/minicourses", component: MiniCourses },
  { path: "/additional", component: AdditionalResources },

  // ToolBox Routes
  { path: "/apps", component: Apps },
  { path: "/pulse", component: Pulse },
  { path: "/specialreports", component: SpecialReports },
  { path: "/library", component: Library },
  { path: "/reinforcement", component: Reinforcement },
  { path: "/exercises", component: Exercises },
  { path: "/upcomingevents", component: UpcomingEvents },
  { path: "/foundationaltopics", component: FoundationalTopics },
  { path: "/toppicks", component: TopPicks },
  { path: "/podcasts", component: Podcasts },
  { path: "/blogs", component: Blogs },
  { path: "/pulse", component: Pulse },

  // Insider Routes
  { path: "/insider", component: Insider },

  //Implementation Routes
  { path: "/implementation", component: Implementation },

  // Courses Routes
  { path: "/courses", component: Courses },

  // Menu routes
  { path: "/jumpstart", component: Jumpstart },
  { path: "/leaderworksheets", component: LeaderWorksheets },
  { path: "/leadermanuals", component: LeaderManuals },
  { path: "/grouprecordings", component: GroupRecordings },
  { path: "/teambriefings", component: TeamBriefings },
  { path: "/teamexercises", component: TeamExercises },
  { path: "/videocliplibrary", component: VideoClipLibrary },

  // WorkShop Routes
  { path: "/workshops", component: Workshops },

  // Profile Routes
  { path: "/profile", component: Profile },

  //chat
  { path: "/chat", component: Chat },

  //calendar
  { path: "/calendar", component: Calendar },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail", component: InvoiceDetail },

  //Projects
  { path: "/projects-grid", component: ProjectsGrid },
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-overview", component: ProjectsOverview },
  { path: "/projects-create", component: ProjectsCreate },

  //Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },

  //Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Certificate
  { path: "/certificate", component: CertificatePage },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/register2", component: Register2 },
  { path: "/register2A", component: Register2A },
  { path: "/registergeneral", component: RegisterGeneral },
  { path: "/hpims-register", component: HpimsRegister },
  { path: "/aesm-register", component: AesmRegister },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  //Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-forget-pwd", component: ForgetPwd1 },

  // Unprotected apps
  { path: "/ppn", component: PPN },
];

export { authProtectedRoutes, publicRoutes };
