import "./styles.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils";
import img1 from "../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

//Import Breadcrumb




const customStyles = {
  content: {
    position: "absolute",
    top: 80,
    left: 40,
    right: 40,
    bottom: 80,
    padding: 40,
  },
};

class UpcomingEventsMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log(doc.data().batch);

          // Get Events

          //  db.collection("/Calendars/batches/" + doc.data().batch)
          db.collection("/Calendars/batches/Master")
            .limit(3)
            .get()
            .then((events) => {
              events.forEach((event) => {
                var currentData = this.state.data;

                currentData.push(event.data());

                this.setState({
                  data: currentData,
                });
              });
            });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  render() {
    const data = this.state.data;
    const { open } = this.state;

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;

    return (
      <MainContainer>
        <CardBody className="pt-0">
          <CardInfo>
            <CardTitle>
              <MainTitle>Upcoming Events</MainTitle>
              <MainText></MainText>
            </CardTitle>
            <CardButton>
              <ButtonTitle>
                <Link
                  to="/eventcalendar"
                  className="btn btn-primary waves-effect waves-light"
                  style={{ fontSize: 18, backgroundColor: "#58c4de" }}
                >
                  View All Events
                </Link>
              </ButtonTitle>
            </CardButton>
          </CardInfo>
          <EventsContainer>
            {data.map((video, index) => {
              return (
                <EventCard key={index}>
                  <TimeContainer style={{ backgroundColor: `${video.color}` }}>
                    <DateTitle>{video.dateTitle}</DateTitle>
                    <DateTime>{video.dateTime}</DateTime>
                  </TimeContainer>
                  <TimeTitleContainer>
                    <Title className="mt-0">{video.title}</Title>
                    <p>{video.description}</p>
                  </TimeTitleContainer>

                  <ButtonContainer>
                    <Button
                      color="primary"
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                      to="/dashboard"
                    >
                      {video.buttonTitle}
                    </Button>
                  </ButtonContainer>
                </EventCard>
              );
            })}
          </EventsContainer>
        </CardBody>
      </MainContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(UpcomingEventsMini);

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 320px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #2ed5be;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 16px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 0;
  padding: 20px;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;
const CloseIcon = styled.span`
  font-size: 50px;
`;

const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const EventCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;

  flex-wrap: wrap;
`;

const TimeContainer = styled.div`
  padding: 20px;
  border-radius: 20px;
`;

const DateTitle = styled.h1`
  font-size: 20px;
  color: white;
`;

const DateTime = styled.h1`
  font-size: 20px;
  color: white;
`;

const TimeTitleContainer = styled.div``;

const ButtonContainer = styled.div``;
