import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "./styles.css";

class JumpstartMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      JS001: true,
      JS002: true,
      JS003: true,
      JS004: true,
      JS005: true,
      JS006: true,
      JS007: true,
      JS008: true,
      JS009: true,
      JS010: true,
      JS011: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  getManualStatus = () => {
    console.log("GET MANUAL STATUS");

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().JumpStartPack);
          this.setState({
            JS001: doc.data().JumpStartPack.JS001,
            JS002: doc.data().JumpStartPack.JS002,
            JS003: doc.data().JumpStartPack.JS003,
            JS004: doc.data().JumpStartPack.JS004,
            JS005: doc.data().JumpStartPack.JS005,
            JS006: doc.data().JumpStartPack.JS006,
            JS007: doc.data().JumpStartPack.JS007,
            JS008: doc.data().JumpStartPack.JS008,
            JS009: doc.data().JumpStartPack.JS009,
            JS010: doc.data().JumpStartPack.JS010,
            JS011: doc.data().JumpStartPack.JS011,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData = () => {
    console.log("GET DATA");
    const db = firebase.firestore();
    let citiesRef = db.collection("Jumpstart").limit(5);
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {});
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  };

  render() {
    console.log("RENDER");
    const { open } = this.state;
    const data = this.state.data;
    var JS001 = this.state.JS001;
    console.log("STATE JS001 " + JS001);
    var JS002 = this.state.JS002;
    console.log("STATE JS002 " + JS002);
    var JS003 = this.state.JS003;
    console.log("STATE JS003 " + JS003);
    var JS004 = this.state.JS004;
    console.log("STATE JS004 " + JS004);
    var JS005 = this.state.JS005;
    console.log("STATE JS005 " + JS005);
    var JS006 = this.state.JS006;
    console.log("STATE JS006 " + JS006);
    var JS007 = this.state.JS007;
    console.log("STATE JS007 " + JS007);
    var JS008 = this.state.JS008;
    console.log("STATE JS008 " + JS008);
    var JS009 = this.state.JS009;
    console.log("STATE JS009 " + JS009);
    var JS010 = this.state.JS010;
    console.log("STATE JS010 " + JS010);
    var JS011 = this.state.JS011;
    console.log("STATE JS011 " + JS011);

    var render = <p>Locked</p>;

    return (
      <MainContainer>
        <CardBody className="pt-0">
          <CardInfo>
            <CardTitle>
              <MainTitle>Jump-Start Packs</MainTitle>
              <MainText>
                Concepts to share with your team and the materials with which to
                do so
              </MainText>
            </CardTitle>
            <CardButton>
              <ButtonTitle>
                <Link
                  to="/jumpstart"
                  className="btn btn-primary waves-effect waves-light"
                  style={{ fontSize: 18, backgroundColor: "#f70c2e" }}
                >
                  View All Jump-Start Packs
                </Link>
              </ButtonTitle>
            </CardButton>
          </CardInfo>
          <Row>
            {data.map((course, index) => {
              console.log(course);

              var locked = false;

              if (course.id === "001") {
                var locked = JS001;
                console.log(course.id + locked);
              } else if (course.id === "002") {
                console.log(course.id + locked);
                var locked = JS002;
              } else if (course.id === "003") {
                console.log(course.id + locked);
                var locked = JS003;
              } else if (course.id === "004") {
                console.log(course.id + locked);
                var locked = JS004;
              } else if (course.id === "005") {
                console.log(course.id + locked);
                var locked = JS005;
              } else if (course.id === "006") {
                console.log(course.id + locked);
                var locked = JS006;
              } else if (course.id === "007") {
                console.log(course.id + locked);
                var locked = JS007;
              } else if (course.id === "008") {
                console.log(course.id + locked);
                var locked = JS008;
              } else if (course.id === "009") {
                console.log(course.id + locked);
                var locked = JS009;
              } else if (course.id === "010") {
                console.log(course.id + locked);
                var locked = JS010;
              } else if (course.id === "011") {
                console.log(course.id + locked);
                var locked = JS011;
              }

              if (locked === true) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#f70c2e"
                        id="icon"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#f70c2e"
                        id="icon"
                        type="solid"
                        name="rocket"
                      ></box-icon>
                    </LockContainer>

                    <ButtonContainer>
                      <Button
                        color="primary"
                        className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        to="/dashboard"
                      >
                        <Link to={course.url} style={{ color: "#FFF" }}>
                          {course.buttonTitle}
                        </Link>
                      </Button>
                    </ButtonContainer>
                  </EpisodeCard>
                );
              }

              return render;
            })}
          </Row>
        </CardBody>
      </MainContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(JumpstartMini);

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 22px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 0px;
  padding: 20px;
`;
