import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import ReactPlayer from "react-player";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import logo from "../../assets/images/small/Team Exercises Banner_square.png";

import "./styles.css";

class TeamExercises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      PPE03: true,
      PPE04: true,
      PPE13: true,
      PPE16: true,
      PPE28: true,
      PPE45: true,
      PPE46: true,
      PPE47: true,
      PPE471: true,
      PPE48: true,
      PPE49: true,
      PPE50: true,
      PPE51: true,
      PPE52: true,
      playing: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  ref = (player) => {
    this.player = player;
  };

  handleProgress(response) {
    const db = firebase.firestore();
  }

  getManualStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().TeamExercises);
          this.setState({
            PPE03: doc.data().TeamExercises.PPE03,
            PPE04: doc.data().TeamExercises.PPE04,
            PPE13: doc.data().TeamExercises.PPE13,
            PPE16: doc.data().TeamExercises.PPE16,
            PPE28: doc.data().TeamExercises.PPE28,
            PPE45: doc.data().TeamExercises.PPE45,
            PPE46: doc.data().TeamExercises.PPE46,
            PPE47: doc.data().TeamExercises.PPE47,
            PPE471: doc.data().TeamExercises.PPE471,
            PPE48: doc.data().TeamExercises.PPE48,
            PPE49: doc.data().TeamExercises.PPE49,
            PPE50: doc.data().TeamExercises.PPE50,
            PPE51: doc.data().TeamExercises.PPE51,
            PPE52: doc.data().TeamExercises.PPE52,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db.collection("Powerpoints");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;

    const playbackRate = this.state.playbackRate;
    const playing = this.state.playing;

    var PPE03 = this.state.PPE03;
    var PPE04 = this.state.PPE04;
    var PPE13 = this.state.PPE13;
    var PPE16 = this.state.PPE16;
    var PPE28 = this.state.PPE28;
    var PPE45 = this.state.PPE45;
    var PPE46 = this.state.PPE46;
    var PPE47 = this.state.PPE47;
    var PPE471 = this.state.PPE471;
    var PPE48 = this.state.PPE48;
    var PPE49 = this.state.PPE49;
    var PPE50 = this.state.PPE50;
    var PPE51 = this.state.PPE51;
    var PPE52 = this.state.PPE52;

    var render = <p>Locked</p>;

    const welcome =
      "<p>These are the same exercises used by PPI Facilitators when conducting onsite implementation training sessions.</p><p>Each Exercise Guide provides step-by-step instructions on HOW to conduct the Exercise with your team members. As with the Team Briefings, the Exercises are released as you move along your Success Path, allowing team awareness and insight to move forward with you.</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Team Exercises" />

            <CardBody className="pt-0">
              <Row>
                <StageContainer>
                  <WelcomeContainer>
                    <WelcomeLogo src={logo} />
                  </WelcomeContainer>
                  <InfoContainer>
                    <div>
                      <h1>Team Exercises</h1>
                      {ReactHtmlParser(welcome)}
                    </div>
                  </InfoContainer>
                </StageContainer>
              </Row>
              <Row>
                {data.map((course, index) => {
                  var locked = false;
                  if (course.id === "003") {
                    var locked = PPE03;
                  } else if (course.id === "004") {
                    var locked = PPE04;
                  } else if (course.id === "013") {
                    var locked = PPE13;
                  } else if (course.id === "016") {
                    var locked = PPE16;
                  } else if (course.id === "028") {
                    var locked = PPE28;
                  } else if (course.id === "045") {
                    var locked = PPE45;
                  } else if (course.id === "046") {
                    var locked = PPE46;
                  } else if (course.id === "047") {
                    var locked = PPE47;
                  } else if (course.id === "0471") {
                    var locked = PPE471;
                  } else if (course.id === "048") {
                    var locked = PPE48;
                  } else if (course.id === "049") {
                    var locked = PPE49;
                  } else if (course.id === "050") {
                    var locked = PPE50;
                  } else if (course.id === "051") {
                    var locked = PPE51;
                  } else if (course.id === "052") {
                    var locked = PPE52;
                  }

                  if (locked === true) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#ff4a53"
                            id="lock"
                            type="solid"
                            name="lock"
                          ></box-icon>
                        </LockContainer>
                      </EpisodeCard>
                    );
                  } else if (locked === false) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#ff4a53"
                            id="icon"
                            type="solid"
                            name="slideshow"
                          ></box-icon>
                        </LockContainer>

                        <ButtonContainer>
                          <Button
                            onClick={this.onOpenModal.bind(this, index)}
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          >
                            {course.buttonTitle}
                          </Button>
                        </ButtonContainer>
                        <Modal isOpen={open[index]} ariaHideApp={false}>
                          <button
                            type="button"
                            onClick={() => this.onCloseModal()}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <CloseIcon aria-hidden="true">&times;</CloseIcon>
                          </button>
                          <TitleContainer>
                            <ModalTitle>{course.title}</ModalTitle>
                          </TitleContainer>
                          <PlayerContainer>
                            <iframe
                              src={course.url}
                              width="100%"
                              height="500px"
                            ></iframe>
                          </PlayerContainer>

                          {course.pdf ? (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              <a
                                style={{ width: "100%", color: "white" }}
                                href={course.url}
                                download
                                target="_blank"
                              >
                                PDF
                              </a>
                            </Button>
                          ) : (
                            <p></p>
                          )}

                          {course.ppt ? (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              <a
                                style={{ width: "100%", color: "white" }}
                                href={course.pptUrl}
                                download
                                target="_blank"
                              >
                                PPT
                              </a>
                            </Button>
                          ) : (
                            <p></p>
                          )}

                          {course.video ? (
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              <a
                                style={{ width: "100%", color: "white" }}
                                href={course.videoUrl}
                                download
                                target="_blank"
                              >
                                Video
                              </a>
                            </Button>
                          ) : (
                            <p></p>
                          )}
                        </Modal>
                      </EpisodeCard>
                    );
                  }

                  return render;
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(TeamExercises);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;

const CloseIcon = styled.span`
  font-size: 50px;
`;

const TitleContainer = styled.div``;

const ModalTitle = styled.h1``;

const ModalDescription = styled.p``;

const PlayerContainer = styled.div`
  padding: 20px;
`;
