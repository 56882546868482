import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Button,
} from "reactstrap";

import classnames from "classnames";

import backhome from "../../../../assets/images/small/back-home.png";

import nextButton from "../../../../assets/images/small/next.png";

import prevButton from "../../../../assets/images/small/previous.png";

import playIcon from "../../../../assets/images/small/Play.png";

import moment from "moment";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import firebase from "firebase/app";

import faker from "faker";

import ContentLoader, { Facebook } from "react-content-loader";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import styled, { keyframes } from "styled-components";

import { bounceIn, rotateIn, rotateOut } from "react-animations";

import ReactPlayer from "react-player";

import { initFirebaseBackend } from "../../../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../../../helpers/authUtils";

import { device } from "../../device.js";

const bounceAnimation = keyframes`${bounceIn}`;

const rotateInAnimation = keyframes`${rotateIn}`;

const rotateOutAnimation = keyframes`${rotateOut}`;

//CHANGE
class Episode0017 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActiveTab: "1",
      activeTabJustify: "1",
      activeTab1: "1",
      episodeData: [],
      episodeResource: [],
      episodeComments: [],
      loading: true,
      value: "",
      videoPlayed: 0.0,
      videoDuration: 0.0,
      DBVideoProgress: 0,
      initialSeek: 5,
      DBCurrentSeconds: 0,
      playbackRate: 1.0,
      light: true,
      overlay: false,
      complete: "none",
      email: "",
      CurrentVideoProgress: 0,
      CurrentVideoSeconds: 0,
      pause: "flex",
    };

    this.toggle1 = this.toggle1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.deleteComment = this.deleteComment.bind(this);
  }
  componentWillMount() {
    this.getEverything();
    this.getLeader();
    this.getMiscResources();
    this.getLabWorksheets();
    //this.getJSP();
    //this.getGuides();

    this.ref();
  }

  componentDidMount() {
    this.handleUpdateCurrent();
  }

  componentWillUnmount() {}

  getEverything() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    // console.log("Episode USer Below");

    // console.log(user);

    const db = firebase.firestore();

    // Get User Data

    let userProgressRef = db.collection("Progress").doc(user);
    let getUserProgress = userProgressRef
      .get()
      .then((progress) => {
        if (!progress.exists) {
          console.log("No such document! USER");
        } else {
          console.log("User Progress Below");

          //CHANGE

          //    console.log(progress.data().episode0017);
          var videoProgressDB = progress.data().episode0017.progress;

          var videoProgressSeconds = progress.data().episode0017.seconds;

          this.setState(
            {
              initialSeek: videoProgressSeconds,
              DBVideoProgress: videoProgressDB,
              DBCurrentSeconds: videoProgressSeconds,
              email: user,
            },
            () => {
              setTimeout(() => {
                this.handleSeekMouseUp();
              }, 3000);

              // Get Episode Data from DB

              //CHANGE
              let episodesRef = db
                .collection("/Courses/001/Principal/Content/Content")
                .doc("episode0017");

              let getEpisode = episodesRef.get().then((episode) => {
                if (!episode.exists) {
                  console.log("No such Episode Exists!");
                } else {
                  this.setState({ episodeData: episode.data() }, () => {
                    // Get Resources Once Episode Data is Set

                    // Get Comments Once Episode Data is Set

                    //    console.log(this.state.episodeResource);

                    //CHANGE
                    let commentsRef = db
                      .collection(
                        "/Courses/001/Principal/Content/Content/episode0017/Comments"
                      )
                      .orderBy("date", "desc");

                    let allComments = commentsRef
                      .get()
                      .then((comments) => {
                        comments.forEach((comment) => {
                          var currentComments = this.state.episodeComments;

                          currentComments.push(comment.data());

                          this.setState(
                            {
                              episodeComments: currentComments,
                            },
                            () => {
                              // Get User Data and Progress Below After Setting Comments
                              console.log(
                                "Adding Comment To Array - Comment Data Below"
                              );
                              //  console.log(this.state.episodeComments);
                            }
                          );
                        });

                        this.setState({ loading: false });
                      })
                      .catch((err) => {
                        console.log("Error getting Comments", err);
                      });
                  });
                }
              });
            }
          );
        }
      })
      .catch((err) => {
        console.log("Error getting User Progress", err);
      });
  }

  getLabWorksheets = () => {
    const fireBaseBackend = getFirebaseBackend();

    const db = firebase.firestore();

    // Get Lab WorkSheets
    db.collection("/Courses/001/00-Intro/Resources/Resources")
      .get()
      .then((sheets) => {
        sheets.forEach((sheet) => {
          // doc.data() is never undefined for query doc snapshots
          //  console.log(sheet.id, " => ", sheet.data());

          var currentEpisodeResource = this.state.episodeResource;

          if (sheet.id === "lab03") {
            console.log(sheet.data());

            currentEpisodeResource.push(sheet.data());

            this.setState(
              { episodeResource: currentEpisodeResource },
              () => {}
            );
          }
        });
      });
  };

  getJSP = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get JSP

    db.collection("/Jumpstart")
      .get()
      .then((jumps) => {
        jumps.forEach((jump) => {
          console.log(jump.id, "=>", jump.data());

          var currentEpisodeResourceJump = this.state.episodeResource;

          if (jump.id === "001") {
            currentEpisodeResourceJump.push(jump.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceJump,
              },
              () => {}
            );
          }
        });
      });
  };

  getGuides = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Exercise Guides

    db.collection("/Powerpoints")
      .get()
      .then((guides) => {
        guides.forEach((guide) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (guide.id === "ppe46") {
            currentEpisodeResourceGuide.push(guide.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getLeader = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/LeaderManuals")
      .get()
      .then((manuals) => {
        manuals.forEach((manual) => {
          //   console.log(guide.id, "=>", guide.data());

          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (manual.id === "01") {
            currentEpisodeResourceGuide.push(manual.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  getMiscResources = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    // Get Leader Manuals

    db.collection("/MiscResources")
      .get()
      .then((miscs) => {
        miscs.forEach((misc) => {
          var currentEpisodeResourceGuide = this.state.episodeResource;

          if (misc.id === "02") {
            currentEpisodeResourceGuide.push(misc.data());

            this.setState(
              {
                episodeResource: currentEpisodeResourceGuide,
              },
              () => {
                // Get Leader WorkSheets
              }
            );
          }
        });
      });
  };

  handleUpdateCurrent() {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    let userRef = db.collection("Progress").doc(user);
    //CHANGE
    let updateSingle = userRef.update({
      "current.principal": "/episode0017",
      "current.home": "/episode0017",
    });
  }

  handleSeekMouseUp = () => {
    console.log("Calling Seek");

    if (
      this.state.initialSeek === null ||
      this.state.initialSeek === undefined
    ) {
      console.log("Do Nothing");
    } else if (
      this.state.initialSeek != null ||
      this.state.initialSeek != undefined
    ) {
      this.player.seekTo(this.state.initialSeek);
    }
  };

  handleProgress(response) {
    const db = firebase.firestore();

    const User = this.state.email;

    var videoDuration = this.state.videoDuration;

    var DBVideoProgress = this.state.DBVideoProgress;
    var DBCurrentSeconds = this.state.DBCurrentSeconds;

    var CurrentVideoSeconds = response.playedSeconds;
    var CurrentVideoProgress = (CurrentVideoSeconds * 100) / videoDuration;

    // console.log("Current DB Video Progress Below");
    // console.log(DBVideoProgress);

    // console.log("Current DB Seconds Below");
    // console.log(DBCurrentSeconds);

    // console.log("Current Video Progress Below");
    // console.log(CurrentVideoProgress);

    // console.log("Current Video Seconds Below");
    // console.log(CurrentVideoSeconds);

    if (
      DBVideoProgress < CurrentVideoProgress &&
      DBCurrentSeconds < CurrentVideoSeconds
    ) {
      console.log("CALL DATABASE");

      this.setState({
        DBVideoProgress: CurrentVideoProgress,
        DBCurrentSeconds: CurrentVideoSeconds,
        CurrentVideoProgress: CurrentVideoProgress,
        CurrentVideoSeconds: CurrentVideoSeconds,
      });

      let userRef = db.collection("Progress").doc(User);

      //CHANGE
      let updateSingle = userRef.update({
        "episode0017.progress": CurrentVideoProgress,
        "episode0017.seconds": CurrentVideoSeconds,
      });
    } else {
      console.log("DO NOTHING");

      this.setState({
        CurrentVideoProgress: CurrentVideoProgress,
        CurrentVideoSeconds: CurrentVideoSeconds,
      });
    }

    if (CurrentVideoProgress === 100) {
      this.setState({ complete: "flex", pause: "none" });
    }
  }

  deleteComment(id, index) {
    const db = firebase.firestore();

    //CHANGE
    let deleteDoc = db
      .collection("/Courses/001/Principal/Content/Content/episode0017/Comments")
      .doc(id)
      .delete();

    var currentComments = this.state.episodeComments;

    currentComments.splice(index, 1);

    this.setState({ episodeComments: currentComments });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    event.preventDefault();

    //Get User Information

    let userRef = db.collection("Progress").doc(User);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          const id = faker.random.uuid();

          var date = new Date();
          var dateString = date.toString();

          let data = {
            user: User,
            value: this.state.value,
            date: new Date(),
            dateString: dateString,
            moment: moment().format(),
            id: id,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            avatar: doc.data().avatar,
          };

          //CHANGE
          let setDoc = db
            .collection(
              "/Courses/001/Principal/Content/Content/episode0017/Comments"
            )
            .doc(id)
            .set(data);

          var currentComments = this.state.episodeComments;

          currentComments.unshift(data);

          this.setState({ episodeComments: currentComments });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  ref = (player) => {
    this.player = player;
  };

  rewatch = () => {
    this.setState({ complete: "none", playing: true }, () => {
      this.player.seekTo(0);
    });
  };

  start = () => {
    var CurrentVideoSeconds = this.state.CurrentVideoSeconds;

    console.log("Current Video Seconds " + CurrentVideoSeconds);

    this.setState({ pause: "none", playing: true }, () => {
      this.player.seekTo(CurrentVideoSeconds);
    });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleMarkComplete = () => {
    console.log("Marking Complete");

    console.log(this.state.videoDuration);

    this.setState({ complete: "flex", playing: false }, () => {
      this.player.seekTo(this.state.videoDuration);
    });
  };

  handleReady = () => {
    console.log("Ready");

    // this.setState({ pause: "flex" });
  };

  handlePlay = () => {
    console.log("Playing");

    this.setState({ pause: "none" });
  };

  handlePause = () => {
    console.log("Pausing");

    this.setState({ pause: "flex" });
  };

  render() {
    const { open } = this.state;
    const playbackRate = this.state.playbackRate;
    const light = this.state.light;
    const playing = this.state.playing;
    const data = this.state.episodeData;
    const resources = this.state.episodeResource;
    const comments = this.state.episodeComments;

    const percentage = Math.round(this.state.DBVideoProgress);

    if (this.state.DBVideoProgress > 90) {
      var Mark = (
        <Button
          style={{ backgroundColor: "#58c4de " }}
          type="submit"
          onClick={this.handleMarkComplete}
        >
          Mark As Complete
        </Button>
      );
    } else {
      var Mark = <div></div>;
    }

    const loading = this.state.loading;

    const MyLoader = () => (
      <ContentLoader
        gradientRatio={0.2}
        backgroundColor="#ae89ff"
        foregroundColor="#ae89ff"
      >
        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    );

    const MyFacebookLoader = () => <Facebook />;

    /*<Link to={data.overlayNext} role="button">
                  CONTINUE
                </Link>*/

    var player = (
      <div className="embed-responsive embed-responsive-16by9">
        <PauseThumbnail style={{ display: `${this.state.pause}` }}>
          <StartButton onClick={this.start} type="submit">
            <ImagePlay src={playIcon} />
            <ImageThumb src={data.url} />
          </StartButton>
        </PauseThumbnail>
        <BouncyDiv style={{ display: `${this.state.complete}` }}>
          <CheckContainer>
            <box-icon
              size="lg"
              color="#a3ff03"
              name="check-circle"
              type="solid"
            ></box-icon>
          </CheckContainer>

          <Subtitle>
            <CongratsText>{data.overlayTitle}</CongratsText>
            <SubtitleText>{data.overlayDescription}</SubtitleText>
          </Subtitle>
          <ActionButtons>
            <div className="button-items">
              <ContinueButton>
                <Link
                  to={data.nextEpisode}
                  style={{ color: "rgba(0, 0, 0, 0.7)" }}
                >
                  Continue
                </Link>
              </ContinueButton>

              <RewatchButton onClick={this.rewatch} type="submit">
                Rewatch
              </RewatchButton>
            </div>
          </ActionButtons>
        </BouncyDiv>
        <ReactPlayer
          ref={this.ref}
          playbackRate={playbackRate}
          onReady={this.handleReady}
          onStart={console.log()}
          onPlay={this.handlePlay}
          onProgress={(response) => {
            this.handleProgress(response);
          }}
          onDuration={(response) => {
            this.setState({ videoDuration: response });
          }}
          onPause={this.handlePause}
          onBuffer={console.log()}
          onSeek={console.log()}
          onEnded={console.log()}
          controls={true}
          url={data.videourl}
          playing={playing}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: { controlsList: "nodownload" },
            },
          }}
        />
      </div>
    );

    if (loading === true) {
      return (
        <React.Fragment>
          <Container fluid>
            <Row>
              <Col sm={12} mg={12} lg={12}>
                <LoaderContainer>
                  <MyLoader />
                  <MyFacebookLoader />
                </LoaderContainer>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else if (loading === false) {
      return (
        <React.Fragment>
          <TabContainer className="page-content">
            <Container fluid>
              <CardBody className="pt-0">
                <Row>
                  <Col sm={12} mg={12} lg={12}>
                    <Row>
                      <TopContainer>
                        <CloseButton>
                          <Link
                            to={{
                              pathname: "./course1",
                              state: { activeTabJustify: "3" },
                            }}
                          >
                            <BackImage src={backhome}></BackImage>
                          </Link>
                        </CloseButton>
                        <LessonTitleContainer>
                          <LessonTitle>{data.title}</LessonTitle>

                          <LessonDuration>{data.duration}</LessonDuration>
                        </LessonTitleContainer>

                        {percentage === 100 ? (
                          <CheckContainer>
                            <box-icon
                              size="lg"
                              color="#a3ff03"
                              name="check-circle"
                              type="solid"
                            ></box-icon>
                          </CheckContainer>
                        ) : (
                          <ProgressContainer>
                            <CircularProgressbar
                              style={{
                                width: 100,
                                position: "absolute",
                                display: "block",
                              }}
                              styles={buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: "butt",

                                // Text size
                                textSize: "30px",

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors
                                pathColor: `#58c4de`,
                                textColor: `#760000`,
                                trailColor: "#d6d6d6",
                                backgroundColor: "#3e98c7",
                              })}
                              value={percentage}
                              text={`${percentage}%`}
                            />
                          </ProgressContainer>
                        )}

                        <NextEpisode>
                          <Link to={data.prevEpisode}>
                            <PrevImage src={prevButton}></PrevImage>
                          </Link>
                          <Link to={data.nextEpisode}>
                            <PrevImage src={nextButton}></PrevImage>
                          </Link>
                        </NextEpisode>
                      </TopContainer>
                    </Row>
                    <PlayerContainer>
                      {player}

                      <SecondaryContainer>
                        <div className="button-items" style={{ marginTop: 10 }}>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={1}
                          >
                            1X
                          </Button>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={1.5}
                          >
                            1.5X
                          </Button>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={2}
                          >
                            2X
                          </Button>
                          <Button
                            style={{
                              backgroundColor: "#ff8b00 ",
                              display: "none",
                            }}
                            type="submit"
                            onClick={console.log()}
                          >
                            Transcript
                          </Button>
                          {Mark}
                        </div>
                      </SecondaryContainer>
                    </PlayerContainer>

                    <Row>
                      <Col sm="12" mg="12" lg="12"></Col>
                    </Row>

                    <Row>
                      <TabContainer>
                        <Nav pills className="navtab-bg nav-justified">
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: this.state.activeTab1 === "1",
                              })}
                              onClick={() => {
                                this.toggle1("1");
                              }}
                            >
                              Lesson Overview
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: this.state.activeTab1 === "2",
                              })}
                              onClick={() => {
                                this.toggle1("2");
                              }}
                            >
                              Resources
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: this.state.activeTab1 === "3",
                              })}
                              onClick={() => {
                                this.toggle1("3");
                              }}
                            >
                              Comments
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab1}>
                          <TabPane
                            tabId="1"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                {ReactHtmlParser(data.overview)}
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane
                            tabId="2"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                {resources.map((resource, index) => {
                                  return (
                                    <Col key={index} mg={12} xl={12}>
                                      <MiniCardContainer>
                                        <Title className="mt-0">
                                          {resource.title}
                                        </Title>

                                        {resource.external ? (
                                          <ButtonContainer>
                                            <Button
                                              color="primary"
                                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                            >
                                              <a
                                                href={resource.url}
                                                target="_blank"
                                                style={{ color: "white" }}
                                              >
                                                {resource.buttonTitle}
                                              </a>
                                            </Button>
                                          </ButtonContainer>
                                        ) : (
                                          <ButtonContainer>
                                            <Button
                                              color="primary"
                                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                            >
                                              <Link
                                                to={resource.url}
                                                style={{ color: "white" }}
                                              >
                                                {resource.buttonTitle}
                                              </Link>
                                            </Button>
                                          </ButtonContainer>
                                        )}
                                      </MiniCardContainer>
                                    </Col>
                                  );
                                })}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane
                            tabId="3"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                <CommentContainer>
                                  <CommentLengthContainer>
                                    <CommentLength>
                                      {comments.length} Comments{" "}
                                    </CommentLength>
                                  </CommentLengthContainer>

                                  <CommentFormSection>
                                    <CommentForm
                                      onSubmit={this.handleSubmit}
                                      width="100%"
                                    >
                                      <TextAreaContainer>
                                        <TextArea
                                          value={this.state.value}
                                          onChange={this.handleChange}
                                          placeholder="Type Your Comment..."
                                        />
                                      </TextAreaContainer>
                                      <SubmitContainer>
                                        <div className="button-items">
                                          <Button
                                            type="submit"
                                            value="Comment"
                                            color="primary"
                                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                          >
                                            Comment
                                          </Button>
                                        </div>
                                      </SubmitContainer>
                                    </CommentForm>
                                  </CommentFormSection>

                                  <CommentSection>
                                    {comments.map((comment, index) => {
                                      var now = moment(new Date()); //todays date
                                      var end = moment(comment.moment); // another date
                                      var duration = moment
                                        .duration(now.diff(end))
                                        .humanize();

                                      // console.log(duration);

                                      if (this.state.email === comment.user) {
                                        return (
                                          <CommentCard key={index}>
                                            <CardInfo>
                                              <CommentTop>
                                                <AvatarImage
                                                  src={comment.avatar}
                                                ></AvatarImage>
                                                <Name>
                                                  {comment.firstName +
                                                    " " +
                                                    comment.lastName +
                                                    " "}{" "}
                                                </Name>
                                                <Badge>Class 2020</Badge>
                                              </CommentTop>
                                              <CommentText>
                                                {comment.value}
                                              </CommentText>
                                              <CommentText>
                                                {duration + " ago"}
                                              </CommentText>

                                              <Button
                                                style={{
                                                  backgroundColor: "#ff4a53 ",
                                                }}
                                                onClick={() =>
                                                  this.deleteComment(
                                                    comment.id,
                                                    index
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </CardInfo>
                                          </CommentCard>
                                        );
                                      } else {
                                        return (
                                          <CommentCard key={index}>
                                            <CardInfo>
                                              <CommentTop>
                                                <AvatarImage
                                                  src={comment.avatar}
                                                ></AvatarImage>
                                                <Name>
                                                  {comment.firstName +
                                                    " " +
                                                    comment.lastName +
                                                    " "}{" "}
                                                </Name>
                                                <Badge>Class 2020</Badge>
                                              </CommentTop>

                                              <CommentText>
                                                {comment.value}
                                              </CommentText>
                                              <CommentText>
                                                {duration + " ago"}
                                              </CommentText>
                                            </CardInfo>
                                          </CommentCard>
                                        );
                                      }
                                    })}
                                  </CommentSection>
                                </CommentContainer>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </TabContainer>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Container>
          </TabContainer>
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episode0017);

const ImageThumb = styled.img`
  width: 100%;
  height: 100%;
`;

const ImagePlay = styled.img`
  position: absolute;

  width: 100%;
  height: 100%;
  opacity: 0;
  animation: 1s ${rotateOutAnimation};

  :hover {
    opacity: 1;
    animation: 1s ${rotateInAnimation};
  }
`;

const ContinueButton = styled.button`
  width: 100px;
  height: 40px;
  margin: 5px;
  color: white;
  background-color: #ff4a53;
  border-radius: 10px;

  @media ${device.mobileS} {
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
  }
`;

const RewatchButton = styled.button`
  width: 100px;
  height: 40px;
  margin: 5px;
  color: rgba(0, 0, 0, 0.7);
  background-color: #42fb93;
  border-radius: 10px;
`;

const StartButton = styled.button`
  padding: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 1000px;
`;

const BackImage = styled.img`
  width: 80px;

  @media ${device.mobileS} {
    width: 40px;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    width: 80px;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const PrevImage = styled.img`
  width: 50px;
  margin: 10px;

  @media ${device.mobileS} {
    width: 30px;
  }

  @media ${device.tablet} {
    width: 50px;
  }
`;

const PlayerContainer = styled.div`
  padding: 40px;
  background-color: #fffff6;
  border-radius: 10px;
`;

const BouncyDiv = styled.div`1
  animation: 0.3s ${bounceAnimation};

  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  flex-direction: column;
  justify-content: space-evenly;
`;

const PauseThumbnail = styled.div`
  animation: 1s ${bounceAnimation};

  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  flex-direction: column;
  justify-content: space-evenly;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const Subtitle = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    display: flex;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const CongratsText = styled.h1`
  color: white;
  font-size: 20px;
  text-align: center;
`;

const SubtitleText = styled.p`
  color: white;
  font-size: 16px;
  text-align: center;
`;

const ActionButtons = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;

  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
  }
`;

const TabContainer = styled.div`
  margin: 20px 0px;
  background-color: white;
  width: 100%;
`;

const ButtonLink = styled.a`
  display: block;
  color: white;
  width: 100%;
`;

const MiniCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: 20px;

  @media ${device.mobileS} {
    padding: 10px;
  }
`;

const Title = styled.h1`
  flex: 1;
  @media ${device.mobileS} {
    font-size: 10px;
  }

  @media ${device.mobileM} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

const Text = styled.p`
  flex: 1;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
`;

const CloseButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LessonTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: center;
  padding: 20px;

  @media ${device.mobileS} {
    flex: 1;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    flex: 2;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const LessonTitle = styled.p`
  font-weight: bold;
  font-size: 20px;

  @media ${device.mobileS} {
    font-size: 15px;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    font-size: 20px;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const LessonDuration = styled.p``;

const ProgressContainer = styled.div`
  padding: 10px;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;
const NextEpisode = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileS} {
    flex: 1;
  }

  @media ${device.mobileM} {
  }

  @media ${device.mobileL} {
  }

  @media ${device.tablet} {
    flex: 3;
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const CardInfo = styled.div`
  flex: 2;
`;

const CommentContainer = styled.div`
  background-color: #c6fef4;
  border-radius: 20px;
  min-height: 100%;

  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CommentLengthContainer = styled.div`
  padding: 20px;
`;

const CommentLength = styled.h1`
  font-size: 20px;
  text-align: center;
  margin: 10px;
`;
const CommentSection = styled.div`
  height: 1000px;
  width: 100%;
  overflow-y: scroll;
`;

const CommentCard = styled.div`
  margin: 20px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  flex-direction: Row;
  padding: 20px;
  background-color: #fff;
`;

const CommentTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Name = styled.span`
  margin-left: 10px;
  display: block;
`;

const Badge = styled.span`
  margin-left: 10px;
  background-color: #2ed5be;
  padding: 10px;
  border-radius: 10px;
  color: white;
`;

const CommentText = styled.p``;
const CommentFormSection = styled.div`
  padding: 20px;
  flex: 1;
  border-top: 2px solid #00a0b7;
  border-bottom: 2px solid #00a0b7;
`;

const CommentForm = styled.form``;

const TextAreaContainer = styled.div`
  display: block;
  height: 100px;
  border: 1px solid #bebebe;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  margin: 0;
  outline: none;
  border-width: 0;
`;

const SubmitContainer = styled.div`
  padding: 10px;
`;

const SecondaryContainer = styled.div``;

/*Passing Data via Link


  const currentEpisode = this.props.location.state.currentEpisode

    const currentEpisodeString = currentEpisode.toString()*/

/* config={{
            file: {
              attributes: { controlsList: "nodownload" },
              tracks: [
                {
                  kind: "subtitles",
                  src: sub,
                  srcLang: "en",
                  default: true,
                },
              ],
            },
          }}*/
