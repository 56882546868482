import React, { Component } from "react";

import { Container, Row, Col } from "reactstrap";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils";

import { getFirebaseBackend } from "../../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb

class Mentor extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false, data: [], locked: true, id: "" };
  }

  componentWillMount() {
    this.getUserData();
    this.getMentor();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {});
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getMentor = () => {
    console.log("Running Mentor");
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    console.log(user);

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Timeline Below");

          var ReadyData = Object.entries(doc.data().timeline);

          console.log("READYDATA");

          console.log(ReadyData.sort());

          console.log(ReadyData);

          ReadyData.map((data) => {
            console.log(data[1].title);

            if (data[1].title === "Mentor") {
              console.log("Inside Mentor");

              console.log(data[1].locked);

              this.setState({ locked: data[1].locked });

              //   this.setState({ mentorLocked: true });
            } else {
              console.log("Not Inside Mentor");
              //  this.setState({ mentorLocked: false });
            }
          });

          //  this.setState({ data: ReadyData.sort() });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  render() {
    const locked = this.state.locked;

    return locked ? (
      <React.Fragment>
        <Container fluid>
          <Locked>
            <box-icon size="lg" type="solid" name="lock"></box-icon>
          </Locked>
        </Container>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Container fluid>
          <SectionOne>
            <Row>
              <Col lg={12}>
                <p>Hello</p>
              </Col>
            </Row>
          </SectionOne>
        </Container>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Mentor);

const SectionOne = styled.div`
  padding: 20px;
`;
const SectionTwo = styled.div`
  padding: 20px;
  margin-top: 20px;
`;

const Locked = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
