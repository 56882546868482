import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  Button,
} from "reactstrap";

import classnames from "classnames";

import imgprofile from "../../../src/assets/images/small/Profile Icon.png";
import imgprofile2 from "../../../src/assets/images/small/Community icon.png";

import backhome from "../../assets/images/small/back-home.png";
import nextButton from "../../assets/images/small/next.png";
import prevButton from "../../assets/images/small/previous.png";

import moment from "moment";

import { Link } from "react-router-dom";

import ScrollableFeed from "react-scrollable-feed";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { connect } from "react-redux";

import firebase from "firebase/app";

import avatar3 from "../../assets/images/users/avatar-3.jpg";

import faker from "faker";

import ContentLoader, { Facebook } from "react-content-loader";

import sub from "../../assets/images/small/sample.vtt";

import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ReactPlayer from "react-player";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

const bounceAnimation = keyframes`${bounce}`;

class Episode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customActiveTab: "1",
      activeTabJustify: "1",
      activeTab1: "1",
      redirected: false,
      episodeData: [],
      episodeResource: [],
      episodeComments: [],
      loading: true,
      value: "",
      videoPlayed: 0.0,
      videoDuration: 0.0,
      DBVideoProgress: 0,
      initialSeek: 5,
      DBCurrentSeconds: 0,
      playbackRate: 1.0,
      light: true,
      overlay: false,
      complete: "none",
      email: "",
    };

    this.toggle1 = this.toggle1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.deleteComment = this.deleteComment.bind(this);
    this.replyComment = this.replyComment.bind(this);
  }
  componentWillMount() {
    this.getEverything();
    this.ref();
  }

  componentDidMount() {
    this.handleUpdateCurrent();
  }

  componentWillUnmount() {}

  getEverything() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    console.log("Episode USer Below");

    console.log(user);

    const db = firebase.firestore();

    // Get User Data

    let userProgressRef = db.collection("Progress").doc(user);
    let getUserProgress = userProgressRef
      .get()
      .then((progress) => {
        if (!progress.exists) {
          console.log("No such document! USER");
        } else {
          console.log("User Progress Below");

          console.log(progress.data().episode001);
          var videoProgressDB = progress.data().episode001.progress;

          var videoProgressSeconds = progress.data().episode001.seconds;

          this.setState(
            {
              initialSeek: videoProgressSeconds,
              DBVideoProgress: videoProgressDB,
              DBCurrentSeconds: videoProgressSeconds,
              email: user,
            },
            () => {
              setTimeout(() => {
                this.handleSeekMouseUp();
              }, 2000);

              // Get Episode Data from DB

              let episodesRef = db
                .collection("/Courses/001/00-Intro/Content/Content")
                .doc("00");

              let getEpisode = episodesRef.get().then((episode) => {
                if (!episode.exists) {
                  console.log("No such Episode Exists!");
                } else {
                  this.setState({ episodeData: episode.data() }, () => {
                    // Get Resources Once Episode Data is Set
                    console.log("Episode Data Set and Data Below");
                    console.log(this.state.episodeData);
                    let resourcesRef = db.collection(
                      "/Courses/001/00-Intro/Content/Content/00/Resources"
                    );
                    let allResources = resourcesRef
                      .get()
                      .then((resources) => {
                        resources.forEach((resource) => {
                          var currentData = this.state.episodeResource;

                          currentData.push(resource.data());

                          this.setState(
                            { episodeResource: currentData },
                            () => {
                              // Get Comments Once Episode Data is Set
                              console.log(
                                "Adding Resource To Array - Resource Data Below"
                              );
                              console.log(this.state.episodeResource);

                              let commentsRef = db
                                .collection(
                                  "/Courses/001/00-Intro/Content/Content/00/Comments"
                                )
                                .orderBy("date", "desc");

                              let allComments = commentsRef
                                .get()
                                .then((comments) => {
                                  comments.forEach((comment) => {
                                    var currentComments = this.state
                                      .episodeComments;

                                    currentComments.push(comment.data());

                                    this.setState(
                                      {
                                        episodeComments: currentComments,
                                      },
                                      () => {
                                        // Get User Data and Progress Below After Setting Comments
                                        console.log(
                                          "Adding Comment To Array - Comment Data Below"
                                        );
                                        console.log(this.state.episodeComments);
                                      }
                                    );
                                  });

                                  this.setState({ loading: false });
                                })
                                .catch((err) => {
                                  console.log("Error getting Comments", err);
                                });
                            }
                          );
                        });
                      })
                      .catch((err) => {
                        console.log("Error getting Resources", err);
                      });
                  });
                }
              });
            }
          );
        }
      })
      .catch((err) => {
        console.log("Error getting User Progress", err);
      });
  }
  handleUpdateCurrent() {
    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    let userRef = db.collection("Progress").doc(user);

    let updateSingle = userRef.update({
      "current.intro": "/episode001",
    });
  }

  handleSeekMouseUp = () => {
    console.log("Calling Seek");

    if (this.state.initialSeek === null) {
      console.log("Do Nothing");
    } else if (this.state.initialSeek != null) {
      this.player.seekTo(this.state.initialSeek);
    }
  };

  handleProgress(response) {
    const db = firebase.firestore();

    const User = this.state.email;

    var videoDuration = this.state.videoDuration;

    var DBVideoProgress = this.state.DBVideoProgress;
    var DBCurrentSeconds = this.state.DBCurrentSeconds;

    var CurrentVideoSeconds = response.playedSeconds;
    var CurrentVideoProgress = (CurrentVideoSeconds * 100) / videoDuration;

    console.log("Current DB Video Progress Below");
    console.log(DBVideoProgress);

    console.log("Current DB Seconds Below");
    console.log(DBCurrentSeconds);

    console.log("Current Video Progress Below");
    console.log(CurrentVideoProgress);

    console.log("Current Video Seconds Below");
    console.log(CurrentVideoSeconds);

    if (
      DBVideoProgress < CurrentVideoProgress &&
      DBCurrentSeconds < CurrentVideoSeconds
    ) {
      console.log("CALL DATABASE");

      this.setState({
        DBVideoProgress: CurrentVideoProgress,
        DBCurrentSeconds: CurrentVideoSeconds,
      });

      let userRef = db.collection("Progress").doc(User);

      let updateSingle = userRef.update({
        "episode001.progress": CurrentVideoProgress,
        "episode001.seconds": CurrentVideoSeconds,
      });
    } else {
      console.log("DO NOTHING");
    }

    if (CurrentVideoProgress == 100) {
      this.setState({ complete: "flex" });
    }
  }

  deleteComment(id, index) {
    console.log(id);
    console.log(index);

    console.log(id + index);
    const db = firebase.firestore();

    let deleteDoc = db
      .collection("/Courses/001/00-Intro/Content/Content/00/Comments")
      .doc(id)
      .delete();

    var currentComments = this.state.episodeComments;

    currentComments.splice(index, 1);

    this.setState({ episodeComments: currentComments });
  }

  replyComment(id, index) {
    console.log(id);
    console.log(index);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    const User = this.state.email;

    event.preventDefault();

    const db = firebase.firestore();

    //Get User Information

    let userRef = db.collection("Users").doc(User);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          const id = faker.random.uuid();

          var date = new Date();
          var dateString = date.toString();

          let data = {
            user: User,
            value: this.state.value,
            date: new Date(),
            dateString: dateString,
            moment: moment().format(),
            id: id,
            firstName: doc.data().firstName,
            lastName: doc.data().lastName,
            avatar: doc.data().avatar,
          };

          let setDoc = db
            .collection("/Courses/001/00-Intro/Content/Content/00/Comments")
            .doc(id)
            .set(data);

          var currentComments = this.state.episodeComments;

          currentComments.unshift(data);

          this.setState({ episodeComments: currentComments });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  }

  toggle1(tab) {
    if (this.state.activeTab1 !== tab) {
      this.setState({
        activeTab1: tab,
      });
    }
  }

  ref = (player) => {
    this.player = player;
  };

  test = () => {
    this.setState({ complete: "none", playing: true }, () => {
      this.player.seekTo(0);
    });
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleMarkComplete = () => {
    console.log("MArking Complete");

    this.player.seekTo(this.state.videoDuration);
  };

  render() {
    const { open } = this.state;
    const playbackRate = this.state.playbackRate;
    const light = this.state.light;
    const playing = this.state.playing;
    const data = this.state.episodeData;
    const resources = this.state.episodeResource;
    const comments = this.state.episodeComments;

    const percentage = Math.round(this.state.DBVideoProgress);

    const play = true;

    const { title } = this.props.location.state;
    const { currentEpisode } = this.props.location.state;
    const { nextEpisode } = this.props.location.state;

    var Mark = (
      <Button
        style={{ backgroundColor: "#58c4de " }}
        type="submit"
        onClick={this.handleMarkComplete}
      >
        Mark As Complete
      </Button>
    );

    if (this.state.DBVideoProgress > 90) {
      var Mark = (
        <Button
          style={{ backgroundColor: "#58c4de " }}
          type="submit"
          onClick={this.handleMarkComplete}
        >
          Mark As Complete
        </Button>
      );
    } else {
      var Mark = <div></div>;
    }

    const loading = this.state.loading;

    const MyLoader = () => (
      <ContentLoader
        gradientRatio={0.2}
        backgroundColor="#ae89ff"
        foregroundColor="#ae89ff"
      >
        <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
        <rect x="80" y="17" rx="4" ry="4" width="300" height="13" />
        <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
      </ContentLoader>
    );

    const MyFacebookLoader = () => <Facebook />;

    var Progress = (
      <ProgressContainer>
        <CircularProgressbar
          style={{
            width: 100,
            position: "absolute",
            display: "block",
          }}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "30px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: `#58c4de`,
            textColor: `#760000`,
            trailColor: "#d6d6d6",
            backgroundColor: "#3e98c7",
          })}
          value={percentage}
          text={`${percentage}%`}
        />
      </ProgressContainer>
    );
    /*<Link to={data.overlayNext} role="button">
                  CONTINUE
                </Link>*/

    var player = (
      <div className="embed-responsive embed-responsive-16by9">
        <BouncyDiv style={{ display: `${this.state.complete}` }}>
          <CheckContainer>
            <box-icon
              size="lg"
              color="#a3ff03"
              name="check-circle"
              type="solid"
            ></box-icon>
          </CheckContainer>

          <Subtitle>
            <CongratsText>{data.overlayTitle}</CongratsText>
            <SubtitleText>{data.overlayDescription}</SubtitleText>
          </Subtitle>
          <ActionButtons>
            <div className="button-items">
              <ContinueButton>Continue</ContinueButton>

              <RewatchButton onClick={this.test} type="submit">
                Rewatch
              </RewatchButton>
            </div>
          </ActionButtons>
        </BouncyDiv>
        <ReactPlayer
          ref={this.ref}
          playbackRate={playbackRate}
          onReady={console.log()}
          onStart={console.log()}
          onPlay={console.log()}
          onProgress={(response) => {
            this.handleProgress(response);
          }}
          progressInterval={2000}
          onDuration={(response) => {
            this.setState({ videoDuration: response });
          }}
          onPause={console.log()}
          onBuffer={console.log()}
          onSeek={console.log()}
          onEnded={console.log("ENDED")}
          controls={true}
          url={data.videourl}
          playing={playing}
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: { controlsList: "nodownload" },
              tracks: [
                {
                  kind: "subtitles",
                  src: sub,
                  srcLang: "en",
                  default: true,
                },
              ],
            },
          }}
        />
      </div>
    );

    if (loading === true) {
      return (
        <React.Fragment>
          <Container fluid>
            <Row>
              <Col sm={12} mg={12} lg={12}>
                <LoaderContainer>
                  <MyLoader />
                  <MyFacebookLoader />
                </LoaderContainer>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else if (loading === false) {
      return (
        <React.Fragment>
          <TabContainer className="page-content">
            <Container fluid>
              <CardBody className="pt-0">
                <Row>
                  <Col sm={12} mg={12} lg={12}>
                    <Row>
                      <TopContainer>
                        <CloseButton>
                          <BackImage src={backhome}></BackImage>
                        </CloseButton>
                        <LessonTitleContainer>
                          <LessonTitle>{data.title}</LessonTitle>

                          <LessonDuration>{data.duration}</LessonDuration>
                        </LessonTitleContainer>
                        {Progress}

                        <NextEpisode>
                          <PrevImage src={prevButton}></PrevImage>
                          <PrevImage src={nextButton}></PrevImage>
                        </NextEpisode>
                      </TopContainer>
                    </Row>
                    <PlayerContainer>
                      {player}

                      <SecondaryContainer>
                        <div className="button-items" style={{ marginTop: 10 }}>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={1}
                          >
                            1X
                          </Button>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={1.5}
                          >
                            1.5X
                          </Button>
                          <Button
                            style={{ backgroundColor: "#10029d " }}
                            type="submit"
                            onClick={this.handleSetPlaybackRate}
                            value={2}
                          >
                            2X
                          </Button>
                          <Button
                            style={{ backgroundColor: "#ff8b00 " }}
                            type="submit"
                            onClick={console.log("Clicked")}
                          >
                            Transcript
                          </Button>
                          {Mark}
                        </div>
                      </SecondaryContainer>
                    </PlayerContainer>

                    <Row>
                      <Col sm="12" mg="12" lg="12"></Col>
                    </Row>

                    <Row>
                      <TabContainer>
                        <Nav pills className="navtab-bg nav-justified">
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                              }}
                              className={classnames({
                                active: this.state.activeTab1 === "1",
                              })}
                              onClick={() => {
                                this.toggle1("1");
                              }}
                            >
                              Lesson Overview
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: this.state.activeTab1 === "2",
                              })}
                              onClick={() => {
                                this.toggle1("2");
                              }}
                            >
                              Resources
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: this.state.activeTab1 === "3",
                              })}
                              onClick={() => {
                                this.toggle1("3");
                              }}
                            >
                              Comments
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={this.state.activeTab1}>
                          <TabPane
                            tabId="1"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                {ReactHtmlParser(data.overview)}
                              </Col>
                            </Row>
                          </TabPane>

                          <TabPane
                            tabId="2"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                {resources.map((resource, index) => {
                                  return (
                                    <Col key={index} mg={12} xl={12}>
                                      <MiniCardContainer>
                                        <Title className="mt-0">
                                          {resource.title}
                                        </Title>
                                        <Text>
                                          'Duration ' + {resource.duration}
                                        </Text>

                                        <ButtonContainer>
                                          <Button
                                            color="primary"
                                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                            to="/dashboard"
                                          >
                                            <ButtonLink href="https://ppi-tc.com/">
                                              GO
                                            </ButtonLink>
                                          </Button>
                                        </ButtonContainer>
                                      </MiniCardContainer>
                                    </Col>
                                  );
                                })}
                              </Col>
                            </Row>
                          </TabPane>
                          <TabPane
                            tabId="3"
                            className="p-3"
                            style={{ backgroundColor: "#fff" }}
                          >
                            <Row>
                              <Col sm="12">
                                <CommentContainer>
                                  <CommentLengthContainer>
                                    <CommentLength>
                                      {comments.length} Comments{" "}
                                    </CommentLength>
                                  </CommentLengthContainer>

                                  <CommentFormSection>
                                    <CommentForm
                                      onSubmit={this.handleSubmit}
                                      width="100%"
                                    >
                                      <TextAreaContainer>
                                        <TextArea
                                          value={this.state.value}
                                          onChange={this.handleChange}
                                          placeholder="Type Your Comment..."
                                        />
                                      </TextAreaContainer>
                                      <SubmitContainer>
                                        <div className="button-items">
                                          <Button
                                            type="submit"
                                            value="Comment"
                                            color="primary"
                                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                                          >
                                            Comment
                                          </Button>
                                        </div>
                                      </SubmitContainer>
                                    </CommentForm>
                                  </CommentFormSection>

                                  <CommentSection>
                                    {comments.map((comment, index) => {
                                      var now = moment(new Date()); //todays date
                                      var end = moment(comment.moment); // another date
                                      var duration = moment
                                        .duration(now.diff(end))
                                        .humanize();

                                      console.log(duration);

                                      if (this.state.email === comment.user) {
                                        return (
                                          <CommentCard key={index}>
                                            <CardInfo>
                                              <CommentTop>
                                                <AvatarImage
                                                  src={comment.avatar}
                                                ></AvatarImage>
                                                <Name>
                                                  {comment.firstName +
                                                    " " +
                                                    comment.lastName +
                                                    " "}{" "}
                                                </Name>
                                                <Badge>Class 2020</Badge>
                                              </CommentTop>
                                              <CommentText>
                                                {comment.value}
                                              </CommentText>
                                              <CommentText>
                                                {duration + " ago"}
                                              </CommentText>

                                              <Button
                                                style={{
                                                  backgroundColor: "#ff4a53 ",
                                                }}
                                                onClick={() =>
                                                  this.deleteComment(
                                                    comment.id,
                                                    index
                                                  )
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </CardInfo>
                                          </CommentCard>
                                        );
                                      } else {
                                        return (
                                          <CommentCard key={index}>
                                            <CardInfo>
                                              <CommentTop>
                                                <AvatarImage
                                                  src={comment.avatar}
                                                ></AvatarImage>
                                                <Name>
                                                  {comment.firstName +
                                                    " " +
                                                    comment.lastName +
                                                    " "}{" "}
                                                </Name>
                                                <Badge>Class 2020</Badge>
                                              </CommentTop>

                                              <CommentText>
                                                {comment.value}
                                              </CommentText>
                                              <CommentText>
                                                {duration + " ago"}
                                              </CommentText>
                                            </CardInfo>
                                          </CommentCard>
                                        );
                                      }
                                    })}
                                  </CommentSection>
                                </CommentContainer>
                              </Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </TabContainer>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Container>
          </TabContainer>
        </React.Fragment>
      );
    }
  }
}

/*Automatically Scroll to the bottom



<ScrollableFeed>
                                      {comments
                                        .reverse()
                                        .map((comment, index) => {
                                          console.log(comment.date);
                                          if (
                                            this.props.email == comment.user
                                          ) {
                                            return (
                                              <CommentCard key={index}>
                                                <CardInfo>
                                                  <Name>
                                                    {comment.firstName +
                                                      " " +
                                                      comment.lastName +
                                                      " "}
                                                  </Name>
                                                  <CourseYear>
                                                    Class 2020
                                                  </CourseYear>
                                                  <CommentText>
                                                    {comment.value}
                                                  </CommentText>

                                                  <DeleteButton
                                                    onClick={() =>
                                                      this.deleteComment(
                                                        comment.id,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </DeleteButton>
                                                </CardInfo>
                                              </CommentCard>
                                            );
                                          } else {
                                            return (
                                              <CommentCard key={index}>
                                                <CardInfo>
                                                  <Name>
                                                    {comment.firstName +
                                                      " " +
                                                      comment.lastName +
                                                      " "}
                                                  </Name>
                                                  <CourseYear>
                                                    Class 2020
                                                  </CourseYear>
                                                  <CommentText>
                                                    {comment.value}
                                                  </CommentText>
                                                </CardInfo>
                                              </CommentCard>
                                            );
                                          }
                                        })}
                                    </ScrollableFeed>











*/

/*<Col sm={12} mg={12} lg={4}></Col>*/

// div to Card if default settings needed
/*
<CardAvatar>
<UserImage src={avatar3} alt='SkoteSkote' />
</CardAvatar>*/
// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episode);

const MarkComplete = styled.button`
  width: 100px;
  padding: 20px;
  color: white;
  background-color: #ff4a53;
  border-radius: 10px;
`;

const ContinueButton = styled.button`
  width: 100px;
  height: 40px;
  margin: 5px;
  color: white;
  background-color: #ff4a53;
  border-radius: 10px;
`;

const RewatchButton = styled.button`
  width: 100px;
  height: 40px;
  margin: 5px;
  color: rgba(0, 0, 0, 0.7);
  background-color: #42fb93;
  border-radius: 10px;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 1000px;
`;

const BackImage = styled.img`
  width: 80px;
`;

const PrevImage = styled.img`
  width: 50px;
  margin: 10px;
`;

const PlayerContainer = styled.div`
  padding: 40px;
  background-color: #fffff6;
  border-radius: 10px;
`;

const BouncyDiv = styled.div`1
  animation: 1s ${bounceAnimation};

  background-color: rgb(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  flex-direction: column;
  justify-content: space-evenly;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const Subtitle = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const CongratsText = styled.h1`
  color: white;
  font-size: 20px;
  text-align: center;
`;

const SubtitleText = styled.p`
  color: white;
  font-size: 16px;
  text-align: center;
`;

const ActionButtons = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Test = styled.div`
  border: 1px solid blue;
`;

const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TabContainer = styled.div`
  margin: 20px 0px;
  background-color: white;
  width: 100%;
`;

const ResourceCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ButtonLink = styled.a`
  display: block;
  color: white;
  width: 100%;
`;

const MiniCardContainer = styled.div`
  width: 100%;
  padding: 20px;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  margin-top: 20px;
`;

const Title = styled.h1`
  flex: 1;
`;

const Text = styled.p`
  flex: 1;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
`;

const CloseButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButtonText = styled.h1`
  font-size: 50px;
`;

const LessonTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const LessonTitle = styled.p`
  font-weight: bold;
  font-size: 20px;
`;

const LessonDuration = styled.p``;

const ProgressContainer = styled.div`
  padding: 10px;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;
const NextEpisode = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserImage = styled.img`
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: auto;
`;

const CardInfo = styled.div`
  flex: 2;
`;

const DeleteButton = styled.button`
  border: 0;
  background-color: #eceff2;
  color: #737f91;
  font-weight: bold;
`;

const CommentContainer = styled.div`
  background-color: #c6fef4;
  border-radius: 20px;
  min-height: 100%;

  width: 100%;
  display: flex;
  flex-direction: column;
`;

const CommentLengthContainer = styled.div`
  padding: 20px;
`;

const CommentLength = styled.h1`
  font-size: 20px;
  text-align: center;
  margin: 10px;
`;
const CommentSection = styled.div`
  height: 1000px;
  width: 100%;
  overflow-y: scroll;
`;

const CommentCard = styled.div`
  margin: 20px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  flex-direction: Row;
  padding: 20px;
  background-color: #fff;
`;

const CommentTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const AvatarImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const Name = styled.span`
  margin-left: 10px;
  display: block;
`;

const Badge = styled.span`
  margin-left: 10px;
  background-color: #2ed5be;
  padding: 10px;
  border-radius: 10px;
  color: white;
`;

const CommentText = styled.p``;
const CommentFormSection = styled.div`
  padding: 20px;
  flex: 1;
  border-top: 2px solid #00a0b7;
  border-bottom: 2px solid #00a0b7;
`;

const CommentForm = styled.form``;

const CourseYear = styled.p``;

const TextAreaContainer = styled.div`
  display: block;
  height: 100px;
  border: 1px solid #bebebe;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  margin: 0;
  outline: none;
  border-width: 0;
`;

const SubmitContainer = styled.div`
  padding: 10px;
`;

const SubmitButton = styled.input`
  width: 100%;
`;

const VideoOverlay = styled.div`
  position: absolute;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.4);
`;

const SecondaryContainer = styled.div``;

const SpeedContainer = styled.div``;

const TranscriptContainer = styled.div``;

const MarkContainer = styled.div``;
/*Passing Data via Link


  const currentEpisode = this.props.location.state.currentEpisode

    const currentEpisodeString = currentEpisode.toString()*/
