import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 2 [Your Foundation]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "The “Fosbury Flop” and use of cell phones by the Massai Tribe in Africa are used in Module 2 as examples of what type of change?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Iterative",
        "Incremental",
        "Team-oriented",
        "Transformational",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "In today’s environment, you’re either growing or you’re…dying. There is no standing still. In addition, neither incremental or iterative changes offer enough speed to ‘keep up’. Module 2 uses examples of the “Fosbury Flop” from world high-jump competition and cell phone proliferation around the globe (no longer requiring telephone lines and physical connections) as examples of transformational change. ",
    },
    {
      question:
        "[TRUE or FALSE] Being FALLIBLE means we are capable of making mistakes or being wrong.",
      questionType: "text",
      answerSelectionType: "single",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "Human fallibility means that we are capable of making mistakes and being wrong- ALL of us.",
      point: "12.5",
      answers: ["True", "False"],
      correctAnswer: "1",
    },
    {
      question:
        "In Module 2 you were asked to complete an exercise where you counted the number of times the letter “f” appeared in a statement. The most common [wrong] answer is 3. Why is this the case?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "Our minds are conditioned to overlook the small things ",
        "There isn’t enough time to read the entire statement",
        "The beeping timer causes distraction",
        "Mental stress caused by knowing the statement was going to disappear",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "We learn throughout our lives by making mistakes. For example, we learned how to walk and how to ride a bicycle by learning how not to fall down. In addition, the speed of society and volume of information has conditioned us to overlook the small things. In the “Count the Fs” exercise, the most common answer is “3”. The correct answer is “6”. The most-common wrong answer (“3”) exists because many overlook the three Fs associated with the small word “OF”.",
      point: "12.5",
    },
    {
      question:
        "Which of the following is/are true about “C Players”? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "“C Players” are worthless human beings.",
        "Most who are acting like “C Players” are highly intelligent / highly frustrated team members, able & willing to transform.",
        "The desired way to get rid of a “C Player” is to help them transform into a “B Player” or “A Player”.",
        "If a “C Player” refuses to transform, we must have the courage to ‘show them the door’.",
      ],
      correctAnswer: [2, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Most of us are frustrated with the “C Players” in our midst. At work, there are many exhibiting “C Player” behaviors and attitudes because they are intelligent, and their experiences on the job have led them to become highly discouraged and even disgruntled. In our experience at PPI, most team members exhibiting “C Player” behaviors fall into this category. PPI experience has also revealed that many such team members rapidly transformation into “B Players” or even “A Players” once convinced the Next-Gen approach is “real” on their team / within their organization. A very important ACTION POINT: you must have the courage to get rid of your “C Players”. The desired way is to help them transform. If they refuse to transform however, you must have the courage to ‘cut ‘em loose’. Otherwise, your performance improvement, including your goal of achieving Viral Accountability® and a culture of One Team with One Goal having One Conversation, will hit a wall.",
      point: "12.5",
    },

    {
      question:
        "PPI Precept #2 is: 84 to 94 percent of all mistakes on the job are setup by process, programmatic, or organizational issues. Which ONE of the following best describes the reason for the REMAINING 6-16%?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "roadblocks",
        "fallibility",
        "landmines",
        "incorrect procedures",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "PPI Precept #2 is: 84 to 94 percent of all mistakes on the job are setup by process, programmatic, or organizational issues. The other 6 to 16 percent? Human Fallibility. This is important to understand, especially where so many organizations believe issuing a “Human Performance Tool Kit” will ‘fix’ mistakes on the job. Such “Tools” only address the 6 to 16 percent. ",
      point: "12.5",
    },
  ],
};

export default class Recall2 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall2: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 5) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "4" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
