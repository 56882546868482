import React, { Component } from "react";

import { Container, CardBody, Button } from "reactstrap";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import styled from "styled-components";
import firebase from "firebase/app";
import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";
import Chart from "react-apexcharts";

import "./styles.css";

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variety: 0,
      significance: 0,
      consistency: 0,
      loveConnection: 0,
      contribution: 0,
      consistency: 0,
      growth: 0,
      user: "",
      labMember: false,
      value: "",
      options: {
        chart: {
          type: "bar",
          height: 380,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "South Korea",
            "Canada",
            "United Kingdom",
            "Netherlands",
            "Italy",
            "France",
            "Japan",
            "United States",
            "China",
            "India",
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        title: {
          text: "Custom DataLabels",
          align: "center",
          floating: true,
        },
        subtitle: {
          text: "Category Names as DataLabels inside bars",
          align: "center",
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    this.handleData();
    // Check if User is Logged in
    const fireBaseBackend = getFirebaseBackend();

    if (fireBaseBackend.getAuthenticatedUser() == null) {
      console.log("null");

      // Handle Non Lab Member
    } else {
      console.log("Not Null");
      var user = fireBaseBackend.getAuthenticatedUser().email;

      // Handle Lab Member
      this.setState({ labMember: true, user: user });
      console.log(user);
      const db = firebase.firestore();

      var userRef = db.collection("Progress").doc(user);

      var data = [
        Number(this.props.data[0]),
        Number(this.props.data[1]),
        Number(this.props.data[2]),
        Number(this.props.data[3]),
        Number(this.props.data[4]),
        Number(this.props.data[5]),
        Number(this.props.data[6]),
        Number(this.props.data[7]),
        Number(this.props.data[8]),
        Number(this.props.data[9]),
        Number(this.props.data[10]),
        Number(this.props.data[11]),
        Number(this.props.data[12]),
        Number(this.props.data[13]),
        Number(this.props.data[14]),
        Number(this.props.data[15]),
        Number(this.props.data[16]),
        Number(this.props.data[17]),
        Number(this.props.data[18]),
        Number(this.props.data[19]),
        Number(this.props.data[20]),
        Number(this.props.data[21]),
        Number(this.props.data[22]),
        Number(this.props.data[23]),
        Number(this.props.data[24]),
        Number(this.props.data[25]),
        Number(this.props.data[26]),
        Number(this.props.data[27]),
        Number(this.props.data[28]),
        Number(this.props.data[29]),
        Number(this.props.data[30]),
        Number(this.props.data[31]),
        Number(this.props.data[32]),
        Number(this.props.data[33]),
        Number(this.props.data[34]),
        Number(this.props.data[35]),
        Number(this.props.data[36]),
        Number(this.props.data[37]),
        Number(this.props.data[38]),
        Number(this.props.data[39]),
        Number(this.props.data[40]),
        Number(this.props.data[41]),
        Number(this.props.data[42]),
        Number(this.props.data[43]),
        Number(this.props.data[44]),
        Number(this.props.data[45]),
        Number(this.props.data[46]),
        Number(this.props.data[47]),
        Number(this.props.data[48]),
        Number(this.props.data[49]),
        Number(this.props.data[50]),
        Number(this.props.data[51]),
        Number(this.props.data[52]),
        Number(this.props.data[53]),
        Number(this.props.data[54]),
        Number(this.props.data[55]),
        Number(this.props.data[56]),
        Number(this.props.data[57]),
        Number(this.props.data[58]),
        Number(this.props.data[59]),
        Number(this.props.data[60]),
        Number(this.props.data[61]),
        Number(this.props.data[62]),
        Number(this.props.data[63]),
        Number(this.props.data[64]),
        Number(this.props.data[65]),
        Number(this.props.data[66]),
        Number(this.props.data[67]),
        Number(this.props.data[68]),
        Number(this.props.data[69]),
        Number(this.props.data[70]),
        Number(this.props.data[71]),
        Number(this.props.data[72]),
        Number(this.props.data[73]),
        Number(this.props.data[74]),
        Number(this.props.data[75]),
        Number(this.props.data[76]),
        Number(this.props.data[77]),
        Number(this.props.data[78]),
        Number(this.props.data[79]),
        Number(this.props.data[80]),
        Number(this.props.data[81]),
        Number(this.props.data[82]),
        Number(this.props.data[83]),
      ];

      userRef.set(
        {
          ppnData: data,
        },
        { merge: true }
      );
    }
  }

  handleData = () => {
    // Handle Variety
    var varietyAdded =
      Number(this.props.data[0]) +
      Number(this.props.data[8]) +
      Number(this.props.data[12]) +
      Number(this.props.data[21]) +
      Number(this.props.data[22]) +
      Number(this.props.data[34]) +
      Number(this.props.data[40]) +
      Number(this.props.data[49]) +
      Number(this.props.data[64]) +
      Number(this.props.data[65]) +
      Number(this.props.data[75]) +
      Number(this.props.data[78]) +
      Number(this.props.data[79]) +
      Number(this.props.data[83]);

    // Handle Significance
    var significanceAdded =
      Number(this.props.data[1]) +
      Number(this.props.data[6]) +
      Number(this.props.data[18]) +
      Number(this.props.data[19]) +
      Number(this.props.data[27]) +
      Number(this.props.data[31]) +
      Number(this.props.data[35]) +
      Number(this.props.data[46]) +
      Number(this.props.data[51]) +
      Number(this.props.data[57]) +
      Number(this.props.data[60]) +
      Number(this.props.data[67]) +
      Number(this.props.data[72]) +
      Number(this.props.data[77]);

    // Handle Consistency
    var consistencyAdded =
      Number(this.props.data[2]) +
      Number(this.props.data[5]) +
      Number(this.props.data[11]) +
      Number(this.props.data[16]) +
      Number(this.props.data[26]) +
      Number(this.props.data[28]) +
      Number(this.props.data[37]) +
      Number(this.props.data[38]) +
      Number(this.props.data[43]) +
      Number(this.props.data[54]) +
      Number(this.props.data[55]) +
      Number(this.props.data[62]) +
      Number(this.props.data[70]) +
      Number(this.props.data[81]);

    // Handle Love & Connection
    var loveConnectionAdded =
      Number(this.props.data[3]) +
      Number(this.props.data[10]) +
      Number(this.props.data[14]) +
      Number(this.props.data[24]) +
      Number(this.props.data[30]) +
      Number(this.props.data[33]) +
      Number(this.props.data[42]) +
      Number(this.props.data[45]) +
      Number(this.props.data[47]) +
      Number(this.props.data[52]) +
      Number(this.props.data[58]) +
      Number(this.props.data[69]) +
      Number(this.props.data[73]) +
      Number(this.props.data[82]);

    // Handle Contribution
    var contributionAdded =
      Number(this.props.data[4]) +
      Number(this.props.data[9]) +
      Number(this.props.data[13]) +
      Number(this.props.data[17]) +
      Number(this.props.data[20]) +
      Number(this.props.data[25]) +
      Number(this.props.data[32]) +
      Number(this.props.data[39]) +
      Number(this.props.data[44]) +
      Number(this.props.data[50]) +
      Number(this.props.data[59]) +
      Number(this.props.data[63]) +
      Number(this.props.data[66]) +
      Number(this.props.data[74]);

    // Handle Growth
    var growthAdded =
      Number(this.props.data[7]) +
      Number(this.props.data[15]) +
      Number(this.props.data[23]) +
      Number(this.props.data[29]) +
      Number(this.props.data[36]) +
      Number(this.props.data[41]) +
      Number(this.props.data[48]) +
      Number(this.props.data[53]) +
      Number(this.props.data[56]) +
      Number(this.props.data[61]) +
      Number(this.props.data[68]) +
      Number(this.props.data[71]) +
      Number(this.props.data[76]) +
      Number(this.props.data[80]);

    this.setState({
      variety: varietyAdded,
      significance: significanceAdded,
      consistency: consistencyAdded,
      loveConnection: loveConnectionAdded,
      contribution: contributionAdded,
      growth: growthAdded,
    });
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = (event) => {
    const db = firebase.firestore();
    var data = [
      Number(this.props.data[0]),
      Number(this.props.data[1]),
      Number(this.props.data[2]),
      Number(this.props.data[3]),
      Number(this.props.data[4]),
      Number(this.props.data[5]),
      Number(this.props.data[6]),
      Number(this.props.data[7]),
      Number(this.props.data[8]),
      Number(this.props.data[9]),
      Number(this.props.data[10]),
      Number(this.props.data[11]),
      Number(this.props.data[12]),
      Number(this.props.data[13]),
      Number(this.props.data[14]),
      Number(this.props.data[15]),
      Number(this.props.data[16]),
      Number(this.props.data[17]),
      Number(this.props.data[18]),
      Number(this.props.data[19]),
      Number(this.props.data[20]),
      Number(this.props.data[21]),
      Number(this.props.data[22]),
      Number(this.props.data[23]),
      Number(this.props.data[24]),
      Number(this.props.data[25]),
      Number(this.props.data[26]),
      Number(this.props.data[27]),
      Number(this.props.data[28]),
      Number(this.props.data[29]),
      Number(this.props.data[30]),
      Number(this.props.data[31]),
      Number(this.props.data[32]),
      Number(this.props.data[33]),
      Number(this.props.data[34]),
      Number(this.props.data[35]),
      Number(this.props.data[36]),
      Number(this.props.data[37]),
      Number(this.props.data[38]),
      Number(this.props.data[39]),
      Number(this.props.data[40]),
      Number(this.props.data[41]),
      Number(this.props.data[42]),
      Number(this.props.data[43]),
      Number(this.props.data[44]),
      Number(this.props.data[45]),
      Number(this.props.data[46]),
      Number(this.props.data[47]),
      Number(this.props.data[48]),
      Number(this.props.data[49]),
      Number(this.props.data[50]),
      Number(this.props.data[51]),
      Number(this.props.data[52]),
      Number(this.props.data[53]),
      Number(this.props.data[54]),
      Number(this.props.data[55]),
      Number(this.props.data[56]),
      Number(this.props.data[57]),
      Number(this.props.data[58]),
      Number(this.props.data[59]),
      Number(this.props.data[60]),
      Number(this.props.data[61]),
      Number(this.props.data[62]),
      Number(this.props.data[63]),
      Number(this.props.data[64]),
      Number(this.props.data[65]),
      Number(this.props.data[66]),
      Number(this.props.data[67]),
      Number(this.props.data[68]),
      Number(this.props.data[69]),
      Number(this.props.data[70]),
      Number(this.props.data[71]),
      Number(this.props.data[72]),
      Number(this.props.data[73]),
      Number(this.props.data[74]),
      Number(this.props.data[75]),
      Number(this.props.data[76]),
      Number(this.props.data[77]),
      Number(this.props.data[78]),
      Number(this.props.data[79]),
      Number(this.props.data[80]),
      Number(this.props.data[81]),
      Number(this.props.data[82]),
      Number(this.props.data[83]),
    ];

    db.collection("ppnNonMember")
      .doc(this.state.value)
      .set({
        data: data,
      })
      .then(() => {
        console.log("Document successfully written");
      })
      .catch((error) => {
        console.log("Error wrting document: ", error);
      });

    event.preventDefault();
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <CardBody className="pt-0">
              <ResultsContainer>
                {this.state.labMember ? (
                  <MainResults>
                    <ResultsChart>
                      <p>Lab Member</p>
                      <p>
                        As a Lab Member Your data has been automatically saved
                        and you can access it in the lab if you need to access
                        it again
                      </p>
                      <Chart
                        options={{
                          chart: {
                            type: "bar",
                            height: 380,
                            foreColor: "#fff",
                          },
                          plotOptions: {
                            bar: {
                              barHeight: "100%",
                              distributed: true,
                              horizontal: true,
                              dataLabels: {
                                position: "bottom",
                              },
                            },
                          },
                          colors: [
                            "#33b2df",
                            "#546E7A",
                            "#d4526e",
                            "#13d8aa",
                            "#A5978B",
                            "#2b908f",
                          ],
                          dataLabels: {
                            enabled: true,
                            textAnchor: "start",
                            style: {
                              fontSize: "14px",
                              fontFamily: "Helvetica, Arial, sans-serif",
                              fontWeight: "bold",
                              colors: ["#fff"],
                            },
                            formatter: function (val, opt) {
                              return (
                                // opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                                opt.w.globals.labels[opt.dataPointIndex]
                              );
                            },
                            offsetX: 0,
                            dropShadow: {
                              enabled: false,
                            },
                          },
                          stroke: {
                            width: 1,
                            colors: ["#fff"],
                          },
                          xaxis: {
                            categories: [
                              "Variety",
                              "Significance",
                              "Consistency",
                              "Love & Connection",
                              "Contribution",
                              "Growth",
                            ],
                          },
                          yaxis: {
                            labels: {
                              show: false,
                            },
                          },
                          title: {
                            text: `"Primary Performance Needs Results"`,
                            align: "center",
                            floating: true,
                          },
                          subtitle: {
                            text: `${this.state.user}`,
                            align: "center",
                          },
                          tooltip: {
                            theme: "dark",
                            x: {
                              show: false,
                            },
                            y: {
                              title: {
                                formatter: function () {
                                  return "";
                                },
                              },
                            },
                          },
                          legend: {
                            show: false,
                          },
                        }}
                        series={[
                          {
                            data: [
                              this.state.variety,
                              this.state.significance,
                              this.state.consistency,
                              this.state.loveConnection,
                              this.state.contribution,
                              this.state.growth,
                            ],
                          },
                        ]}
                        type="bar"
                        width={500}
                        height={320}
                      />
                    </ResultsChart>
                    <ResultsParagraph>
                      <p>Hello</p>
                    </ResultsParagraph>
                  </MainResults>
                ) : (
                  <div>
                    <p>Non Lab Member</p>
                    <p>Would you like us to save your survey data?</p>
                    <form onSubmit={this.handleSubmit}>
                      <label>
                        <input
                          type="text"
                          value={this.state.value}
                          onChange={this.handleChange}
                        />
                      </label>
                      <input type="submit" value="Submit" />
                    </form>
                  </div>
                )}
              </ResultsContainer>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Results);

const ResultsContainer = styled.div`
  border: 1px solid #e4eaec;
  padding: 20px;
`;

const MainResults = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResultsChart = styled.div``;

const ResultsParagraph = styled.div``;
