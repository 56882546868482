import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file

import firebase from "firebase/app";

import { initFirebaseBackend } from "./helpers/authUtils";

import { getFirebaseBackend } from "./helpers/authUtils";

const firebaseConfig = {
  apiKey: "AIzaSyCdEGfvPSvGDmE63Evh5RlsSrTGuS1ohz4",
  authDomain: "ppilabv2.firebaseapp.com",
  databaseURL: "https://ppilabv2.firebaseio.com",
  projectId: "ppilabv2",
  storageBucket: "ppilabv2.appspot.com",
  messagingSenderId: "994704346529",
  appId: "1:994704346529:web:4c70b0159ecdeb9c1e0fe0",
  measurementId: "G-W6ZZZ7ZWS4",
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "email", loading: false };
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  componentWillMount() {
    console.log("1");

    // Get Logged in User and send to Redux

    const fireBaseBackend = getFirebaseBackend();

    if (fireBaseBackend.getAuthenticatedUser() == null) {
      console.log("FIREBASE LOGGED IN USER IS Null");
    } else {
      console.log(this.props.email);
    }

    console.log("END 1");
  }

  componentDidMount() {
    console.log("3");

    console.log("Redux User Below");
    console.log(this.props.email);

    console.log("END 3");
  }

  getUserEmail() {
    const fireBaseBackend = getFirebaseBackend();

    if (fireBaseBackend.getAuthenticatedUser() == null) {
      console.log("Null");
    } else {
      // Get Currently Logged In User
      console.log("Not Null");

      var user = fireBaseBackend.getAuthenticatedUser().email;

      console.log("Currently Logged in User Below");

      console.log(user);
      if (user != null) {
        this.props.updateEmail(user);
      } else {
        console.log("User is Null");
      }

      this.setState({ email: user }, () => {
        this.getUserData();
      });
    }
  }

  componentDidUpdate() {}

  getUserData() {
    // Get Firestore Database User Data
    console.log("Running Email");
    console.log(this.state.email);

    const db = firebase.firestore();
    let cityRef = db.collection("Users").doc(this.state.email);
    let getDoc = cityRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log(doc.data());
          let firstName = doc.data().firstName;
          let lastName = doc.data().lastName;
          let plan = doc.data().plan;
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });

    let usersRef = db.collection("Users");
    let allUsers = usersRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          //   console.log(doc.id, '=>', doc.data())
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );

    console.log("2");

    console.log("Redux User Below");
    console.log(this.props.email);

    console.log("End 2");
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(App);
