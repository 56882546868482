import React, { Component, Image } from 'react'

import { Row, Col, Card, CardBody, CardImg, Container } from 'reactstrap'
import { Link } from 'react-router-dom'

import avatar1 from '../../assets/images/users/avatar-1.jpg'
import img1 from '../../assets/images/small/img-1.jpg'
import profileImg from '../../assets/images/profile-img.png'

import img4 from '../../assets/images/small/img-4.jpg'

import { connect } from 'react-redux'

import firebase from 'firebase/app'

import styled from 'styled-components'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

class UpcomingEvents extends Component {
  constructor (props) {
    super(props)
    this.state = { data: [] }
  }

  componentDidMount () {
    const db = firebase.firestore()
    let citiesRef = db.collection('UpcomingEvents')
    let allCities = citiesRef
      .get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          console.log(doc.data())

          var currentData = this.state.data

          currentData.push(doc.data())

          this.setState({ data: currentData })
        })
      })
      .catch(err => {
        console.log('Error getting documents', err)
      })
  }

  render () {
    const data = this.state.data

    return (
      <React.Fragment>
        <div className='page-content'>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title='PPILAB' breadcrumbItem='Upcoming Events' />

            <CardBody className='pt-0'>
              <Row>
                {data.map((event, index) => {
                  return (
                    <Col key={index} mg={12} xl={12}>
                      <Card
                        style={{
                          overflow: 'hidden',
                          borderRadius: 20,
                          display: 'flex',
                          flexDirection: 'row'
                        }}
                      >
                        <CardContainer>
                          <DateContainer
                            style={{ backgroundColor: event.color }}
                          >
                            <DateDay>{event.date}</DateDay>
                            <DateYear>{event.year}</DateYear>
                          </DateContainer>

                          <CardDetails>
                            <CTitle className='mt-0'>{event.title}</CTitle>
                            <CText>{event.description}</CText>
                          </CardDetails>

                          <CardDetailsButton>
                            <Link
                              to='/upcomingevents'
                              className='btn btn-primary waves-effect waves-light'
                            >
                              Register Now
                            </Link>
                          </CardDetailsButton>
                        </CardContainer>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps (state) {
  return { ...state.User }
}

export default connect(mapStateToProps)(UpcomingEvents)

const CardInfo = styled.div`
  display: flex;
  align-items: center;
`

const CardButton = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
`
const CardTitle = styled.h1`
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const CTitle = styled.h1``

const CText = styled.p``

const ButtonTitle = styled.div`
  display: flex;
  margin-right: 0;
`

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const DateContainer = styled.div`
  padding: 10px;
  flex-grow: 1;
`

const DateDay = styled.p`
  text-align: center;
  color: white;
  font-size: 25px;
`

const DateYear = styled.p`
  text-align: center;
  color: white;
  font-size: 25px;
`

const CardDetails = styled.div`
  justify-content: center;
  align-items: center;

  padding: 10px;
  flex-grow: 2;
`

const CardDetailsButton = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 10px;
  flex-grow: 1;
`
