import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 8 [Code of Honor]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "Identify the choice below that correctly completes this attribute of a great team: Full commitment by all members-",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "team first, mission second, individual third",
        "mission first, team second, individual third.",
        "individual first, mission second, team third",
        "team first, individual second, mission third",
      ],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question:
        "Which of the following statements are TRUE regarding development of a Code of Honor within an organization? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "Human fallibility means that we are capable of making mistakes and being wrong- ALL of us.",
      point: "12.5",
      answers: [
        "A Code of Honor is developed at the natural work team level.",
        "The most senior member of the team has equal say with the most junior member of the team.",
        "For any item to go onto the Code, ALL team members must be willing and able to 100% commit to it. ",
        "If there are five members on a team, there will be ten sets of verbal person-to-person commitments following Code development.",
      ],
      correctAnswer: [1, 2, 3, 4],
    },
    {
      question:
        "[TRUE or FALSE] The Code of Honor essentially allows the members of a team to set their own expectations.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "Relative to the Code of Honor, what does the term “Mind the Gap” refer to?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "The “gaps” or spaces between the “speedboats” (different Codes of different work teams).",
        "The “holes” existing between the different elements of a given work team’s Code.",
        "Break something into smaller units and you’re bound to diminish its effectiveness.",
        "Speak when you’re angry and you’ll make the greatest speech you’ll ever regret.",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
  ],
};

export default class Recall8 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall8: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 4) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "6" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
