import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import { Link } from "react-router-dom";

import img1 from "../../../assets/images/small/img-1.jpg";
import fast from "../../../assets/images/small/20&.png";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../../helpers/authUtils";

import ScrollContainer from "react-indiana-drag-scroll";

import styled from "styled-components";
//Import Breadcrumb

import { device } from "../device.js";
import "./styles.css";

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodes: [],
      fromNotifications: "Please Reload",
      episode0011Progress: 0,
      episode0012Progress: 0,
      episode0013Progress: 0,
      episode0014Progress: 0,
      episode0015Progress: 0,
      episode0016Progress: 0,
      episode0017Progress: 0,
      episode0018Progress: 0,
      episode0019Progress: 0,
      episode0110Progress: 0,
      episode0111Progress: 0,
      episode0112Progress: 0,
      episode0011Locked: true,
      episode0012Locked: true,
      episode0013Locked: true,
      episode0014Locked: true,
      episode0015Locked: true,
      episode0016Locked: true,
      episode0017Locked: true,
      episode0018Locked: true,
      episode0019Locked: true,
      episode0110Locked: true,
      episode0111Locked: true,
      episode0112Locked: true,
    };
  }

  componentDidMount() {
    this.getEpisodes();
    this.getUserData();
    this.getStatus();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getEpisodes() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/Principal/Content/Content");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.episodes;

          currentData.push(doc.data());

          this.setState({ episodes: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.getPrincipalEpisodesProgress();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("ID HERE");
          console.log(doc.data());
          this.setState({
            episode0011Locked: doc.data().episode0011.locked,
            episode0012Locked: doc.data().episode0012.locked,
            episode0013Locked: doc.data().episode0013.locked,
            episode0014Locked: doc.data().episode0014.locked,
            episode0015Locked: doc.data().episode0015.locked,
            episode0016Locked: doc.data().episode0016.locked,
            episode0017Locked: doc.data().episode0017.locked,
            episode0018Locked: doc.data().episode0018.locked,
            episode0019Locked: doc.data().episode0019.locked,
            episode0110Locked: doc.data().episode0110.locked,
            episode0111Locked: doc.data().episode0111.locked,
            episode0112Locked: doc.data().episode0112.locked,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getPrincipalEpisodesProgress() {
    var data = this.state.data;
    console.log("DATA HERE");

    this.setState({
      episode0011Progress: Math.round(data[0].episode0011.progress),
      episode0012Progress: Math.round(data[0].episode0012.progress),
      episode0013Progress: Math.round(data[0].episode0013.progress),
      episode0014Progress: Math.round(data[0].episode0014.progress),
      episode0015Progress: Math.round(data[0].episode0015.progress),
      episode0016Progress: Math.round(data[0].episode0016.progress),
      episode0017Progress: Math.round(data[0].episode0017.progress),
      episode0018Progress: Math.round(data[0].episode0018.progress),
      episode0019Progress: Math.round(data[0].episode0019.progress),
      episode0110Progress: Math.round(data[0].episode0110.progress),
      episode0111Progress: Math.round(data[0].episode0111.progress),
      episode0112Progress: Math.round(data[0].episode0112.progress),
    });
  }

  render() {
    const { open } = this.state;
    const episodes = this.state.episodes;
    const episode0011Locked = this.state.episode0011Locked;
    const episode0012Locked = this.state.episode0012Locked;
    const episode0013Locked = this.state.episode0013Locked;
    const episode0014Locked = this.state.episode0014Locked;
    const episode0015Locked = this.state.episode0015Locked;
    const episode0016Locked = this.state.episode0016Locked;
    const episode0017Locked = this.state.episode0017Locked;
    const episode0018Locked = this.state.episode0018Locked;
    const episode0019Locked = this.state.episode0019Locked;
    const episode0110Locked = this.state.episode0110Locked;
    const episode0111Locked = this.state.episode0111Locked;
    const episode0112Locked = this.state.episode0112Locked;

    var percentage = 8;
    var item = <h1>Loading</h1>;

    return (
      <React.Fragment>
        <Row>
          <CardContainer>
            {episodes.map((episode, index) => {
              if (episode.currentEpisode === "/episode0011") {
                var percentage = this.state.episode0011Progress;
                var locked = episode0011Locked;
              } else if (episode.currentEpisode === "/episode0012") {
                var percentage = this.state.episode0012Progress;
                var locked = episode0012Locked;
              } else if (episode.currentEpisode === "/episode0013") {
                var percentage = this.state.episode0013Progress;
                var locked = episode0013Locked;
              } else if (episode.currentEpisode === "/episode0014") {
                var percentage = this.state.episode0014Progress;
                var locked = episode0014Locked;
              } else if (episode.currentEpisode === "/episode0015") {
                var percentage = this.state.episode0015Progress;
                var locked = episode0015Locked;
              } else if (episode.currentEpisode === "/episode0016") {
                var percentage = this.state.episode0016Progress;
                var locked = episode0016Locked;
              } else if (episode.currentEpisode === "/episode0017") {
                var percentage = this.state.episode0017Progress;
                var locked = episode0017Locked;
              } else if (episode.currentEpisode === "/episode0018") {
                var percentage = this.state.episode0018Progress;
                var locked = episode0018Locked;
              } else if (episode.currentEpisode === "/episode0019") {
                var percentage = this.state.episode0019Progress;
                var locked = episode0019Locked;
              } else if (episode.currentEpisode === "/episode0110") {
                var percentage = this.state.episode0110Progress;
                var locked = episode0110Locked;
              } else if (episode.currentEpisode === "/episode0111") {
                var percentage = this.state.episode0111Progress;
                var locked = episode0111Locked;
              } else if (episode.currentEpisode === "/episode0112") {
                var percentage = this.state.episode0112Progress;
                var locked = episode0112Locked;
              }

              if (episode.fast === true) {
                var fast = (
                  <FastContainer>
                    <Fast>
                      <box-icon
                        color="#f70c2e"
                        id="fast"
                        name="star"
                        type="solid"
                      ></box-icon>
                      <FastText>20%</FastText>
                    </Fast>
                  </FastContainer>
                );
              } else {
                var fast = <div></div>;
              }

              if (locked === true) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#ff8b00"
                        id="lock"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <ProgressContainer>
                      {percentage === 100 ? (
                        <CheckContainer>
                          <box-icon
                            size="lg"
                            color="#a3ff03"
                            name="check-circle"
                            type="solid"
                          ></box-icon>
                        </CheckContainer>
                      ) : (
                        <CircularProgressbar
                          style={{
                            width: 100,
                            position: "absolute",
                            display: "block",
                          }}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "30px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#ff8b00`,
                            textColor: `#ff8b00`,
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                          value={percentage}
                          text={`${percentage}%`}
                        />
                      )}
                    </ProgressContainer>
                    <Duration>
                      <TimeIcon>
                        <box-icon type="solid" name="time"></box-icon>
                      </TimeIcon>
                      <TextContainer>
                        <Text>{episode.duration}</Text>
                      </TextContainer>
                    </Duration>
                    <BottomContainer>
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          <Link
                            to={{
                              pathname: `${episode.currentEpisode}`,
                            }}
                            style={{ color: "#FFF" }}
                          >
                            Watch
                          </Link>
                        </Button>
                      </ButtonContainer>
                      {fast}
                    </BottomContainer>
                  </EpisodeCard>
                );
              }
              {
                return item;
              }
            })}
          </CardContainer>
        </Row>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episodes);

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FastContainer = styled.div`
  position: relative;
`;

const Fast = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileS} {
    width: 40px;
  height: 40px;
    margin-bottom:2px ;
  }
`;

const FastText = styled.p`
  position: absolute;
  color: white;
  font-size: 15px;
  border:1px solid green;

    @media ${device.mobileS} {
 display:none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #ff8b00;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

@media ${device.mobileS} {
    width:160px;
    height:180px;
    margin-bottom:2px ;
  }

  @media ${device.mobileM} {
     width:190px;
    height:200px;
  }

  @media ${device.mobileL} {
    width:225px;
    height:250px;
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }

`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin:0;
  @media ${device.mobileS} {
    font-size:11px;
  }
  @media ${device.mobileL} {
    font-size:15px;
  }
   @media ${device.tablet} {
    font-size:17px;
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    display:none;
  }

   @media ${device.mobileL} {
   display:flex;
  }
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media ${device.mobileS} {
    width:70px;
    height:70px;
  }
  @media ${device.mobileL} {
    width: 100px;
  height: 100px;
  }
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
