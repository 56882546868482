import React, { Component } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";

import { Link } from "react-router-dom";

import firebase from "firebase/app";
import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import { connect } from "react-redux";
import FileUploader from "react-firebase-file-uploader";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styled from "styled-components";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./styles.css";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "Email",
      first: "First Name",
      last: "Last Name",
      password: "New Password Here",
      intro: 0,
      principal: 0,
      coach: 0,
      synthesizer: 0,
      maestro: 0,
      total: 0,
      avatar: "filename",
      progress: 0,
      isUploading: false,
      username: "user",
      alert: false,
      alert2: false,
      alertError: false,
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeFirst = this.handleChangeFirst.bind(this);
    this.handleChangeLast = this.handleChangeLast.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
  }

  componentDidMount() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    this.setState({ username: user });
    this.getProgress();
  }

  handleUploadSuccess = (filename) => {
    const db = firebase.firestore();

    var user = this.state.username;

    console.log(filename);
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) =>
        this.setState({ avatarURL: url }, () => {
          console.log(url);
          this.setState({
            avatar: url,
            progress: 100,
            isUploading: false,
            username: user,
          });

          let updateNested = db.collection("Progress").doc(user).update({
            avatar: url,
          });
        })
      );
  };

  getProgress = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(doc.data());
          var email = doc.data().email;
          var first = doc.data().firstName;
          var last = doc.data().lastName;
          var avatar = doc.data().avatar;

          var intro = doc.data().stageProgress.intro;
          var principal = doc.data().stageProgress.principal;
          var coach = doc.data().stageProgress.coach;
          var synthesizer = doc.data().stageProgress.synthesizer;
          var maestro = doc.data().stageProgress.maestro;
          var total = doc.data().stageProgress.total;

          this.setState({
            email: email,
            first: first,
            last: last,
            password: "",
            avatar: avatar,
            intro: intro,
            principal: principal,
            coach: coach,
            synthesizer: synthesizer,
            maestro: maestro,
            total: total,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  deleteProfileImage = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    var defaultAvatar =
      "https://firebasestorage.googleapis.com/v0/b/ppilabv2.appspot.com/o/images%2F0a5036b0-75b2-487c-8dac-673f150f36bb.jpg?alt=media&token=585b234b-fb33-4200-9294-679a7f6ddad4";

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    this.setState({ avatar: defaultAvatar }, () => {
      var setWithMerge = docRef.set(
        {
          avatar: defaultAvatar,
        },
        { merge: true }
      );
    });
  };

  componentDidUpdate() {
    console.log("Avatar url here " + this.state.avatar);
  }

  handleChangeEmail(event) {}
  handleChangeFirst(event) {
    this.setState({ first: event.target.value });
  }
  handleChangeLast(event) {
    this.setState({ last: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }
  handleChangeUsername = (event) =>
    this.setState({ username: event.target.value });

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });

  handleProgress = (progress) => this.setState({ progress });

  handleUploadError = (error) => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleSubmit(event) {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var userRef = db.collection("Progress").doc(user);

    var setWithMerge = userRef.set(
      {
        firstName: this.state.first,
        lastName: this.state.last,
      },
      { merge: true }
    );
    this.setState({ alert: true });
    event.preventDefault();
  }

  handleSubmit2() {
    console.log("Calling Submit 2");
    const fireBaseBackend = getFirebaseBackend();

    var emailAddress = fireBaseBackend.getAuthenticatedUser().email;

    var auth = firebase.auth();

    auth
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        // Email sent.
        console.log("Email Sent");
        this.setState({ alert2: true });
      })

      .catch((error) => {
        // An error happened.
        this.setState({ alertError: true });
        console.log(error);
      });
  }

  render() {
    var storage = firebase.storage();

    var user = this.state.username;

    var avatar = this.state.avatar;
    var email = this.state.email;
    console.log(avatar);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="PPILAB" breadcrumbItem="Profile" />

            <Row>
              <ProfileContainer>
                <ProfileDetails>
                  <ProfileImageContainer>
                    <ProfileImage src={avatar}></ProfileImage>

                    <ProfileImageInfo>
                      <ProfileImageText>
                        <h1>
                          {this.state.first + " "} {this.state.last}
                        </h1>
                        <p>{this.state.email}</p>
                        <p>JPG,PNG or GIF only</p>
                      </ProfileImageText>
                      <ProfileImageButtons>
                        <ProfileUploadButton>
                          {this.state.isUploading && (
                            <p>Progress: {this.state.progress}</p>
                          )}
                          <ProfileUploadButton>
                            UPLOAD NEW
                            <FileUploader
                              hidden
                              accept="image/*"
                              filename={(file) => user}
                              name="avatar"
                              storageRef={firebase.storage().ref("images")}
                              onUploadStart={this.handleUploadStart}
                              onUploadError={this.handleUploadError}
                              onUploadSuccess={this.handleUploadSuccess}
                              onProgress={this.handleProgress}
                            />
                          </ProfileUploadButton>
                        </ProfileUploadButton>
                        <ProfileDeleteButton onClick={this.deleteProfileImage}>
                          DELETE
                        </ProfileDeleteButton>
                      </ProfileImageButtons>
                    </ProfileImageInfo>
                  </ProfileImageContainer>
                </ProfileDetails>
              </ProfileContainer>
              <ProfileProgressContainer>
                <ProfileProgressTitle>Course Progress</ProfileProgressTitle>
                <ProfileProgress>
                  <IntroProgress>
                    <ProgressTitle>Intro</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        id="circle"
                        value={this.state.intro}
                        text={`${this.state.intro}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `#2ed5bd`,
                          textColor: `#2ed5bd`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                  <IntroProgress>
                    <ProgressTitle>Principal</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        value={this.state.principal}
                        text={`${this.state.principal}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `rgb(255,139,0)`,
                          textColor: `#ff8b00`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                  <IntroProgress>
                    <ProgressTitle>Coach</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        value={this.state.coach}
                        text={`${this.state.coach}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `#f70c2e`,
                          textColor: `#f70c2e`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                  <IntroProgress>
                    <ProgressTitle>Synthesizer</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        value={this.state.synthesizer}
                        text={`${this.state.synthesizer}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `#10029c`,
                          textColor: `#10029c`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                  <IntroProgress>
                    <ProgressTitle>Maestro</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        value={this.state.maestro}
                        text={`${this.state.maestro}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `#ff4a52`,
                          textColor: `#ff4a52`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                  <IntroProgress>
                    <ProgressTitle>Overall</ProgressTitle>
                    <ProgressContainer>
                      <CircularProgressbar
                        className="progressCircle"
                        value={this.state.total}
                        text={`${this.state.total}%`}
                        styles={buildStyles({
                          // Rotation of path and trail, in number of turns (0-1)
                          rotation: 0,

                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",

                          // Text size
                          textSize: "30px",

                          // How long animation takes to go from one percentage to another, in seconds
                          pathTransitionDuration: 0.5,

                          // Can specify path transition in more detail, or remove it entirely
                          // pathTransition: 'none',

                          // Colors
                          pathColor: `#58c4de`,
                          textColor: `#58c4de`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </ProgressContainer>
                  </IntroProgress>
                </ProfileProgress>
              </ProfileProgressContainer>

              <AccountContainer>
                <AccountDetailsContainer>
                  <h1>Account Details</h1>
                  <hr></hr>
                  <MainForm onSubmit={this.handleSubmit}>
                    <FormLabel>Email Address</FormLabel>
                    <p>*Contact Support to change your email address</p>

                    <FormInput
                      type="text"
                      placeholder={email}
                      value={email}
                      onChange={this.handleChangeEmail}
                    />
                    <br />
                    <FormLabel>First Name</FormLabel>
                    <br />
                    <FormInput
                      type="text"
                      value={this.state.first || ""}
                      onChange={this.handleChangeFirst}
                      placeholder="PlaceHolder Here"
                    />
                    <br />
                    <FormLabel>Last Name</FormLabel>
                    <br />
                    <FormInput
                      type="text"
                      value={this.state.last || ""}
                      onChange={this.handleChangeLast}
                      placeholder="PlaceHolder Here"
                    />

                    <br />
                    {this.state.alert ? (
                      <Alert color="success">
                        <strong>Successfully Updated!</strong>
                      </Alert>
                    ) : (
                      <div></div>
                    )}
                    {this.state.alertError ? (
                      <Alert color="danger">
                        <strong>An Error Has Ocurred</strong>
                      </Alert>
                    ) : (
                      <div></div>
                    )}
                    <FormSubmitContainer>
                      <FormSubmitButton
                        type="submit"
                        value="SAVE ACCOUNT CHANGES"
                      />
                    </FormSubmitContainer>
                  </MainForm>
                </AccountDetailsContainer>
                <ChangePasswordContainer>
                  <h1>Change Password</h1>
                  <hr></hr>
                  <p>We will send a password reset to your email at </p>
                  <h4>{email}</h4>
                  {this.state.alert2 ? (
                    <Alert color="info">
                      <strong>Email Sent - Please Check Your Email</strong>
                    </Alert>
                  ) : (
                    <div></div>
                  )}
                  {this.state.alertError ? (
                    <Alert color="danger">
                      <strong>An Error Has Ocurred</strong>
                    </Alert>
                  ) : (
                    <div></div>
                  )}

                  <br />
                  <FormSubmitContainer>
                    <RequestPasswordButton
                      onClick={() => {
                        this.handleSubmit2();
                      }}
                    >
                      REQUEST PASSWORD RESET
                    </RequestPasswordButton>
                  </FormSubmitContainer>
                </ChangePasswordContainer>
              </AccountContainer>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Profile);

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`;
const ProfileDetails = styled.div`
  margin: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  background-color: gray;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  flex: 1;
`;

const ProfileImageInfo = styled.div`
  flex: 3;
  margin-left: 10px;
`;

const ProfileImageText = styled.div``;

const ProfileImageButtons = styled.div``;

const ProfileUploadButton = styled.label``;

const ProfileDeleteButton = styled.button`
  background-color: #f8f8fb;
  margin-left: 10px;
  font-weight: 500;
  border: 0px;
`;

const ProfileProgressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ProfileProgress = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: space-between;
  margin: 10px;

  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ProfileProgressTitle = styled.h1`
  margin-left: 20px;
`;
const ProgressTitle = styled.h1`
  font-size: 18px;
`;
const IntroProgress = styled.div`
  border: 1px solid #e4eaec;
  border-radius: 20px;
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
`;

const ProgressContainer = styled.div`
  width: 100%;
`;

const AccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`;

const AccountDetailsContainer = styled.div`
  margin: 10px;
  padding: 10px;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ChangePasswordContainer = styled.div`
  margin: 10px;
  padding: 10px;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const MainForm = styled.form`
  width: 350px;
`;

const FormLabel = styled.label`
  margin-top: 20px;
`;

const FormInput = styled.input`
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
  width: 100%;
  height: 40px;
  padding: 10px;
`;

const FormSubmitContainer = styled.div`
  margin-top: 20px;
`;

const FormSubmitButton = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
`;
const RequestPasswordButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
`;
