import React, { Component } from 'react'

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle
} from 'reactstrap'

import { Link } from 'react-router-dom'

import img1 from '../../../assets/images/small/img-1.jpg'

import { connect } from 'react-redux'

import firebase from 'firebase/app'

import ScrollContainer from 'react-indiana-drag-scroll'
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2
} from 'react-html-parser'

import styled from 'styled-components'
//Import Breadcrumb

import { device } from "../device.js";

class Overview extends Component {
  constructor (props) {
    super(props)
    this.state = { open: false, data: [], overview: '' }
  }

  componentDidMount () {
    this.getOverview()
  }

  removeBodyCss () {
    document.body.classList.add('no_paddings')
  }

  onOpenModal = id => {
    this.setState({
      open: {
        [id]: true
      }
    })
  }

  onCloseModal = id => {
    this.setState({
      open: {
        [id]: false
      }
    })
  }

  getOverview () {
    const db = firebase.firestore()
    let overviewRef = db.collection('/Courses/001/00-Intro').doc('Overview')
    let getDoc = overviewRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log('No such document!')
        } else {
          console.log(doc.data().overview)

          this.setState({ overview: doc.data().overview })
        }
      })
      .catch(err => {
        console.log('Error getting document', err)
      })
  }

  render () {
    const { open } = this.state
    const data = this.state.data
    const overview = this.state.overview
    return (
      <React.Fragment>
        <Row>
          <Col lg='12'>
            <CardContainer>
              <h1>Overview</h1>
              <hr></hr>
              {ReactHtmlParser(overview)}
            </CardContainer>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

function mapStateToProps (state) {
  return { ...state.User }
}

export default connect(mapStateToProps)(Overview)

const CardContainer = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    display:none;

  }

  @media ${device.mobileM} {
 
  }

  @media ${device.mobileL} {
   display:block;
   
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`
