import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import img1 from "../../assets/images/small/img-1.jpg";
import profileImg from "../../assets/images/profile-img.png";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import styled from "styled-components";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "./styles.css";

class LeaderWorksheetsMini extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      LAB00: true,
      LAB01: true,
      LAB02: true,
      LAB03: true,
      LAB04: true,
      LAB05: true,
      LAB06: true,
      LAB07: true,
      LAB08: true,
      LAB09: true,
      LAB10: true,
      LAB11: true,
      LAB12: true,
      LAB13: true,
      LAB14: true,
      LAB15: true,
      LAB16: true,
      LAB17: true,
      LAB18: true,
      LAB19: true,
      LAB20: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  getManualStatus = () => {
    console.log("GET MANUAL STATUS");

    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().JumpStartPack);
          this.setState({
            LAB00: doc.data().LabWorksheets.LAB00,
            LAB01: doc.data().LabWorksheets.LAB01,
            LAB02: doc.data().LabWorksheets.LAB02,
            LAB03: doc.data().LabWorksheets.LAB03,
            LAB04: doc.data().LabWorksheets.LAB04,
            LAB05: doc.data().LabWorksheets.LAB05,
            LAB06: doc.data().LabWorksheets.LAB06,
            LAB07: doc.data().LabWorksheets.LAB07,
            LAB08: doc.data().LabWorksheets.LAB08,
            LAB09: doc.data().LabWorksheets.LAB09,
            LAB10: doc.data().LabWorksheets.LAB10,
            LAB11: doc.data().LabWorksheets.LAB11,
            LAB12: doc.data().LabWorksheets.LAB12,
            LAB13: doc.data().LabWorksheets.LAB13,
            LAB14: doc.data().LabWorksheets.LAB14,
            LAB15: doc.data().LabWorksheets.LAB15,
            LAB16: doc.data().LabWorksheets.LAB16,
            LAB17: doc.data().LabWorksheets.LAB17,
            LAB18: doc.data().LabWorksheets.LAB18,
            LAB19: doc.data().LabWorksheets.LAB19,
            LAB20: doc.data().LabWorksheets.LAB20,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db
      .collection("/Courses/001/00-Intro/Resources/Resources")
      .limit(5);
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;
    var LAB00 = this.state.LAB00;
    var LAB01 = this.state.LAB01;
    var LAB02 = this.state.LAB02;
    var LAB03 = this.state.LAB03;
    var LAB04 = this.state.LAB04;
    var LAB05 = this.state.LAB05;
    var LAB06 = this.state.LAB06;
    var LAB07 = this.state.LAB07;
    var LAB08 = this.state.LAB08;
    var LAB09 = this.state.LAB09;
    var LAB10 = this.state.LAB10;
    var LAB11 = this.state.LAB11;
    var LAB12 = this.state.LAB12;
    var LAB13 = this.state.LAB13;
    var LAB14 = this.state.LAB14;
    var LAB15 = this.state.LAB15;
    var LAB16 = this.state.LAB16;
    var LAB17 = this.state.LAB17;
    var LAB18 = this.state.LAB18;
    var LAB19 = this.state.LAB19;
    var LAB20 = this.state.LAB20;

    var render = <p>Locked</p>;

    return (
      <MainContainer>
        <CardBody className="pt-0">
          <CardInfo>
            <CardTitle>
              <MainTitle>Leader Worksheets</MainTitle>
              <MainText>
                Self-discovery, self-awareness, and concept development for the
                Next-Gen LEADER
              </MainText>
            </CardTitle>
            <CardButton>
              <ButtonTitle>
                <Link
                  to="/leaderworksheets"
                  className="btn btn-primary waves-effect waves-light"
                  style={{ fontSize: 18, backgroundColor: "#ff8b00" }}
                >
                  View All Leader Worksheets
                </Link>
              </ButtonTitle>
            </CardButton>
          </CardInfo>

          <Row>
            {data.map((course, index) => {
              if (course.id === "000") {
                var locked = LAB00;
              } else if (course.id === "001") {
                var locked = LAB01;
              } else if (course.id === "002") {
                var locked = LAB02;
              } else if (course.id === "003") {
                var locked = LAB03;
              } else if (course.id === "004") {
                var locked = LAB04;
              } else if (course.id === "005") {
                var locked = LAB05;
              } else if (course.id === "006") {
                var locked = LAB06;
              } else if (course.id === "007") {
                var locked = LAB07;
              } else if (course.id === "008") {
                var locked = LAB08;
              } else if (course.id === "009") {
                var locked = LAB09;
              } else if (course.id === "010") {
                var locked = LAB10;
              } else if (course.id === "011") {
                var locked = LAB11;
              } else if (course.id === "012") {
                var locked = LAB12;
              } else if (course.id === "013") {
                var locked = LAB13;
              } else if (course.id === "014") {
                var locked = LAB14;
              } else if (course.id === "015") {
                var locked = LAB15;
              } else if (course.id === "016") {
                var locked = LAB16;
              } else if (course.id === "017") {
                var locked = LAB17;
              } else if (course.id === "018") {
                var locked = LAB18;
              } else if (course.id === "019") {
                var locked = LAB19;
              } else if (course.id === "020") {
                var locked = LAB20;
              }

              if (locked === true) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#ff8b00"
                        id="icon"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                render = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{course.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#ff8b00"
                        id="icon"
                        type="solid"
                        name="spreadsheet"
                      ></box-icon>
                    </LockContainer>

                    {course.external ? (
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          <a
                            href={course.url}
                            style={{ color: "#FFF" }}
                            target="_blank"
                          >
                            {course.buttonTitle}
                          </a>
                        </Button>
                      </ButtonContainer>
                    ) : (
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                        >
                          <Link to={course.url} style={{ color: "#FFF" }}>
                            {course.buttonTitle}
                          </Link>
                        </Button>
                      </ButtonContainer>
                    )}
                  </EpisodeCard>
                );
              }

              return render;
            })}
          </Row>
        </CardBody>
      </MainContainer>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(LeaderWorksheetsMini);

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 22px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;

const MainContainer = styled.div`
  margin-top: 0px;
  padding: 20px;
`;
