import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 5 [Understanding Yourself & Others]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "Considering the definition, WHICH of the following would be classified as a mistake/error? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "While transferring data, I enter “0.377”, rather than “0.733” ",
        "After getting laid off, I pack my stuff, have lunch, and on my way out, I tell my boss to “Pack Sand!”",
        "While keying a WhatsApp message on my phone, my left thumb hits “A” instead of “S” ",
        "While preparing dinner, my knife slips and nicks the side of my finger.",
      ],
      correctAnswer: [1, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
    },
    {
      question: "WHICH ONE of the following is SKILL-BASED Performance?",
      questionType: "text",
      answerSelectionType: "single",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "Human fallibility means that we are capable of making mistakes and being wrong- ALL of us.",
      point: "12.5",
      answers: [
        "Writing your name with your alternate hand",
        "Using instructions provided in a YouTube video",
        "Following GPS in your car",
        "Eating a bowl of cereal",
      ],
      correctAnswer: "4",
    },
    {
      question:
        "[TRUE or FALSE] Within the scheme of Defense in Depth (the “Swiss Cheese Model”), the final layer of defense is Behavioral.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "Why are six of the Error Elimination Tools™ collectively known as the “Power Tools”? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "These Error Elimination Tools™ offer the greatest and most universal benefit",
        "These Error Elimination Tools™ are used primarily under dangerous conditions (such as when using powered hand tools) ",
        "Using these Error Elimination Tools™ has been known to give team members certain ‘super powers’",
        "Using these Error Elimination Tools™ is appropriate only when seeking power over others",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },

    {
      question: "[TRUE or FALSE] A Pre-Job Brief (PJB) is part of your work.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
    {
      question:
        "The ‘linchpin’ is the team member (by position) who has the greatest impact on HOW work gets done on a daily basis. WHICH ONE of the following is the ‘linchpin’ in a typical organization?",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "The CEO",
        "The CFO ",
        "Department Head",
        "Front Line Supervisor (FLS)",
      ],
      correctAnswer: "4",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
  ],
};

export default class Recall5 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];
    var input6 = UserInput[5];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall5: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
          UserInput6: UserInput[5],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 6) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "5" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
