import "./styles.css";

import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  Col,
  Container,
  Modal,
  Row,
} from "reactstrap";
import React, { Component } from "react";
import ReactHtmlParser, {
  convertNodeToElement,
  htmlparser2,
  processNodes,
} from "react-html-parser";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { connect } from "react-redux";
import firebase from "firebase/app";
import { getFirebaseBackend } from "../../helpers/authUtils";
import img1 from "../../assets/images/small/img-1.jpg";
import { initFirebaseBackend } from "../../helpers/authUtils.js";
import logo from "../../assets/images/small/Mini-Course Banner_square.png";
import profileImg from "../../assets/images/profile-img.png";
import styled from "styled-components";

//Import Breadcrumb

class MiniCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      MC001: true,
      MC002: true,
      MC003: true,
      MC004: true,
      MC005: true,
    };
  }

  componentDidMount() {
    this.getManualStatus();
    this.getData();
  }

  getManualStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var docRef = db.collection("Progress").doc(user);

    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("Document data:", doc.data());

          console.log(doc.data().MiniCourses);
          this.setState({
            MC001: doc.data().MiniCourses.MC001,
            MC002: doc.data().MiniCourses.MC002,
            MC003: doc.data().MiniCourses.MC003,
            MC004: doc.data().MiniCourses.MC004,
            MC005: doc.data().MiniCourses.MC005,
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  getData() {
    const db = firebase.firestore();
    let citiesRef = db.collection("MiniCourses");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  render() {
    const { open } = this.state;
    const data = this.state.data;
    var MC001 = this.state.MC001;
    var MC002 = this.state.MC002;
    var MC003 = this.state.MC003;
    var MC004 = this.state.MC004;
    var MC005 = this.state.MC005;

    var render = <p>Locked</p>;

    const welcome =
      "<p>Designed to help you grow as a Stage 5 (Steward &amp; Mentor) Leader, Mini-Courses offer additional learning opportunities beyond your PPI Lab Core Content. Each is focused on a specific or emergent area of Human Performance.</p><p>As you should be aware, at PPI we focus on Constant and Never Ending Improvement (CANI). PPI Lab Mini-Courses are no different. They are designed to go deeper into specific areas, as well as keep you up-to-date with emergent strategies and technologies as the world moves forward.</p>";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="PPILAB" breadcrumbItem="Mini Courses" />

            <CardBody className="pt-0">
              <Row>
                <StageContainer>
                  <WelcomeContainer>
                    <WelcomeLogo src={logo} />
                  </WelcomeContainer>
                  <InfoContainer>
                    <div>
                      <h1>Mini-Courses</h1>
                      {ReactHtmlParser(welcome)}
                    </div>
                  </InfoContainer>
                </StageContainer>
              </Row>
              <Row>
                {data.map((course, index) => {
                  var locked = false;
                  if (course.id === "001") {
                    var locked = MC001;
                  } else if (course.id === "002") {
                    var locked = MC002;
                  } else if (course.id === "003") {
                    var locked = MC003;
                  } else if (course.id === "004") {
                    var locked = MC004;
                  } else if (course.id === "005") {
                    var locked = MC005;
                  }

                  if (locked === true) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#42fb93"
                            id="lock"
                            type="solid"
                            name="lock"
                          ></box-icon>
                        </LockContainer>
                      </EpisodeCard>
                    );
                  } else if (locked === false) {
                    render = (
                      <EpisodeCard key={index}>
                        <Title className="mt-0">{course.title}</Title>
                        <LockContainer>
                          <box-icon
                            color="#42fb93"
                            id="icon"
                            type="solid"
                            name="chalkboard"
                          ></box-icon>
                        </LockContainer>

                        <ButtonContainer>
                          <Button
                            color="primary"
                            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            to="/dashboard"
                          >
                            <a href={course.url} style={{ color: "#FFF" }}>
                              Access
                            </a>
                          </Button>
                        </ButtonContainer>
                      </EpisodeCard>
                    );
                  }

                  return render;
                })}
              </Row>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(MiniCourses);

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonTitle = styled.div`
  display: flex;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #b2b2b2;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 18px;
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ThumbnailContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const ThumbnailImage = styled.img``;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
const StageContainer = styled.div`
  display: flex;
  background-color: white;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  margin-bottom: 20px;
`;

const StartButton = styled.button`
  width: 300px;
  height: 80px;
  border-radius: 20px;
  font-size: 25px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  background-color: #57c5de;
  border-color: #b6c7ca;
  color: white;

  &:hover {
    height: 100px;
    width: 320px;
    font-size: 30px;
  }
`;

const InfoContainer = styled.div`
  flex: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StartContainer = styled.div`
  flex: 2;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const WelcomeContainer = styled.div`
  padding: 20px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WelcomeLogo = styled.img`
  width: 200px;
`;

const Info = styled.h1``;
