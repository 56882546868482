import React, { Component } from "react";

import { Container, CardBody, Button } from "reactstrap";

import { Link } from "react-router-dom";

import { connect } from "react-redux";

import styled from "styled-components";

import "./styles.css";

class AdditionalResources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [
        {
          title: "Video Clip Library",
          buttonTitle: "Access",
          url: "/videocliplibrary",
          external: false,
        },
        {
          title: "APPs",
          buttonTitle: "Access",
          url: "/apps",
          external: false,
        },
        {
          title: "Special Reports",
          buttonTitle: "Access",
          url: "/specialreports",
          external: false,
        },
        {
          title: "REPS RADIO",
          buttonTitle: "Access",
          url: "https://ppiweb.com/repsradio/",
          external: true,
        },
        {
          title: "Monday Mindset",
          buttonTitle: "Access",
          url: "https://ppiweb.com/resources/blog-latest-insights/",
          external: true,
        },
        {
          title: "Infographics & Downloads",
          buttonTitle: "Access",
          url: "/downloads",
          external: false,
        },
        {
          title: "Mentoring Session Recordings",
          buttonTitle: "Access",
          url: "/grouprecordings",
          external: false,
        },
        {
          title: "Error Elimination Tools Leader's Guide",
          buttonTitle: "Access",
          url:
            "https://ppilabassets.s3.amazonaws.com/Downloads/EE-Tools-Leaders-Guide_Rev10.01.pdf",
          external: true,
        },
      ],
    };
  }

  componentDidMount() {}

  render() {
    const data = this.state.data;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <CardBody className="pt-0">
              <CardInfo>
                <CardTitle>
                  <MainTitle>Additional Resources</MainTitle>
                  <MainText>
                    Resources to help you learn, grow, and prosper as a Next-Gen
                    Leader
                  </MainText>
                </CardTitle>
                <CardButton></CardButton>
              </CardInfo>
              <ItemContainer>
                {data.map((video, index) => {
                  return (
                    <ItemCard key={index}>
                      <Title className="mt-0">{video.title}</Title>

                      {video.external ? (
                        <a
                          href={video.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ButtonContainer>
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              {video.buttonTitle}
                            </Button>
                          </ButtonContainer>
                        </a>
                      ) : (
                        <ButtonContainer>
                          <Link to={video.url}>
                            <Button
                              color="primary"
                              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                            >
                              {video.buttonTitle}
                            </Button>
                          </Link>
                        </ButtonContainer>
                      )}
                    </ItemCard>
                  );
                })}
              </ItemContainer>
            </CardBody>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(AdditionalResources);

const MainTitle = styled.h1``;

const MainText = styled.p`
  font-size: 18px;
`;

const CardInfo = styled.div`
  display: flex;
`;
const CardTitle = styled.div`
  flex: 4;
`;
const CardButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 16px;
`;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 20px;
  margin: 10px;
  width: 300px;
`;
