import React, { Component } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

import { Link } from "react-router-dom";

import img1 from "../../../assets/images/small/img-1.jpg";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

import { connect } from "react-redux";

import firebase from "firebase/app";

import { initFirebaseBackend } from "../../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../../helpers/authUtils";

import ScrollContainer from "react-indiana-drag-scroll";

import styled from "styled-components";
//Import Breadcrumb

import "../styles.css";

import { device } from "../device.js";

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      data: [],
      episodes: [],
      fromNotifications: "Please Reload",
      episode0051Progress: 0,
      episode0052Progress: 0,
      episode0053Progress: 0,
      episode0054Progress: 0,
      episode0055Progress: 0,
      episode0056Progress: 0,

      episode0061Progress: 0,
      episode0062Progress: 0,
      episode0063Progress: 0,
      episode0064Progress: 0,
      episode0065Progress: 0,
      episode0071Progress: 0,
      episode0072Progress: 0,
      episode0073Progress: 0,
      episode0074Progress: 0,
      episode0075Progress: 0,
      episode0076Progress: 0,

      episode0051Locked: true,
      episode0052Locked: true,
      episode0053Locked: true,
      episode0054Locked: true,
      episode0055Locked: true,
      episode0056Locked: true,

      episode0061Locked: true,
      episode0062Locked: true,
      episode0063Locked: true,
      episode0064Locked: true,
      episode0065Locked: true,
      episode0071Locked: true,
      episode0072Locked: true,
      episode0073Locked: true,
      episode0074Locked: true,
      episode0075Locked: true,
      episode0076Locked: true,
    };
  }

  componentDidMount() {
    this.getEpisodes();
    this.getUserData();
    this.getStatus();
  }

  removeBodyCss() {
    document.body.classList.add("no_paddings");
  }

  onOpenModal = (id) => {
    this.setState({
      open: {
        [id]: true,
      },
    });
  };

  onCloseModal = (id) => {
    this.setState({
      open: {
        [id]: false,
      },
    });
  };

  getEpisodes() {
    const db = firebase.firestore();
    let citiesRef = db.collection("/Courses/001/Synthesizer/Content/Content");
    let allCities = citiesRef
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          console.log(doc.data());

          var currentData = this.state.episodes;

          currentData.push(doc.data());

          this.setState({ episodes: currentData });
        });
      })
      .catch((err) => {
        console.log("Error getting documents", err);
      });
  }

  getUserData = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();
    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("pushing Data");
          var currentData = this.state.data;

          currentData.push(doc.data());

          this.setState({ data: currentData }, () => {
            this.getPrincipalEpisodesProgress();
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getStatus = () => {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    let userRef = db.collection("Progress").doc(user);
    let getDoc = userRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          console.log("No such user!");
        } else {
          console.log("ID HERE");
          console.log(doc.data().episode001.locked);

          this.setState({
            episode0051Locked: doc.data().episode0051.locked,
            episode0052Locked: doc.data().episode0052.locked,
            episode0053Locked: doc.data().episode0053.locked,
            episode0054Locked: doc.data().episode0054.locked,
            episode0055Locked: doc.data().episode0055.locked,
            episode0056Locked: doc.data().episode0056.locked,
            episode0061Locked: doc.data().episode0061.locked,
            episode0062Locked: doc.data().episode0062.locked,
            episode0063Locked: doc.data().episode0063.locked,
            episode0064Locked: doc.data().episode0064.locked,
            episode0065Locked: doc.data().episode0065.locked,
            episode0071Locked: doc.data().episode0071.locked,
            episode0072Locked: doc.data().episode0071.locked,
            episode0073Locked: doc.data().episode0073.locked,
            episode0074Locked: doc.data().episode0074.locked,
            episode0075Locked: doc.data().episode0075.locked,
            episode0076Locked: doc.data().episode0076.locked,
          });
        }
      })
      .catch((err) => {
        console.log("Error getting document", err);
      });
  };

  getPrincipalEpisodesProgress() {
    var data = this.state.data;
    console.log("DATA HERE");

    this.setState({
      episode0051Progress: Math.round(data[0].episode0051.progress),
      episode0052Progress: Math.round(data[0].episode0052.progress),
      episode0053Progress: Math.round(data[0].episode0053.progress),
      episode0054Progress: Math.round(data[0].episode0054.progress),
      episode0055Progress: Math.round(data[0].episode0055.progress),
      episode0056Progress: Math.round(data[0].episode0056.progress),
      episode0061Progress: Math.round(data[0].episode0061.progress),
      episode0062Progress: Math.round(data[0].episode0062.progress),
      episode0063Progress: Math.round(data[0].episode0063.progress),
      episode0064Progress: Math.round(data[0].episode0064.progress),
      episode0065Progress: Math.round(data[0].episode0065.progress),
      episode0071Progress: Math.round(data[0].episode0071.progress),
      episode0072Progress: Math.round(data[0].episode0072.progress),
      episode0073Progress: Math.round(data[0].episode0073.progress),
      episode0074Progress: Math.round(data[0].episode0074.progress),
      episode0075Progress: Math.round(data[0].episode0075.progress),
      episode0076Progress: Math.round(data[0].episode0076.progress),
    });
  }

  render() {
    const { open } = this.state;
    const episodes = this.state.episodes;
    const episode0051Locked = this.state.episode0051Locked;
    const episode0052Locked = this.state.episode0052Locked;
    const episode0053Locked = this.state.episode0053Locked;
    const episode0054Locked = this.state.episode0054Locked;
    const episode0055Locked = this.state.episode0055Locked;
    const episode0056Locked = this.state.episode0056Locked;
    const episode0061Locked = this.state.episode0061Locked;
    const episode0062Locked = this.state.episode0062Locked;
    const episode0063Locked = this.state.episode0063Locked;
    const episode0064Locked = this.state.episode0064Locked;
    const episode0065Locked = this.state.episode0065Locked;
    const episode0071Locked = this.state.episode0071Locked;
    const episode0072Locked = this.state.episode0072Locked;
    const episode0073Locked = this.state.episode0073Locked;
    const episode0074Locked = this.state.episode0074Locked;
    const episode0075Locked = this.state.episode0075Locked;
    const episode0076Locked = this.state.episode0076Locked;

    var percentage = 8;
    var item = <h1>Loading</h1>;
    return (
      <React.Fragment>
        <Row>
          <CardContainer>
            {episodes.map((episode, index) => {
              if (episode.currentEpisode === "/episode0051") {
                var percentage = this.state.episode0051Progress;
                var locked = this.state.episode0051Locked;
              } else if (episode.currentEpisode === "/episode0052") {
                var percentage = this.state.episode0052Progress;
                var locked = this.state.episode0052Locked;
              } else if (episode.currentEpisode === "/episode0053") {
                var percentage = this.state.episode0053Progress;
                var locked = this.state.episode0053Locked;
              } else if (episode.currentEpisode === "/episode0054") {
                var percentage = this.state.episode0054Progress;
                var locked = this.state.episode0054Locked;
              } else if (episode.currentEpisode === "/episode0055") {
                var percentage = this.state.episode0055Progress;
                var locked = this.state.episode0055Locked;
              } else if (episode.currentEpisode === "/episode0056") {
                var percentage = this.state.episode0056Progress;
                var locked = this.state.episode0056Locked;
              } else if (episode.currentEpisode === "/episode0061") {
                var percentage = this.state.episode0061Progress;
                var locked = this.state.episode0061Locked;
              } else if (episode.currentEpisode === "/episode0062") {
                var percentage = this.state.episode0062Progress;
                var locked = this.state.episode0062Locked;
              } else if (episode.currentEpisode === "/episode0063") {
                var percentage = this.state.episode0063Progress;
                var locked = this.state.episode0063Locked;
              } else if (episode.currentEpisode === "/episode0064") {
                var percentage = this.state.episode0064Progress;
                var locked = this.state.episode0064Locked;
              } else if (episode.currentEpisode === "/episode0065") {
                var percentage = this.state.episode0065Progress;
                var locked = this.state.episode0065Locked;
              } else if (episode.currentEpisode === "/episode0071") {
                var percentage = this.state.episode0071Progress;
                var locked = this.state.episode0071Locked;
              } else if (episode.currentEpisode === "/episode0072") {
                var percentage = this.state.episode0072Progress;
                var locked = this.state.episode0072Locked;
              } else if (episode.currentEpisode === "/episode0073") {
                var percentage = this.state.episode0073Progress;
                var locked = this.state.episode0073Locked;
              } else if (episode.currentEpisode === "/episode0074") {
                var percentage = this.state.episode0074Progress;
                var locked = this.state.episode0074Locked;
              } else if (episode.currentEpisode === "/episode0075") {
                var percentage = this.state.episode0075Progress;
                var locked = this.state.episode0075Locked;
              } else if (episode.currentEpisode === "/episode0076") {
                var percentage = this.state.episode0076Progress;
                var locked = this.state.episode0076Locked;
              }

              if (episode.fast === true) {
                var fast = (
                  <FastContainer>
                    <Fast>
                      <box-icon
                        color="#f70c2e"
                        id="fast"
                        name="star"
                        type="solid"
                      ></box-icon>
                      <FastText>20%</FastText>
                    </Fast>
                  </FastContainer>
                );
              } else {
                var fast = <div></div>;
              }

              if (locked === true) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <LockContainer>
                      <box-icon
                        color="#10039b"
                        id="lock"
                        type="solid"
                        name="lock"
                      ></box-icon>
                    </LockContainer>
                  </EpisodeCard>
                );
              } else if (locked === false) {
                item = (
                  <EpisodeCard key={index}>
                    <Title className="mt-0">{episode.title}</Title>
                    <ProgressContainer>
                      {percentage === 100 ? (
                        <CheckContainer>
                          <box-icon
                            size="lg"
                            color="#a3ff03"
                            name="check-circle"
                            type="solid"
                          ></box-icon>
                        </CheckContainer>
                      ) : (
                        <CircularProgressbar
                          style={{
                            width: 100,
                            position: "absolute",
                            display: "block",
                          }}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "30px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#10039b`,
                            textColor: `#10039b`,
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                          value={percentage}
                          text={`${percentage}%`}
                        />
                      )}
                    </ProgressContainer>
                    <Duration>
                      <TimeIcon>
                        <box-icon type="solid" name="time"></box-icon>
                      </TimeIcon>
                      <TextContainer>
                        <Text>{episode.duration}</Text>
                      </TextContainer>
                    </Duration>
                    <BottomContainer>
                      <ButtonContainer>
                        <Button
                          color="primary"
                          className="btn btn-primary btn-lg btn-block waves-effect waves-light"
                          to="/dashboard"
                        >
                          <Link
                            to={{
                              pathname: `${episode.currentEpisode}`,
                            }}
                            style={{ color: "#FFF" }}
                          >
                            Watch
                          </Link>
                        </Button>
                      </ButtonContainer>
                      {fast}
                    </BottomContainer>
                  </EpisodeCard>
                );
              }
              {
                return item;
              }
            })}
          </CardContainer>
        </Row>
      </React.Fragment>
    );
  }
}

// div to Card if default settings needed

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

export default connect(mapStateToProps)(Episodes);

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FastContainer = styled.div`
  position: relative;
`;

const Fast = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
  }
`;

const FastText = styled.p`
  position: absolute;
  color: white;
  font-size: 15px;
  border: 1px solid green;

  @media ${device.mobileS} {
    display: none;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const CheckContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 100px;
`;

const EpisodeCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 250px;
  margin: 10px;
  padding: 10px;
  border: 2px solid #10029c;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 10px 8px #888888;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

  @media ${device.mobileS} {
    width: 160px;
    height: 180px;
    margin-bottom: 2px;
  }

  @media ${device.mobileM} {
    width: 190px;
    height: 200px;
  }

  @media ${device.mobileL} {
    width: 225px;
    height: 250px;
  }

  @media ${device.tablet} {
  }

  @media ${device.laptop} {
  }

  @media ${device.laptopL} {
  }

  @media ${device.desktop} {
  }

  @media ${device.desktopL} {
  }
`;

const Title = styled.h1`
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
  @media ${device.mobileS} {
    font-size: 11px;
  }
  @media ${device.mobileL} {
    font-size: 15px;
  }
  @media ${device.tablet} {
    font-size: 17px;
  }
`;

const Duration = styled.div`
  display: flex;
  align-items: center;
  @media ${device.mobileS} {
    display: none;
  }

  @media ${device.mobileL} {
    display: flex;
  }
`;
const TimeIcon = styled.div``;
const TextContainer = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span``;

const ButtonContainer = styled.div`
  flex: 1;
  width: 100% !important;
`;

const ProgressContainer = styled.div`
  padding: 10px;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;

  @media ${device.mobileS} {
    width: 70px;
    height: 70px;
  }
  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
`;
