import React, { Component } from "react";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import firebase from "firebase/app";
import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils";

import { connect } from "react-redux";
import FileUploader from "react-firebase-file-uploader";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styled from "styled-components";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import "./styles.css";

class Helpdesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      subject: "",
      textarea: "",
      alert: false,
      alert2: false,
      alertError: false,
    };
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeTextArea = this.handleChangeTextArea.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;
    console.log(user);

    this.setState({ user: user });
  }

  componentDidUpdate() {
    console.log("Avatar url here " + this.state.avatar);
  }

  handleChangeSubject(event) {
    this.setState({ subject: event.target.value });
  }

  handleChangeTextArea(event) {
    this.setState({ textarea: event.target.value });
  }

  handleSubmit(event) {
    console.log("Called");
    const fireBaseBackend = getFirebaseBackend();

    var user = fireBaseBackend.getAuthenticatedUser().email;

    const db = firebase.firestore();

    var userRef = db.collection("HelpDesk").doc(user);

    var setWithMerge = userRef.set(
      {
        subject: this.state.subject,
        message: this.state.textarea,
      },
      { merge: true }
    );
    this.setState({ alert: true });

    event.preventDefault();
  }

  render() {
    var storage = firebase.storage();

    var user = this.state.user;

    var avatar = this.state.avatar;
    var email = this.state.email;
    console.log(avatar);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs title="PPILAB" breadcrumbItem="Helpdesk" />

            <Row>
              <AccountContainer>
                <AccountDetailsContainer>
                  <h1>Send Us a Message</h1>
                  <hr></hr>
                  <MainForm onSubmit={this.handleSubmit}>
                    <FormLabel>Subject</FormLabel>
                    <br />
                    <FormInput
                      type="text"
                      value={this.state.subject || ""}
                      onChange={this.handleChangeSubject}
                      placeholder="Subject Here"
                    />

                    <FormLabel>Message</FormLabel>
                    <br />
                    <FormTextArea
                      type="text"
                      value={this.state.textarea || ""}
                      onChange={this.handleChangeTextArea}
                      placeholder="Message Here"
                    />

                    <br />
                    {this.state.alert ? (
                      <Alert color="success">
                        <strong>
                          Message Sent! - We will get back to you at{" "}
                          {this.state.user}
                        </strong>
                      </Alert>
                    ) : (
                      <div></div>
                    )}
                    {this.state.alertError ? (
                      <Alert color="danger">
                        <strong>An Error Has Ocurred</strong>
                      </Alert>
                    ) : (
                      <div></div>
                    )}
                    <FormSubmitContainer>
                      <FormSubmitButton type="submit" value="SEND MESSAGE" />
                    </FormSubmitContainer>
                  </MainForm>
                </AccountDetailsContainer>
              </AccountContainer>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// Redux Functions

function mapStateToProps(state) {
  return { ...state.User };
}

function mapDispatchToProps(dispatch) {
  return {
    updateEmail: (email) => dispatch({ type: "UPDATE_EMAIL", payload: email }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Helpdesk);

const ProfileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`;
const ProfileDetails = styled.div`
  margin: 10px;
  padding: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

const ProfileImage = styled.img`
  background-color: gray;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  flex: 1;
`;

const ProfileImageInfo = styled.div`
  flex: 3;
  margin-left: 10px;
`;

const ProfileImageText = styled.div``;

const ProfileImageButtons = styled.div``;

const ProfileUploadButton = styled.label``;

const ProfileDeleteButton = styled.button`
  background-color: #f8f8fb;
  margin-left: 10px;
  font-weight: 500;
  border: 0px;
`;

const ProfileProgressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ProfileProgress = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-items: space-between;
  margin: 10px;

  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ProfileProgressTitle = styled.h1`
  margin-left: 20px;
`;
const ProgressTitle = styled.h1`
  font-size: 18px;
`;
const IntroProgress = styled.div`
  border: 1px solid #e4eaec;
  border-radius: 20px;
  width: 200px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
`;

const ProgressContainer = styled.div`
  width: 100%;
`;

const AccountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
`;

const AccountDetailsContainer = styled.div`
  margin: 10px;
  padding: 10px;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const ChangePasswordContainer = styled.div`
  margin: 10px;
  padding: 10px;

  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.29);
  border-radius: 20px;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

const MainForm = styled.form`
  width: 350px;
`;

const FormLabel = styled.label`
  margin-top: 20px;
`;

const FormTextArea = styled.textarea`
  margin-top: 20px;
  width: 100%;
  height: 150px;
`;

const FormInput = styled.input`
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
  width: 100%;
  height: 40px;
  padding: 10px;
`;

const FormSubmitContainer = styled.div`
  margin-top: 20px;
`;

const FormSubmitButton = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
`;
const RequestPasswordButton = styled.button`
  width: 100%;
  padding: 10px;
  border-radius: 10px !important;
  border: 1px solid #e4eaec !important;
`;
