import React, { Component } from "react";
import Quiz from "react-quiz-component";

import styled from "styled-components";

import firebase from "firebase/app";

import { Link } from "react-router-dom";

import { initFirebaseBackend } from "../../helpers/authUtils.js";

import { getFirebaseBackend } from "../../helpers/authUtils.js";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  CardText,
  Modal,
  Button,
  CardTitle,
} from "reactstrap";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Questions",
    question: "Question",
    startQuizBtn: "Start",
    resultFilterAll: "All",
    resultFilterCorrect: "Correct",
    resultFilterIncorrect: "Incorrect",
    nextQuestionBtn: "Next",
    resultPageHeaderText: "",
  },
  quizTitle: "KEY POINT RECALL- Module 3 [Understanding Yourself & Others]",
  quizSynopsis: "Quiz Synopsis",
  questions: [
    {
      question:
        "[TRUE or FALSE] When considering PPI Precept #2 (84 to 94 percent of all mistakes on the job are setup by process, programmatic, or organizational issues), we need to take a good long look in the mirror because WE (as a team) created these setups.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "As we begin to consider how to take performance from where it is to where we want it to be, and to influence team members to help create and sustain it, we must be mindful that there are traps, roadblocks, and landmines everywhere. We must also be mindful of WHO created these setups. As a team, you and your team members should recognize that over time, existing setups have been created by “us”.",
    },
    {
      question:
        "Which of the following statements are TRUE regarding human motivation? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      messageForCorrectAnswer: "Great Job",
      messageForIncorrectAnswer: "Please Try Again",
      explanation:
        "In efforts to promote new choices, actions, and behaviors, recognize that all motivation (the choice to take an action and take that action in a certain way) is INTERNAL. You cannot motivate anyone (other than yourself) to do anything. You can, however, INFLUENCE them. ",
      point: "12.5",
      answers: [
        "You cannot motivate another person to do anything (ALL motivation is INTERNAL)",
        "A person’s motivation derives from their answer to- WIIFM?",
        "The two primary motivations are acquisition of power and avoidance of procrastination",
        "Bonuses motivate sustainable behavior change",
      ],
      correctAnswer: [1, 2],
    },
    {
      question:
        "Choose the response which BEST completes this statement: In a COMPLIANCE-BASED environment, many team members generally treat work/job expectations as…",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "an opportunity to exceed their ‘personal best’ ",
        "the minimum level at which to perform",
        "the level of performance to reach/maintain when being observed",
        "‘gospel’ and inviolate",
      ],
      correctAnswer: "3",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "In a compliance-based work environment, expectations are generally the highest level to which typical team members will perform, and often then only when someone is watching. It is human nature for most of us to ‘get away with things’ (such as exceeding the speed limit when no police are around). The alternate is to grow a culture of desire- team members doing ‘right’ things in ‘right’ ways when no one is watching because they WANT to.",
      point: "12.5",
    },
    {
      question:
        "[TRUE or FALSE] Gift cards and pizza parties are two ways to sustainably influence team members.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "There are three ways to INFLUENCE the choices, actions, and behaviors of others: Force, Manipulation, and Inspiration. The tactic of, “if you do this, I’ll give you this,” (such as gift cards, pizza parties, and even bonuses) are forms of manipulation and do NOT generate sustainable behavior change. Recall from Maslow’s Hierarchy- the only way to generate sustainable change is through inspiration- through sincere appreciation and provision of opportunity to grow.",
      point: "12.5",
    },

    {
      question: "Which part of the human brain functions as the ‘gatekeeper’? ",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Frontal lobe", "amygdala", "Neo-cortex", "limbic"],
      correctAnswer: "2",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "If we are to effectively influence another person, we must make it through their mind’s ‘gatekeeper’ before [whatever it is] will even be considered. The amygdala (the ‘lizard brain’) functions as the gatekeeper. It has one primary function: our survival. As such, it rapidly processes a series of questions before allowing external information (including your efforts to influence) to pass. These questions include 1. Is this a threat? 2. Is this complex? 3. Is this unique, and 4. Is this novel? Only when perceived as NOT being a threat, NOT being complex, unique, and novel will your efforts be allowed onward for conscious consideration. ",
      point: "12.5",
    },
    {
      question:
        "The primary function of the brain’s ‘gatekeeper’ is survival. As such, it Immediately considers whether incoming information is a ‘threat’. When incoming information is not perceived as a threat, what additional questions does the ‘gatekeeper’ process when determining whether to allow incoming information to be forwarded on to the rest of the brain?  [CHECK ALL THAT APPLY] ",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "Is this fun?",
        "Is this complex?",
        "Is this unique?",
        "Will this bring me pleasure?",
      ],
      correctAnswer: [2, 3],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Once your attempts to influence reach the level of conscious processing, the individual quickly processes the answers to two internal questions. First, “Is this relevant (to me)?” In other words, WIFFM?  When the individual perceives there’s nothing ‘in it for them’, they may continue to be polite; however, you will NOT be able to influence them. When your efforts make it past this first consciously-considered hurdle, the individual then processes the second question, “Can I do this?” (IN other words, “Do I have the ability?”) This is known as the Personal Screening Process. Your approach therefore must be to (1) come from the perspective of what’s in it for THEM, and (2) make [whatever it is] as fast, simple, and easy as possible. ",
      point: "12.5",
    },
    {
      question:
        "WHICH ONE of the following represents the (2) questions an individual quickly processes when you are attempting to influence them?  ",
      questionType: "text",
      answerSelectionType: "single",
      answers: [
        "“Is this relevant (to me)?” AND “Can I do this?”",
        "“WIIFM?” AND “How much is it going to cost?”",
        "“Is this relevant (to me)? AND “Will it make my boss happy?”",
        "“WIIFM” AND “How long will it take?”",
      ],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Once you make it through the Personal Screening Process, then (and only then) do you have the opportunity to INFLUENCE. Your goal, of course, is to get through the person’s mindset and perception in a manner that generates desirable thoughts. The only way for you to alter an individual’s mindset and perception is by providing them with different experiences. ",
      point: "12.5",
    },
    {
      question:
        "Which of the following statement(s) is/are CORRECT regarding the Individual Performance Model? [CHECK ALL THAT APPLY]",
      questionType: "text",
      answerSelectionType: "multiple",
      answers: [
        "The GAP is where “free will” resides.",
        "The stories we tell ourselves while in the GAP are quite reliable (having come from our subconscious mind).",
        "Mindset is our “mental amplifier”.",
        "Mindset and Perception are influenced through experiences.",
      ],
      correctAnswer: [1, 3, 4],
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation:
        "Once ‘inside the model’, thoughts lead to feelings. Feelings then process through the GAP. The GAP is where freewill resides. It’s where we CHOOSE to either react, or [how] to respond. Coming out of the GAP we make choices to take (or not take) specific actions and to do so specific ways (our behaviors).",
      point: "12.5",
    },
    {
      question:
        "[TRUE or FALSE] As a leader, the best (and perhaps only) way to transform your team’s culture is by providing its members with different experiences.",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["TRUE", "FALSE"],
      correctAnswer: "1",
      messageForCorrectAnswer: "Great Job",
      messageForInorrectAnswer: "Please Try Again",
      explanation: "",
      point: "12.5",
    },
  ],
};

export default class Recall3 extends Component {
  constructor(props) {
    super(props);

    this.state = { correct: 0, show: false, positive: false, negative: false };
    this.onCompleteAction = this.onCompleteAction.bind(this);
  }

  renderCustomResultPage = (obj) => {
    console.log(obj.numberofCorrectAnswers);
  };

  onCompleteAction = (obj) => {
    console.log(obj);

    // Correct Answers
    var correct = obj.numberOfCorrectAnswers;
    // Incorrect Answers
    var incorrect = obj.numberOfIncorrectAnswers;
    // Number of Questions
    var numberOfQuestions = obj.numberOfQuestions;
    // Input
    var UserInput = obj.userInput;

    var input1 = UserInput[0];
    var input2 = UserInput[1];
    var input3 = UserInput[2];
    var input4 = UserInput[3];
    var input5 = UserInput[4];
    var input6 = UserInput[5];
    var input7 = UserInput[6];
    var input8 = UserInput[7];
    var input9 = UserInput[8];

    const db = firebase.firestore();

    const fireBaseBackend = getFirebaseBackend();

    var User = fireBaseBackend.getAuthenticatedUser().email;

    var userRef = db
      .collection("Progress")
      .doc(User)
      .update({
        recall3: {
          correct: correct,
          incorrect: incorrect,
          numberOfQuestions: numberOfQuestions,
          UserInput1: UserInput[0],
          UserInput2: UserInput[1],
          UserInput3: UserInput[2],
          UserInput4: UserInput[3],
          UserInput5: UserInput[4],
          UserInput6: UserInput[5],
          UserInput7: UserInput[6],
          UserInput8: UserInput[7],
          UserInput9: UserInput[8],
        },
      })
      .then(function () {
        console.log("Document successfully updated!");
      });

    console.log("Completing ");
    console.log(obj);
    console.log(obj.numberOfCorrectAnswers);

    if (!this.state.show) {
      this.setState({ show: true });

      if (obj.numberOfCorrectAnswers === 9) {
        this.setState({ positive: true });
      } else {
        this.setState({ negative: true });
      }
    }
  };

  render() {
    var show = this.state.show;
    var positive = this.state.positive;
    var negative = this.state.negative;

    if (show === true) {
      if (positive === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#a3ff03"
                name="check-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageTitle>GREAT job!</MessageTitle>
            <MessageBody>
              Your response to each Recall Question was spot on!
            </MessageBody>
          </MessageContainer>
        );
      } else if (negative === true) {
        var message = (
          <MessageContainer>
            <MessageIcon>
              <box-icon
                size="lg"
                color="#da0410"
                name="x-circle"
                type="solid"
              ></box-icon>
            </MessageIcon>

            <MessageBody>
              Okay- one or more of your Recall responses was NOT correct.
            </MessageBody>
            <MessageIntructions>
              Either use the RETAKE button below to take another shot, or Click
              on CONTINUE to advance to the next episode.
            </MessageIntructions>
          </MessageContainer>
        );
      }

      var Retake = (
        <div>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            color="primary"
            className="btn btn-primary btn-lg btn-block waves-effect waves-light"
          >
            RETAKE
          </Button>
          <Link
            to={{
              pathname: "./course1",
              state: { activeTabJustify: "4" },
            }}
          >
            <Button
              style={{ marginTop: 10 }}
              color="primary"
              className="btn btn-primary btn-lg btn-block waves-effect waves-light"
            >
              CONTINUE
            </Button>
          </Link>
        </div>
      );
    } else if (show === false) {
      var Retake = <div></div>;
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg={12}>
                {message}
                {Retake}
                <Quiz
                  quiz={quiz}
                  showDefaultResult={true}
                  onComplete={this.onCompleteAction}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const MessageContainer = styled.div``;

const MessageIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageTitle = styled.h1`
  text-align: center;
  font-size: 35px;
`;

const MessageBody = styled.p`
  font-size: 25px;
  text-align: center;
`;

const MessageIntructions = styled.p`
  font-size: 25px;
`;
