import { UPDATE_EMAIL } from './actionTypes'

const initialState = {
  email: 'User'
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_EMAIL:
      return { ...state, email: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default user
