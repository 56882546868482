import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {} from "../../store/actions";

// MetisMenu
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";

import styled from "styled-components";

import { Link } from "react-router-dom";

// <Icon src={dashboardIcon} />
/*<li>
            <Link to="/leadermanuals" className="waves-effect">
              <i className="bx bx-book-open"></i>

              <span>Leader Manuals</span>
            </Link>
          </li>*/

const SidebarContent = (props) => {
  return (
    <>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bxs-dashboard"></i>

              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/course1" className="waves-effect">
              <i className="bx bx-analyse"></i>

              <span>Core Content</span>
            </Link>
          </li>

          <li>
            <Link to="/jumpstart" className="waves-effect">
              <i className="bx bx-rocket"></i>

              <span>Jumpstart Packs</span>
            </Link>
          </li>
          <li>
            <Link to="/leaderworksheets" className="waves-effect">
              <i className="bx bx-spreadsheet"></i>

              <span>Leader Worksheets</span>
            </Link>
          </li>
          <li>
            <Link to="/teambriefings" className="waves-effect">
              <i className="bx bx-task"></i>

              <span>Team Briefings</span>
            </Link>
          </li>
          <li>
            <Link to="/teamexercises" className="waves-effect">
              <i className="bx bx-slideshow"></i>

              <span>Team Exercises</span>
            </Link>
          </li>
          <li>
            <Link to="/minicourses" className="waves-effect">
              <i className="bx bx-chalkboard"></i>

              <span>Mini Courses</span>
            </Link>
          </li>
          <li>
            <Link to="/videocliplibrary" className="waves-effect">
              <i className="bx bxs-videos"></i>

              <span>Video Clip Library</span>
            </Link>
          </li>
          <li>
            <Link to="/additional" className="waves-effect">
              <i className="bx bx-collection"></i>

              <span>Additional Resources</span>
            </Link>
          </li>
          <li>
            <Link to="/eventcalendar" className="waves-effect">
              <i className="bx bx-calendar"></i>

              <span>Events Calendar</span>
            </Link>
          </li>
          <li>
            <Link to="/helpdesk" className="waves-effect">
              <i className="bx bx-help-circle"></i>

              <span>Helpdesk</span>
            </Link>
          </li>

          <li>
            <Link to="/profile" className="waves-effect">
              <i className="bx bx-user-circle"></i>

              <span>Profile</span>
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

/*<spa className="badge badge-pill badge-info float-right">3</spa>*/

/* <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">Menu</li>
          <li>
            <Link to="/dashboard" className="waves-effect">
              <i className="bx bx-dashboard"><box-icon name='dashboard' type='solid' ></box-icon></i>

              <span>Dashboard</span>
            </Link>
          </li>
          <li>
            <Link to="/course1" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Core Content</span>
            </Link>
          </li>
          <li>
            <Link to="/leadermanuals" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Leader Manuals</span>
            </Link>
          </li>
          <li>
            <Link to="/jumpstart" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Jumpstart Packs</span>
            </Link>
          </li>
          <li>
            <Link to="/leaderworksheets" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Leader Worksheets</span>
            </Link>
          </li>
          <li>
            <Link to="/teamexercises" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Team Briefings</span>
            </Link>
          </li>
          <li>
            <Link to="/teamexercises" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Team Exercises</span>
            </Link>
          </li>
          <li>
            <Link to="/minicourses" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Mini Courses</span>
            </Link>
          </li>
          <li>
            <Link to="/teamexercises" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Video Clip Library</span>
            </Link>
          </li>
          <li>
            <Link to="/additional" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Additional Resources</span>
            </Link>
          </li>
          <li>
            <Link to="/eventcalendar" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Events Calendar</span>
            </Link>
          </li>
          <li>
            <Link to="/helpdesk" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Helpdesk</span>
            </Link>
          </li>

          <li>
            <Link to="/profile" className="waves-effect">
              <i className="bx bx-home-circle"></i>

              <span>Profile</span>
            </Link>
          </li>
        </ul>*/

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    // if (this.props.type !== "condensed" || this.props.isMobile) {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    // }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div data-simplebar className="h-100">
            {this.props.type !== "condensed" ? (
              <SimpleBar style={{ maxHeight: "100%" }}>
                <SidebarContent />
              </SimpleBar>
            ) : (
              <SidebarContent />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
